.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #FFCC0033;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .progress-bar__fill {
    height: 100%;
    background-color: #ffcc00;
    width: 0;
    transition: width 5s ease-in-out;
  }

  .progress-bar.blue{
    background: #0066CC33;
  }
  .progress-bar__fill.blue{
    background: #0066cc;
  } 
