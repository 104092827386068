:root {
	--apColorBrandLogo1: #1EBDC1;
	--apColorBrandLogo2: #c5e505;
	--apColorBrandLogo3: #FF5100;
	--apColorBrandLogo4: #D120B3;
	--apColorBrandBG1: #1BA8B2;
	--apColorBrandBG2: #39D0D3;
	--apColorBrandBG3: #B0C105;
	--apColorBrandBG4: #BB74D6;
	--apColorBrandBG5: #F3BB13;
	--apColorBrandBG6: #8181A5;
	--apColorDark: #444F60;
	--apColorLight: #FFFFFF;
	--apColorAccent: #1BA8B2;
	--apColorAttention: #F2994A;
	--apColorError: #FF3941;
	--apColorDarkGray1: #959595;
	--apColorDarkGray2: #C1C1C1;
	--apColorGray1: #DEDFE1;
	--apColorMediumGray: #EDEDF0;
	--apColorLightGray: #F1F2F4;
	--apColorOverlayDark: rgba(68, 79, 96, 0.8);
	--apColorOverlayMedium: rgba(68, 79, 96, 0.5);
	--apColorOverlayLight: rgba(68, 79, 96, 0.1);
	--apWidthStrokeDefault: 1px;
	--apWidthStrokeFocus: 2px;
	--apWidthStrokeDisabled: 1px;
	--apWidthStrokeAttention: 1px;
	--apWidthStrokeError: 1px;
	--apWidthStrokeErrorFocused: 2px;
	--apTransitionDurationPetal: 0.2s;
	--apTransitionDurationSwiper: 0.1s;
}
/* This stylesheet generated by Transfonter (https://transfonter.org) on October 3, 2017 10:05 PM */

@font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 200;
	src:
		 url('fonts/Montserrat-ExtraLightItalic.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 300;
	src:
		 url('fonts/Montserrat-LightItalic.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 500;
	src:
		 url('fonts/Montserrat-MediumItalic.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 600;
	src: 
		 url('fonts/Montserrat-SemiBoldItalic.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 800;
	src: 
		 url('fonts/Montserrat-ExtraBoldItalic.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: 900;
	src: 
		 url('fonts/Montserrat-BlackItalic.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: italic;
	font-weight: normal;
	src: 
		 url('fonts/Montserrat-Italic.woff') format('woff');
  }
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 100;
	src:
		 url('fonts/Montserrat-Thin.woff') format('woff');
  }
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 200;
	src: 
		 url('fonts/Montserrat-ExtraLight.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 300;
	src: 
		 url('fonts/Montserrat-Light.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400; /* Assuming this is your regular weight */
	src: 
		 url('fonts/Montserrat-Regular.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	src:
		 url('fonts/Montserrat-Medium.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	src: 
		 url('fonts/Montserrat-SemiBold.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700; /* Assuming this is your bold weight */
	src: 
		 url('fonts/Montserrat-Bold.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 800;
	src:
		 url('fonts/Montserrat-ExtraBold.woff') format('woff');
  }
  
  @font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 900;
	src: 
		 url('fonts/Montserrat-Black.woff') format('woff');
  }

  
/* RESET */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, button, input, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	outline: none;
	box-sizing: border-box;
	font: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
html {
	height: 100%;
}
body {
	background: var(--apColorLightGray);
	height: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	color: var(--apColorDark);
	overflow-y: scroll;
}
/* body>div {
	overflow: auto;
}
*/
#root {
	height: 100%;
}
.App {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}
ol, ul {
	margin: 0 0 0 0;
	padding: 0 0 0 16px;
}
i {
	font-style: italic;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.apIinnerPage figure {
	max-width: 100%;
	overflow-x: auto;
}
.apIinnerPage table {
	width: 100%;
	max-width: 677px;
	margin: 0 auto 24px;
	border-radius: 7px;
	border-collapse: separate;
}
.apIinnerPage table tr:first-child td, .apIinnerPage table tr:first-child th {
	color: #fff;
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	letter-spacing: 0.006em;
	background-color: #1BA8B2;
}
.apIinnerPage table tr:not(:first-child) td:first-child, .apIinnerPage table tr:not(:first-child) th:first-child {
	border-left: 2px solid #1BA8B2;
}
.apIinnerPage table tr:not(:first-child) td, .apIinnerPage table tr:not(:first-child) th, .apIinnerPage table tr:not(:last-child) td, .apIinnerPage table tr:not(:last-child) th {
	border-bottom: 1px solid #D9D9D9;
}
.apIinnerPage table tr:not(:first-child) td:last-child, .apIinnerPage table tr:not(:first-child) th:last-child {
	border-right: 2px solid #1BA8B2;
}
.apIinnerPage table tr:last-child td {
	border-bottom: 2px solid #1BA8B2;
}
.apIinnerPage table tr:last-child td:first-child {
	border-bottom-left-radius: 7px;
}
.apIinnerPage table tr:last-child td:last-child {
	border-bottom-right-radius: 7px;
}
.apIinnerPage table tr:first-child td:first-child, .apIinnerPage table tr:first-child th:first-child {
	border-top-left-radius: 7px;
}
.apIinnerPage table tr:first-child td:last-child, .apIinnerPage table tr:first-child th:last-child {
	border-top-right-radius: 7px;
}
.apIinnerPage table td, .apIinnerPage table th {
	padding: 0 4px;
	vertical-align: middle;
	box-sizing: border-box;
	height: 44px;
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	text-align: center;
	letter-spacing: 0.006em;
	position: relative 
}
.apIinnerPage table td:before, .apIinnerPage table th:before {
	position: absolute;
	content: '';
	right: 0;
	top: 10px;
	bottom: 10px;
	width: 1px;
	background-color: #D9D9D9;
}
.apIinnerPage table tr:first-child td:before, .apIinnerPage table tr:first-child th:before {
	position: absolute;
	content: '';
	right: 0;
	top: 10px;
	bottom: 10px;
	width: 1px;
	background-color: #fff;
}
.apIinnerPage table td p, .apIinnerPage table th p {
	margin: 0;
}
.apIinnerPage table td:last-child:before, .apIinnerPage table th:last-child:before {
	content: none !important;
}
/* Общие стили тегов, не зависящие от разрешения. */
button, .label {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
   /* height: 40px;
	*/
	border-radius: 8px;
	font-weight: 600;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	padding: 5px 8px 5px 8px;
	background-color: var(--apColorGray1);
	cursor: pointer;
	transition: var(--apTransitionDurationSwiper);
}
button svg, .label svg {
	border-radius: 1.5px;
	width: 24px;
	min-width: 24px;
	height: 24px;
	min-height: 24px;
}
button:hover {
	cursor: pointer;
}
.hoverHeader:hover{
	cursor: pointer;
	color: var(--apColorAccent);
}
a {
	color: var(--apColorBrandLogo1);
	text-decoration: none;
	font-size: 14px;
	line-height: 160%;
}
a:hover {
	text-decoration: underline;
}
a:not(.apDisabled) {
	cursor: pointer;
}
a .messagesRowTextCellFile div:last-child {
	justify-content: center;
}
h1, h2, h3, h4 {
	letter-spacing: 0.006em;
	line-height: 120%;
}
h1, h2, h3 {
	font-weight: bold;
}
h1 {
	font-size: 32px;
}
h2 {
	font-size: 20px;
}
h3 {
	font-size: 16px;
}
h4 {
	font-size: 15px;
	font-weight: 600;
}
h1.apInnerH1 {
	font-weight: 700;
	font-size: 32px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorDark);
	margin-bottom: 17px;
	padding: 0 0 32px 0;
	border-bottom: 1px solid #DEDFE1;
}
.main-section.main-news{
	margin-bottom: 0;
}
.storeSection{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top:40px;
   /* margin-bottom: 50px;
	*/
}
.storeHeader{
	margin-top: 0;
	margin-bottom: 39px;
	font-weight: 700;
	font-size: 32px;
	line-height: 120%;
	max-width: 690px;
}
.storeText{
	display: flex;
	flex-direction: column;
	align-items: baseline;
	justify-content: center;
}
.storeButtonCollection{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}
.storeButtonCollection a{

	margin: 0 20px 10px;
}

button.storeButton{
	min-width: 128px;
	max-width: 128px;
	height: 40px;
}
button.storeButton:first-child{
	margin-right: 36px;
}
.testPage img{
	height: 400px;
	width: auto;
}
.storeImage{
	min-width: 650px;
	max-width: 650px;
	min-height: 590px;
	max-height: 590px;
	background-position: center;
	background-size: cover;
}
.storeHeaderActive{
color: #444F60;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 57.6px */
letter-spacing: 0.288px;
text-transform: uppercase;
}
.storeHeader{
color: #444F60;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 24px */
letter-spacing: 0.12px;
}
button.storeButton:hover{
	box-shadow: 0px 0px 2px 2px var(--apColorBrandBG6);
}
h1.apInnerH1+.groundPropertiesExtraBlock {
	margin-top: 0;
}
p {
	margin-bottom: 8px;
}
body {
	font-family: 'Montserrat', sans-serif;
}
body div.apDictPage {
	min-height: 100%;
}
body+div.apMapPage {
	min-height: 100%;
}
div.apSettingsList div {
	color: var(--apColorDark);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 6px 8px 6px 8px;
	min-height: 36px;
	line-height: 100%;
	border-radius: 4px;
	cursor: pointer;
	width: auto;
}
div.apSettingsList {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	background-color: var(--apColorLight);
	border-radius: 4px;
	width: max-content;
	position: absolute;
	top: 56px;
    /* left: -100%; */
	z-index: 2;
	padding: 8px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
   /* height: 90px;
	*/
}

.apSettingsList.apNewsSettingsListStart{
	right: -100%;
	left: 0;
}
strong {
	font-weight: 600;
	line-height: 120%;
	letter-spacing: 0.006em;
	margin-right: 10px;
}
video::-webkit-media-controls {
	visibility: hidden;
}
video::-webkit-media-controls-enclosure {
	visibility: visible;
}
.actionButtonsBlock .filterBlock {
	width: initial;
	margin-top: initial;
}
.apRoutingBlock{
	display: flex;
	flex-direction: column-reverse;
}
.actionButtonsBlock button {
	margin-right: 16px;
	margin-bottom: 8px;
	margin-top: 8px;
	min-width: fit-content;
}

.ck-widget img{
	width: inherit;
}
.ck.ck-reset_all.ck-widget__type-around{
	display: none;
}
.buttonInInput{
	position: absolute;
	right: 0;
	top: 6px;
	border: 1px solid var(--apColorDark);
	border-radius: 0px 8px 8px 0px;
	height: 38px;
}
.actionButtonsBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}
.apUserButtonWrap {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.apUserButtonWrap>button {
	margin-right: 8px;
}
.menuFunctionLine {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
}
.actionButtonsBlock.apMapPage button {
	margin-right: 24px;
}
.actionButtonsBlock.apMapPage div.apDropDownList {
	margin-bottom: 6px;
}
.popUp {
	position: absolute;
	right: 0;
	top: 6px;
	width: 24px;
	height: 24px;
	z-index: 1000;
	cursor: pointer;
}
span.apBold{
	font-weight:700;
	font-size:15px;
	line-height:18px ;
}
.popUp svg {
	width: 24px;
	height: 24px;
}
.popUp div.apSettingsList {
	top: 30px;
}
.actionTriggersBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 8px;
	margin-top: 8px;
}
.apCandidate {
	background-color: var(--apColorAttention);
}
.modalFormQr {
	position: absolute;
	z-index: 1000;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .5);
	width: 100vw;
	height: 100vh;
	overflow-y: auto;
}
.modalFormQrContainer {
	height: 466px;
	width: 360px;
}

.modalForm {
	position: fixed;
	z-index: 100000000000;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .5);
	width: 100vw;
	height: 100vh;
	overflow-y: auto;


}
.modalFormContainer {
	height: 466px;
	width: 410px;
	margin: 20px auto;
	border-radius: 20px;
	background-color: white;
	padding: 40px 20px;
}


.profileImg {
	width: 230px;
	height: 230px;
	border-radius: 50%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.headerClubSub {
	margin: 15px 0 0;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
}
.headerClubSub span {
	font-weight: 600;
}
.apFCSection {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	position: relative;
	margin-left: -12px;
	margin-right: -12px;
}
.notificationsItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-bottom: 1px solid var(--apColorDarkGray2);
}
.notificationsText {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.statisticPage h2{
	text-align: center;
}
.statisticPage .filterBlock{
	margin: 0 auto 46px;
	width: 560px;
}
.filterRow{
	display: flex;
	flex-direction: row;
	border: 1px solid #C1C1C1;
	border-radius: 8px;
	margin-bottom: 26px;
}
.activeItemType{
	margin: 10px 0 10px 20px;
	color: #FFF;
	display: flex;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px; /* 125% */
	letter-spacing: -0.32px;
}
.activeItemType .leftsideInfoStartPageIcon{
	float: none;
	padding: 2px 6px 0 0;
	margin: 0;
}
.activeItemType .leftsideInfoStartPageIcon svg{
	width: 16px;
	height: 16px;
}
.activeItem{
	aspect-ratio: 16/10;
	/* min-height: 390px; */
	min-width: 380px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	color: white;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
}
.activeItem::before{
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.8) 100%);
	z-index: 1;
}
.activeItem::after{
	content: 'АКТИВНІ ПАРКИ';
	position: absolute;
	right: -40px;
	top: 40px;
	background-color: #1BA8B2;
	z-index: 1;
	text-orientation: upright;
	transform: rotate(-90deg);
	padding: 5px 10px;
	font-size: 10px;
	font-weight: 400;
	line-height: 15px; /* 140% */
	letter-spacing: -0.2px;
}
.activeItemStatistic{
	display: flex;
	justify-content: space-around;
}
.activeItemDetails{
	z-index: 5;
	margin-bottom: 20px;
	margin-top: 10px;
}
.activeItemStatisticData{
	color: #FFF;
	font-size: 18px;
	font-weight: 700;
	line-height: 20px; /* 111.111% */
	letter-spacing: -0.36px;
	text-transform: uppercase;
}
.activeItemStatisticData span{
	font-size: 10px;
}
.activeItemStatisticLabel{
	color: #FFF;
	font-family: e-Ukraine;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 10px; /* 100% */
	letter-spacing: -0.2px;
}
.filterRowItem{
	width: 140px;
	text-align: center;
	color: #444F60;
	font-size: 13px;
	font-weight: 300;
	line-height: 18px; /* 138.462% */
	letter-spacing: -0.26px;
	text-transform: uppercase;
	padding: 4px 0px;
	cursor: pointer;
	border-radius: 8px;
}
.filterRowItem:hover{
	background-color: var(--apColorBrandBG3);
}
.filterRowItemActive{
	background-color: var(--apColorBrandLogo2);
}
.filterDate{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}
.filterDateCompare{
	color: var(--apColorAccent);
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px; /* 140% */
	letter-spacing: -0.2px;
	text-transform: uppercase;
	padding: 0 8px 4px;
	border-bottom: 1px solid var(--apColorAccent);
	cursor: pointer;
}
.notificationsTime {
	justify-self: end;
	white-space: nowrap;
	margin-left: 10px;
}
.flexDirect{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
}
.flexDirect .itemIndex{
	width: 33%;
}
.flexDirect .itemIndex2{
	width: 65%;
}
.flexDirect .item1{
	-webkit-box-flex: 0;
    -ms-flex: 0 0 49%;
    flex: 0 0 49%;
    max-width: 49%;
}
.notificationPage h3 {
	margin-top: 40px;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--apColorGray1);
}
.apFCSectionItem {
	padding-left: 12px;
	padding-right: 12px;
	flex: 0 0 40%;
	max-width: 40%;
	box-sizing: border-box;
}
.apFCSectionItem.date {
	flex: 0 0 20%;
	max-width: 20%;
}
.apFCSectionItem.apFcSectionSmItem {
	flex: 0 0 25%;
	max-width: 25%;
}
.apFCSectionItem.apFcSectionLgItem {
	flex: 0 0 50%;
	max-width: 50%;
}
.apFCSection svg:hover {
	cursor: auto;
}
.apFCSection .videoCardDropAreaAndHint {
	align-items: center;
}
.apFCSection .videoCardDropArea {
	width: 211px !important;
	height: 211px;
	position: relative;
	cursor: pointer;
	transition: .2s;
}
.apFCSection .videoCardDropArea.withBg {
	background-color: transparent;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}
.apFCSection .videoCardDropArea.withBg:before {
	position: absolute;
	content: '';
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: #000;
	opacity: .4;
}
.apFCSectionItem.apFcSectionLgItem .apTextarea{
	margin-top: 0;
}
.apFCSection .videoCardDropAreaAndHint .apParkImagesHintArea {
	margin: 0;
	width: auto;
}
.copyHover:hover{
	fill:var(--apColorAccent) 
}
.videoCardDropAreaAndHint {
   /* padding: 0 26px;
	*/
}
.withBg .dropLabelText {
	color: var(--apColorLight) 
}
.withBg .dropLabelText svg {
	fill: var(--apColorLight) 
}
.dropLabelText {
	position: absolute;
	color: var(--apColorDark);
	display: flex;
	flex-direction: column;
	align-items: center;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.apUserTileItem .bottom svg{
	margin-right: 4px;
}

.trainingMainContent{
	display: flex;
	flex-direction: row;
}
.trainingMainContent.video{
	margin-bottom: 20px;
}
.apFCSectionItem.apFcSectionSmItem .apInputTextIcon:last-child {
	margin: 0;
}
.apFCSectionItem .apTextarea {
	width: 100% !important;
}
.swiper-pagination-bullet:only-child {
	visibility: hidden;
}
.buttonFullWidthScroll{
	display: flex;
	overflow-y: auto;
}
.apFCSectionItem .apTextarea textarea {
	height: auto;
	min-height: 297px;
	max-height: 297px;
}
.dropLabelText svg {
	fill: var(--apColorDark);
	margin: 0 0 10px 0;
}
.apFCSection .videoCardDropAreaAndHint {
	width: 100%;
}
.storeImage.storeImage2{
	display: none;
}
.apCheckbox {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	width: 60px;
	height: 40px;
	padding: 0;
	margin: 0 0 0 12px;
	background-image: URL('./assets/images/switchOff.svg');
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 16px;
	-moz-transition-duration: var(--apTransitionDurationSwiper);
	-webkit-transition-duration: var(--apTransitionDurationSwiper);
	-o-transition-duration: var(--apTransitionDurationSwiper);
	transition-duration: var(--apTransitionDurationSwiper);
}
.apCheckbox+label {
	margin-right: 8px;
	white-space: nowrap;
}
.apCheckbox:checked {
	background-image: URL('./assets/images/switchOn.svg');
}
.apCheckbox:checked:not(:disabled):hover {
	background-image: URL('./assets/images/switchOnHover.svg');
}
.apCheckbox:disabled {
	background-image: URL('./assets/images/switchDisabledOff.svg');
}
.apCheckbox:disabled:checked {
	background-image: URL('./assets/images/switchDisabledOn.svg');
}
.apCheckbox:not(:checked):not(:disabled):hover {
	background-image: URL('./assets/images/switchOffHover.svg');
}
.apCheckbox:not(:disabled) {
	cursor: pointer;
}
.apChips svg {
	width: 24px;
	min-width: 24px;
	height: 24px;
	min-height: 24px;
	cursor: pointer;
}
.apChips {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: 32px;
	border-radius: 16px;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	padding: 4px 8px 4px 12px;
	background-color: var(--apColorLightGray);
	margin-right: 8px;
	margin-top: 4px;
	margin-bottom: 4px;
}
.leaflet-popup-close-button {
	display: none !important;
}
.leaflet-popup-content .apgmtt4 {
	margin-top: 5px;
   /* margin-left: 45%;
	*/
}
img.leaflet-marker-icon {
   /* top: -42px;
	left: -10px;
	*/
}
.infoPage {
	display: flex;
	flex-direction: row;
}
.infoPageSidebar {
	margin-right: 20px;
	padding-right: 20px;
	border-right: 2px solid var(--apColorBrandBG6);
}
.infoPageSidebar div {
	padding: 10px;
	border-bottom: 1px dashed var(--apColorBrandBG6);
	cursor: pointer;
	border-radius: 10px;
}
.parkInfoPage .groundPropertiesNameBlock {
	border: none;
	flex: 0 0 539px;
	max-width: 539px;
	margin-bottom: 25px;
}
.parkInfoPage h3 {
	margin: 0 0 10px 0 !important;
}
.parkInfoPage .groundPropertiesExtraBlock {
	justify-content: flex-start;
}
.parkInfoPage .groundPropertiesOtherParamsLeftBlock {
	padding-left: 0;
	border: none;
}
.parkInfoPage .groundPropertiesOtherParamsRightBlock {
	padding-left: 0;
	border: none;
}
.parkInfoPage .groundPropertiesOtherParamsLeftBlock, .parkInfoPage .groundPropertiesOtherParamsRightBlock {
	max-width: 238px;
	margin-bottom: 16px;
}
.parkInfoPage .groundPropertiesOtherParamsLeftBlock input, .parkInfoPage .groundPropertiesOtherParamsRightBlock input {
	padding: 0 0 0 14px;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	letter-spacing: 0.03em;
	opacity: .9;
	color: var(--apColorDark) 
}
.parkItemImageList {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-bottom: 0;
	margin-left: -31px;
	margin-right: -7px;
	width: calc(100% + 39px);
}
h3.headerHelpedCustom{
	margin-top: 40px!important;
}

.groundPropertiesImageSection .parkItemImageList{
	flex-wrap: nowrap;
	overflow-y: auto;
	gap: 15px;
	padding-bottom: 10px;
	margin-bottom: 20px;
	width:100%;
	margin-left:0px;
	margin-right:0px;
}

.parkItemImageList .apParkImage {
	margin-bottom: 8px;
	width: auto;
	aspect-ratio: 16/10;
	flex: 0 0 20%;
	max-width: 20%;
	padding: 0 7px;
	border: none;
	background: transparent;
	height: auto;
	overflow: hidden;
	border-radius: 8px;
}
.mobileShow{
	display: none;
	color: #1BA8B2;
	font-size: 15px;
	font-weight: 400;
	line-height: 120%; /* 18px */
	letter-spacing: 0.45px;
	text-decoration-line: underline;
	background: none;
	margin: 0 auto;
	cursor: pointer;
}
.parkItemImageList .apParkImage:hover img {
	scale: 1.1;
}
.parkItemImageList .apParkImage img {
	width: 100%;
	vertical-align: bottom;
	height: 60px;
	object-fit: cover;
	border-radius: 8px;
	transition: .3s;
}
.groundPropertiesImageSection .parkItemImageList .apParkImage img{
	height: auto;
}
.apParkCoverImageContainer.parkItem {
	width: auto;
	max-width: calc(100% + 24px);
	margin: 0 0 15px -24px;
}
.groundPropertiesImageSection .apParkCoverImageContainer.parkItem{
	margin:0 0 15px 0;
	max-width:100%;
}
.apParkCoverImageContainer.parkItem div {
	aspect-ratio: 16 / 10;
	background-size: cover;
	width: 100%;
	height: 337px;
	border-radius: 8px;
}
.groundPropertiesImageSection .apParkCoverImageContainer.parkItem div{
	height: auto;
}
.infoPageContent {
	width: 100%;
}
.infoPageContent .dictRowNormal {
	padding: 10px;
}
.infoPageSidebar .active {
	background-color: var(--apColorDarkGray2);
}
.infoPageSidebar div:hover {
	background-color: var(--apColorBrandBG6);
}
.apChips:hover {
	background-color: var(--apColorLight);
	fill: var(--apColorError);
}
.apDeactivated {
	background-color: var(--apColorDarkGray1);
}
.apDeleted {
	background-color: var(--apColorError);
}
.apDisabled label {
	background-color: var(--apColorMediumGray);
	background-image: URL('./assets/images/inputDisabledLabelBG.png');
}
.apDropDownList input {
	cursor: pointer;
}
.apDropDownList input::selection {
	background-color: transparent;
}
.apDropDownList {
	position: relative;
}
/* .apFilterButtonsBlock .secondarySmallButtonTextDefault:hover {
	background-color: var(--apColorGray1);
}
*/
.apEmptyFileList {
	color: var(--apColorDarkGray2);
}
.apFilterButtonsBlock button {
	margin-top: 8px;
	margin-right: 8px;
}
.apFilterButtonsBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	flex-wrap: wrap;
}
.address.fill svg{
	fill: var(--apColorDark)
}
.apFilterContainer button:not(.secondarySmallButtonTextActive,.primaryButtonIconTextDefault,.primaryButtonTextDefault), .apFilterContainer .label:not(.secondarySmallButtonTextActive,.primaryButtonIconTextDefault) {
	background-color: var(--apColorMediumGray);
}
.apFilterContainer button.secondarySmallButtonTextActive:hover, .apFilterContainer .label.secondarySmallButtonTextActive:hover {
	background-color: var(--apColorAccent);
}
.apFilterContainer {
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
	background-color: var(--apColorLight);
	border-radius: 8px;
	min-width: 300px;
	max-width: 510px;
   /* width: 510px;
	*/
	display: flex;
	height: 80vh;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 8px;
	position: fixed;
	z-index: 11000;
	left: calc(50% - 150px);
	margin-right: 10px;
	top: 50px;
	overflow-y: auto;
}
.apFilterGroupBlock .videoCardTextUnderVideo {
	margin: 0;
	padding: 8px 0 8px 0;
	justify-content: space-between;
	border-bottom: 1px solid var(--apColorLightGray);
}
.apFilterGroupBlock span {
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
}
.apFilterGroupBlock span+button {
	margin-top: 8px;
}
.apFilterGroupBlock span+div.videoCardTextUnderVideo {
	border-top: 1px solid var(--apColorLightGray);
}
.apFilterGroupBlock {
	padding: 0 16px 24px 16px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}
.apFilterGroupBlock.apQr{
	align-items: center;
	position: relative;
}
.infoSmallText{
	background: var(--apColorDark);
	color: var(--apColorLight);
	padding: 10px;
	position: absolute;
	bottom:0;
}
.apFilterGroupBlock.apQr button{
	background-color: var(--apColorDark);
}
.apFilterHeader h4, .formBlock .apFilterHeader h2 {
	margin: 0 10px 0 0;
}
.formBlock h2 {
	margin: 30px 0 15px 0;
}
.apInputTextIcon.form div {
	width: 100%;
}
.apFilterHeader svg {
	width: 24px;
	height: 24px;
	fill: var(--apColorDarkGray1);
	cursor: pointer;
}
.apFilterHeader svg:hover {
	fill: var(--apColorDark);
}
.apFilterHeader {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 0 16px 8px 16px;
	border-bottom: 1px solid var(--apColorGray1);
}
.apFilterHeader.form {
	padding: 0;
	border-bottom: none 
}
.apFilterHeader+.apFilterGroupBlock {
	margin-top: 24px;
}
.apFooter {
	margin-top: 96px;
	border-top: 1px solid var(--apColorGray1);
	min-height: 192px;
	width: 100%;
	padding: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.apFooterLinksBlock div {
	margin-left: 16px;
	margin-right: 16px;
}
.apFooterCopyright {
	text-align: center;
	margin-top: 16px;
}
.apFooterLinksBlock {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	text-align: center;
}
.statisticSelector{
	background: none;
	border: none;
	color: var(--apColorAccent);
}
.statisticSelector:hover{
	text-decoration: underline;
	cursor: pointer;
}
.statisticSelector:focus{
	border: none;
}
.apFullHeight button {
	min-width: 300px;
	width: 336px;
	justify-content: center;
	margin-top: 32px;
}
.apFullHeight h1 {
	margin-bottom: 0;
}
.startSection {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.startHeader {
	display: flex;
	flex-direction: column;
}
.startHeader p {
	color: var(--apColorDark);
	font-size: 1.4rem;
	margin-bottom: 0px;
}
.startHeader h2 {
	min-height: 232px;
	display: flex;
	align-items: center;
}
select{
	font-family: 'Montserrat';
}
.startHeader h2, .secondSection h2 {
	margin: 20px 0;
	font-size: 3rem;
}
.secondSection h2 {
	text-align: center;
	margin-bottom: 25px;
}
.secondSection{
	margin-bottom: 40px;
}
.thirdSection h1 {
	margin-top: 1.5rem;
}
.forthSection {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
   /* align-items: center;
	*/
}
.forthSection .videoPlayerContainer {
	width: 60%;
	height: fit-content;
	margin-bottom: 40px;
}
.forthSectionText {
	width: 40%;
	padding-right: 20px;
}
.clubItem .img-wrp{
	margin: 0 8px;
}
.clubItemWrap{
	border-radius: 20px;
}
.clubItem .img-wrp img{
	border-radius: 10px;
}
.forthSectionText p {
	font-size: 14px;
}
.main-clubs.main-section .headline{
	margin-bottom: 20px;
	/* padding-bottom: 20px; */
}
.parkInfoItemLabel {
	padding-bottom: 0.5rem;
	border-bottom: 1px dashed var(--apColorDarkGray1);
	margin-bottom: 0.5rem;
	width: 100%;
}
.iconButtonTrack{
	height: 40px;
	margin-top: 4px;
}
.parkInfoItemLabel label {
	font-size: 1rem;
	font-weight: bold;
}
.parkInfoItemLabel div {
	font-size: 0.9rem;
	font-weight: 200;
}
.calendarFilter{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 27px 0;
}
.calendarFilterHeader{
	font-weight: 700;
	font-size: 20px;
	margin-right: 20px;
}
.footerlogo{
	/* background: black;
    border-radius: 50px;
    padding: 20px 20px 12px; */
	margin-top: 10px;
}
.footerlogo img{
	height: 24px;
}
.calendarFilterItem{
	font-weight: 600;
	font-size: 14px;
	line-height: 160%;
	margin-right: 16px;
	cursor: pointer;
}
.startList {
	margin: 30px 0;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}
.startList div {
	padding-bottom: 0px;
	margin-top: 20px;
	background:var(--apColorDarkGray1);
	cursor: pointer;
	min-width: 120px;
	height: 4px;
	border-radius: 4px;
}
.startHeader h2{
	min-height: 310px;
}
.buttonBlock{
	margin: 0 auto 20px;
}
.startList .selectedStart {
	background:var(--apColorAccent);
	/* min-width: 120px; */
}
.startList div:nth-child(2) {
	margin: 20px 10px 0 10px;
}
.startHeader button, .sportEventsCard button {
	width: min-content;
	background-color: var(--apColorAccent);
	cursor: pointer;
}
.startHeader button:hover, .sportEventsCard button:hover {
	background-color: var(--apColorBrandBG2);
}
.cardsList {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}
.cardSecond {
	display: flex;
	flex-direction: row;
	background: var(--apColorBrandBG1);
	border-radius: 10px;
	padding: 30px 0;
	width: 30%;
	margin-bottom: 10px;
	max-width: 375px;
	min-width: 375px;
	color: var(--apColorLight);
}
.ck-powered-by__label,.ck.ck-powered-by{
	display: none;

}
.cardSecondIcon {
	width: 33%;
	text-align: center;
}
.cardSecondIcon div {
	margin-left: 10px;
	background-size: cover;
	width: 80px;
	height: 90px;
}
.cardSecondText {
	width: 67%;
	padding-left: 10px;
	border-left: 2px dashed var(--apColorLight);
}
.cardSecondText div {
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 0.5rem;
	margin-top: 0.2rem;
}
.cardSecondText p {
	font-size: 0.9rem;
	margin-bottom: 0;
}
.newsList {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
	justify-content: flex-start;
}
.startsButton {
	background-color: var(--apColorLight);
	border-radius: 100px;
	padding: 10px 20px;
	color: var(--apColorAccent);
	font-weight: 300;
	margin: 1.5rem auto 0;
}
.mobileMenu{
	display: none;
}
.startsButton:hover {
	background-color: var(--apColorAccent);
	color: var(--apColorLight);
}
.startsButton:hover svg {
	fill: var(--apColorLight);
}
.startsButton svg {
	padding-right: 5px;
	fill: var(--apColorAccent);
}
.apNewsCardsContent {
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0 0 10px rgb(0 0 0 / 15%);
	background-color: var(--apColorLight);
}
.parkInfoOther {
	display: flex;
	margin: 0 -16px 32px;
}
.parkInfoOther>div {
	padding: 0 16px;
}
.parkInfoOther>div:first-child {
	flex: 0 0 45%;
	max-width: 45%;
}
.parkInfoOther>div:last-child {
	flex: 0 0 50%;
	max-width: 50%;
}
.parkInfoOther .leaflet-container {
	max-height: 300px !important;
	border-radius: 10px;
	overflow: hidden;
}
.apCoordinators {
	flex: 1 1 auto;
}
.apCoordinators {
	margin-bottom: 24px;
}
.apCoordinators .item {
	background-color: var(--apColorLight);
	margin-bottom: 30px;
	padding: 10px;
	display: flex;
	align-items: flex-start;
	margin-bottom: 16px;
	border-radius: 8px;
	
}
.apMapTileContainerRoute{
	display: flex;
	flex-direction: column;
	width: 380px;
	/* height: 300px; */
	height: auto;
	margin-bottom: 20px;
}
.buttonsNav{
	display: flex;
	padding: 24px 16px;
	justify-content: space-between;
}
.leaflet-center {
	left: 20%;
}
.apCoordinators .item:last-child {
	margin-bottom: 0;
}
.apCoordinators .imgWrp {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	margin-right: 12px;
	background-color: var(--apColorGray1);
	border: 1px solid rgba(68, 79, 96, .1);
	position: relative;
	display: flex;
	font-size: 14px;
	font-weight: 600;
	justify-content: center;
	align-items: center;
}
.apCoordinators .imgWrp span{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.apCoordinators .imgWrp img ~ span{
	opacity: 0;
}
.apCoordinators img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	vertical-align: middle;
	border-radius: 50%;
}
.apCoordinators ul {
	padding: 0;
	margin: 0;
}
.apCoordinators li {
	margin-bottom: 0;
	list-style: none;
}
.apTileContainer {
	flex: 0 0 33.3%;
	max-width: 33.3%;
	margin-bottom: 20px;
	padding: 0 10px;
}
.apTile div{
	padding: 2px 8px;
	background: #C5E505;
	border-radius: 48px;
	margin-bottom: 19px;
	margin-left: 15px;
	color: #444F60;
	font-weight: 500;
	font-size: 11px;
	width: max-content;
}
.apMapTileContainer {
	flex: 0 0 25%;
	max-width: 25%;
	min-width: min-content;
	padding: 0 12px;
	margin-bottom: 24px;
}
.apMapTileContainerRoute .apMapTile img{
	height: 220px;
}
.topsideInfoStartPage{
	position: absolute;
	display: flex;
	width: 100%;
	top:0;
	justify-content: space-between;
}
.topsideInfoStartPage .isFilled svg{
	fill: red;
}
.eapps-instagram-feed-content{
	border-radius: 5px;
}
.eapps-instagram-feed-posts-slider-inner{
	width: 100%!important;
}
.item1 #eapps-instagram-feed-1{
	width: 100%;
}
.leftsideInfoStartPageIcon{
	float: left;
	margin-right: 10px;
    padding-top: 5px;
}
.leftsideInfoStartPageIcon svg path{
	stroke: white!important;
}

.apMapTile {
	padding: 10px;
	border-radius: 10px;
	background-color: var(--apColorLight);
	height: 100%;
	position: relative;
}
.apMapTile .title{
	display: flex;
	flex-direction: column;
	font-weight: 500;
	font-size: 13px;
	line-height: 120%;
	color: var(--apColorDark);
	padding-bottom: 17px;
	padding-top: 16px;
	box-sizing: border-box;
	min-height: 102px;
}
.ck.ck-toolbar.ck-toolbar_grouping.ck-reset_all.ck-rounded-corners{
	display: none;
}
.visibleTextBlock .ck.ck-toolbar.ck-toolbar_grouping.ck-reset_all.ck-rounded-corners{
	display: flex;
}
p.title{
	font-weight: 500;
	font-size: 13px;
	line-height: 120%;
	color: var(--apColorDark);
	margin-bottom: 0;
}
.apMapTile .title span {
	margin-top: 5px;
}
.apMapTile .title>* {
	width: 100%;
}
.apMapTile .buttons {
	display: flex;
	margin: 0 -4px;
}
.apMapTile .buttons>div {
	flex: 0 0 50%;
	max-width: 50%;
	padding: 0 4px;
}
.apMapTile .buttons>div button {
	width: 100%;
	height: 100%;
	text-align: center;
	box-sizing: border-box;
	justify-content: center;
	transition: .2s;
}
.apMapTile .buttons>div .secondaryButtonTextDefault:hover {
	background-color: var(--apColorAccent);
	color: var(--apColorLight);
}
.apTile {
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0 0 10px rgb(0 0 0 / 15%);
	background-color: var(--apColorLight);
	text-decoration: none !important;
	height: 100%;
	display: flex;
	align-items: stretch;
	flex-direction: column;
}
.apTile>* {
	width: 100%;
}
.apTile span {
	font-weight: 600;
	font-size: 1.1rem;
	color: var(--apColorDark);
   /* padding: 1rem;
	*/
	display: inline-block;
	background-color: var(--apColorLight);
	z-index: 2;
	position: relative;
	transition: .2s;
	height: 100%;
	vertical-align: middle;
   /* min-height: 76px;
	*/
	padding:15px 15px 10px;
	display: flex;
	align-items: center;
}
.apTile img, .apMapTile img {
	width: 100%;
	max-width: 100%;
	min-width: 100%;
	text-align: center;
	aspect-ratio: 16/10;
	object-fit: cover;
	vertical-align: middle;
	min-height: 217px;
	background-color: var(--apColorLightGray);
	transition: .2s;
}
.apMapTile img {
	min-height: 147px;
	border-radius: 8px;
}
.apTile:hover img {
	scale: 1.1;
}
.apTile:hover span {
	color: #1BA8B2;
}
.apNewsCard {
	flex: 0 0 33.3%;
	max-width: 33.3%;
	margin-bottom: 20px;
	padding: 0 10px;
}
.CalendarListDate {
	color: white;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-color: var(--apColorAccent);
	width: fit-content;
	padding: 6px 20px;
	font-size: 16px;
	font-weight: 700;
}
.apNewsCard div:nth-child(1) {
	height: 242px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
}
.apNewsCard div:nth-child(2) {
	font-weight: 600;
	font-size: 1.1rem;
	color: var(--apColorDark);
	padding: 1rem;
}
.apNewsCardText:hover {
	color: var(--apColorAccent) !important;
	cursor: pointer;
}
.apEventsGlobalContainer {
	max-width: 100%;
	overflow-x: auto;
}
.apEventsContainer {
	border: 2px solid #1BA8B2;
	border-radius: 0px 7px 7px 7px;
	min-width: 800px;
	margin-bottom: 32px;
}
.apCollapseInner.isOpen{
	width: 100%;
}
.apEventsContainer:not(.fallback) {
	transition: .3s;
	cursor: pointer;
}
.apEventsContainer:not(.fallback):hover {
	background-color: #f1f1f1;
}
.apEventsContainer:last-child {
	margin: 0;
}
.apEventsContainer.fallback {
	border-radius: 7px;
	padding: 32px;
	background-color: rgba(0, 0, 0, .03);
	text-align: center;
	font-size: 15px;
	font-weight: 600;
	border: none;
}
.apEventItem {
	display: flex;
	justify-content: flex-start;
	text-decoration: none !important;
}
.apEventItem.finishedEvent{
	/* background-color: var(--apColorDarkGray2); */
	opacity: 0.3;
} 
.apEventItem.holdingEvent{
	background-color: rgba(197, 229, 5, 0.3);
} 
.apEventItem.futureEvent{
	/* background-color: var(--apColorDarkGray1); */
} 
.apEventItem>div {
	flex: 1 1 auto;
	min-height: 67px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 8px 12px;
	box-sizing: border-box;
	position: relative;
}
.apEventItem>div:after {
	position: absolute;
	width: 1px;
	height: 31px;
	top: 50%;
	right: 0;
	translate: 0 -50% 0;
	background-color: #DEDFE1;
}
.sliderBlockNotifications .swiper-pointer-events{
	padding-bottom: 40px;
}
.apEventItem>div:not(:last-child):after {
	content: '';
}
.apEventItem:not(:last-child) {
	border-bottom: 1px solid #DEDFE1;
}
.apEventItem>div {
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorDark) 
}
.apEventItem>div:not(text) span {
	font-weight: 700;
	font-size: 15px;
	line-height: 15px;
}
.apEventItem .distance{
	flex: 0 0 10%;
	max-width: 10%;
}
.apEventItem .date {
	flex: 0 0 13%;
	max-width: 13%;
}
.apEventItem .title{
	flex: 0 0 24%;
	max-width: 24%;
}
.apEventItem .geo {
	flex: 0 0 35%;
	max-width: 35%;
}
.geo span:hover, .title span:hover{
	text-decoration: underline;
	color: var(--apColorAccent);
}
.apEventItem .text {
	flex: 0 0 17%;
	max-width: 27%;
	font-weight: 500;
}
.searchMapTab {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 20px;
}
.searchMapTab .apInputText {
	width: 32%;
	margin-right: 10px;
}
.leaflet-control.leaflet-bar {
	border: none !important;
}
.leaflet-center {
	left: 33%;
}
.APleaflet-button {
	background-color: var(--apColorLight);
}
.APleaflet-button:hover {
	background-color: var(--apColorGray1);
}
.apRangeInput {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
	z-index: 1;
}
input[type="range"] {
	margin-bottom: 5px;
	-webkit-appearance: none;
	appearance: none;
	margin-right: 15px;
	width: 100%;
	height: 7px;
	background: rgba(255, 255, 255, 0.6);
	border-radius: 5px;
	background-image: linear-gradient(var(--apColorBrandBG1), var(--apColorBrandBG2));
	background-repeat: no-repeat;
}
input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background: var(--apColorAccent);
	cursor: ew-resize;
	box-shadow: 0 0 2px 0 #555;
	transition: background .3s ease-in-out;
}
input[type=range]::-webkit-slider-runnable-track {
	-webkit-appearance: none;
	box-shadow: none;
	border: none;
	background: transparent;
}
.petalLeft{
	min-width:330px;
}
.petalLeft1{
	display: block;
}
.petalLeft2{
	display: none;
	/* margin-bottom: 20px; */
}
.sportEventsCard {
	position: relative;
	border-radius: 10px;
	border: 0;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
	padding: 15px 15px 15px 80px;
	background-color: var(--apColorLight);
	margin: 30px 0 0 60px;
	width: 90%;
}
.sportEventsCardTimeLocation {
	color: var(--apColorAccent);
	cursor: pointer;
}
.sportEvents {
	display: flex;
	flex-direction: row;
}
.sportEventsCoordinator {
	background-color: var(--apColorLight);
	display: flex;
	flex-direction: row;
	padding: 10px;
	margin-bottom: 30px;
}
.sportEventsLineItem {
	width: 32%;
	border-right: 1px dashed var(--apColorBrandBG6);
	margin-right: 10px;
}
.sportEventsLineItem.last {
	margin-right: 0;
	border: none;
}
.sportEventsCardTimeLocation:hover {
	color: var(--apColorAccent);
	color: var(--apColorBrandBG2);
	text-decoration: underline;
}
.sportEventsCardTime {
	display: flex;
	flex-direction: row;
	margin: 20px 0;
}
.sportEventsCardBody {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.sportEventsCardBodyText {
	margin-left: 20px;
}
.sportEventsCardDate {
	position: absolute;
	border-radius: 50%;
	border: 5px solid var(--apColorLight);
	background-color: var(--apColorBrandBG3);
	text-align: center;
	vertical-align: middle;
	height: 120px;
	width: 120px;
	padding-top: 20px;
	left: -60px;
	top: 30px;
	color: var(--apColorLight);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.sportEventsCardDate span {
	font-size: 1.2rem;
}
.sportEventsCardDate span:first-child {
	font-size: 2rem;
	font-weight: 700;
}
.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline, .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
	overflow-y: visible;
	min-width: 100%;
	max-width: 100%;
	max-height: 400px;
	min-height: 300px;
	margin-bottom: 16px;
}
.sportEventsCardBodyImage {
	height: 187px;
	width: 300px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.sportEventsCard h4 {
	margin: 10px 0 0;
	font-weight: 700;
	font-size: 1.5rem;
	cursor: pointer;
}
.sportEventsListBlock {
	display: flex;
	flex: 0 0 30%;
	max-width: 30%;
	box-sizing: border-box;
	flex-direction: column;
	padding-left: 20px;
	border-left: 1px dashed var(--apColorBrandBG6);
}
.sportEventsCard h4:hover {
	color: var(--apColorAccent);
}
.sportEventsBlock {
	flex: 0 0 70%;
	max-width: 70%;
	padding-right: 20px;
}
.sportEventsBlock>* {
	max-width: 100%;
}
.sportEventsBlock h2 {
	font-size: 2rem;
	margin: 30px 0 20px;
}
.sportEventsListBlock h2 {
	font-size: 1.6rem;
	margin: 30px 0 25px;
}
.sportEventsListBlockCard {
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px dashed var(--apColorBrandBG6);
}
.sportEventsListBlockCard h5 {
	color: var(--apColorAccent);
	font-size: 1rem !important;
	line-height: 1.4rem;
	font-weight: 700;
	padding-bottom: 10px;
}
.sportEventsBlock h3 {
	font-size: 1.1rem;
	margin: 0 0 10px;
}
.sportEventsBlockImage {
	height: calc(1200px*0.7/16*10);
	width: calc(1200px*0.7);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 20px;
}
.sportEventsLine {
	display: flex;
	flex-direction: row;
	margin: 20px 0;
}
.sportEventsListBlockCardImage {
	width: 100%;
	height: auto;
	aspect-ratio: 16/10;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 10px;
}
.headerSideText {
	font-size: 1rem;
	font-weight: 400;
	float: right;
	margin: 0 0 0 40px;
	padding-left: 20px;
	border-left: 1px solid var(--apColorDark);
	max-width: 600px;
	line-height: normal;
}
.headerWithButtonsHeaderBlock.zahody {
	justify-content: space-between;
	align-items: center;
}
.apFullHeight {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.apInformation div button {
	margin-top: 16px;
}
.apInformation div {
	min-width: 300px;
	width: 100%;
	background: var(--apColorDark);
	box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 16px 56px 16px 16px;
	color: var(--apColorLight);
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	margin-top: -24px;
}
.apInformation svg {
	width: 24px;
	height: 24px;
	fill: var(--apColorLight);
	cursor: pointer;
	position: relative;
	top: 16px;
	right: 16px;
}
.apInformation {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	position: fixed;
	width: 344px;
	bottom: 24px;
	left: 5%;
	z-index: 99999;
	animation: fadeIn 0.5s ease-in forwards;
}

.apInformation.fadeOut {
	animation: fadeOut 0.5s ease-in forwards;
  }
  

@keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }
  
  @keyframes fadeOut {
	from {
	  opacity: 1;
	}
	to {
	  opacity: 0;
	}
  }
.apInputText input {
	padding: 8px 14px 8px 14px;
	margin-top: -7px;
}
.apInputTextIcon .subtitle,  .apInputText .subtitle{
	font-size: 11px;
	margin: 4px 0 0 0 !important;
}
.apInputText input, .apInputTextIcon input, .apDropDownList input {
	height: 40px;
	width: 100%;
	background-color: var(--apColorLightGray);
	border: 1px solid var(--apColorDark);
	color: var(--apColorDark);
	border-radius: 8px;
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	outline: none;
}
.apInputText input:disabled, .apInputTextIcon input:disabled, .apDropDownList input:disabled, .apTextarea textarea:disabled {
	background-color: var(--apColorMediumGray);
	border-color: var(--apColorDarkGray2);
}
.apInputText label, .apInputTextIcon label, .apDropDownList label {
	font-weight: 600;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	background-color: var(--apColorLightGray);
	background-position: center;
	padding: 0 6px 0 6px;
	position: relative;
	left: 8px;
	z-index: 1;
}
.apDropDownList label::selection {
	background-color: transparent;
}
.marker-icon{
	background: transparent;
	text-align: center;
}
.leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive{
	background: transparent;
	border: none;
}


div.leaflet-div-icon{
	width: 14px!important;
	height:30px!important;
	z-index: 10000000!important;
	color: white;
	cursor:unset
}


.leaflet-container.leaflet-touch.leaflet-retina{
	position: relative;
}
.leaflet-container::before {
	top: 50%;
	left: 50%;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 2px solid white;
	position: absolute;
	content: '';
	z-index: 110000000;
	margin-top: -8px;
	margin-left: -7px;
  }


  .leaflet-container::after {
	top: 50%;
	left: 50%;
	position: absolute;
	content: '+';
	color:#000;
	font-size: 48px;
	font-weight: 100;
	z-index: 100000000;
	margin-top: -42px;
	margin-left: -14px;
  }
.apInputText span, .apInputTextIcon span, .apDropDownList span {
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	width: inherit;
	padding: 0 16px 0 16px;
	margin-top: 2px;
}
.apInputText, .apInputTextIcon, .apDropDownList {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 196px;
   /* width: 100%;
	*/
	margin-bottom: 18px;
}
.apInputText:focus-within input, .apTextarea:focus-within textarea {
	background-color: var(--apColorLight);
	border-width: 2px;
	padding: 7px 13px 7px 13px;
}
.apInputText:focus-within label, .apInputTextIcon:focus-within label, .apDropDownList:focus-within label, .apTextarea:focus-within label {
	background-color: var(--apColorLightGray);
	background-image: URL('./assets/images/inputFocusedLabelBG.png');
}
.statusCircle{
	width: 8px; 
	height: 8px;
	margin-right: 5px;
	border-radius: 50%;
}
.statusCircle.gray{
	background-color: gray;
}
.statusCircle.blue{
	background-color: #1BA8B2;
}
.statusCircle.orange{
	background-color: orange;
}
.apInputTextIcon input, .apDropDownList input {
	margin-right: -24px;
	padding: 8px 40px 8px 14px;
}
.apInputTextIcon label+div, .apDropDownList label+div {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: -7px;
}
.main-hero .img-wrp.img-wrp-mob{
	display: none;
	margin: 20px auto;
}
.apInputTextIcon svg, .apDropDownList svg {
	width: 24px;
	height: 24px;
	position: relative;
	left: -8px;
	cursor: pointer;
	fill: var(--apColorDark);
}
.main .age{
	margin-top: 5px;
}
.apInputTextIcon:focus-within input, .apDropDownList:focus-within input {
	background-color: var(--apColorLight);
	border-width: 2px;
	padding: 7px 39px 7px 13px;
}
.apKeywordButton svg {
	fill: var(--apColorLight);
	cursor: pointer;
	width: 24px;
	height: 24px;
}
.apKeywordButton {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 40px;
	padding: 16px;
}
.apKeywordButtonChevronDown {
	display: block;
}
.apKeywordButtonChevronUp {
	display: none;
}
.apKeywordButtonList {
	min-width: 160px;
	min-height: 40px;
	display: flex;
	position: absolute;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	border-radius: 8px;
	overflow: hidden;
	background-color: var(--apColorDark);
	color: var(--apColorLight);
	font-weight: 600;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	margin-top: 10px;
	margin-right: 10px;
}
.apKeywordList div {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
}
.apKeywordList div:not(:first-child) {
	margin-top: 12px;
}
.apKeywordList svg {
	width: 18px;
	height: 24px;
	margin-right: 8px;
}
.apKeywordList {
	display: none;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	padding: 16px;
}
.apLoginFormContainer .apInputTextIcon, .apLoginFormContainer .apInputText, .apLoginFormContainer .apDropDownList {
	margin-bottom: 18px;
	width: 100%;
}
.coverList{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	position: absolute;
	top: 50px;
}
.statisticList{
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	margin: 0 auto;
	justify-content:center;
}
.statisticListItem{
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
}
.statisticItem{
	display: flex;
	gap: 10px;
	min-width: 157px;
	align-items: center;
}
.activityList{
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}
.statisticItemText{
	color: #444F60;
	font-size: 18px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: -0.36px;
	text-transform: uppercase;
}
.statisticItemText.compare{
	color: var(--apColorAccent);
}
.statisticItemText span{
	font-size: 10px;
}
.statisticItemLabel{
	color: #959595;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 10px; /* 100% */
	letter-spacing: -0.2px;
}

.coverItem{
	padding:4px 12px 4px 12px;
	background-color: var(--apColorLight);
	border-radius: 16px; 
	margin-right: 12px;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
}
.crossButton{
	color: red;
	cursor: pointer;
	margin-left: 5px;
}

.apLoginFormContainer button {
	width: 100%;
	margin-top: 4px;
	justify-content: center;
}
.apLoginFormContainer h3 {
	margin: 0px 40px 20px 2px;
}
.apLoginFormContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	min-width: 320px;
	min-height: 262px;
	position: fixed;
	left: calc(50% - 160px);
	top: calc(50% - 230px);
	background-color: var(--apColorLightGray);
	border-radius: 8px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2);
	padding: 24px;
	z-index: 6;
}

.buttonDia{
	margin-top: 0px;
    border-radius: 50%;
    background: transparent;
	padding: 0;
}
.buttonDia svg{
	width: 75px;
    height: 36px;
}
.motivationBlock{
	background-color: #444F60;
	padding: 32px 102px;
	color:#FFF;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.motivationBlockLeft{
	width: 102px;
}
.motivationBlockRight h3{
	color: #FFF;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px; /* 133.333% */
	letter-spacing: -0.36px;
	text-transform: uppercase;
	margin: 0 0 23px;
}
.motivationBlockText{
	color: #FFF;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px; /* 140% */
	letter-spacing: -0.2px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 8px;
}
.motivationBlockLine{
	margin-bottom: 8px;
}
.motivationBlockText span{
	color: #1BA8B2;
}
.leftSideText{
	color: #FFF;
	text-align: center;
	font-size: 8px;
	font-style: normal;
	font-weight: 400;
	line-height: 10px; /* 125% */
	letter-spacing: -0.16px;
}
.leftSideText span{
	color: #1BA8B2;
	font-size: 8px;
	font-style: normal;
	font-weight: 700;
	line-height: 10px;
	letter-spacing: -0.16px;
}
.askButton{
	border-radius: 50%;
	border: 1px solid white;
	width: 16px;
	height: 16px;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
}
.motivationBlockLeft svg{
	stroke: white;
	margin-left: 10px;
}
.motivationBlockWeek{
	margin-top: 16px;
	color: #FFF;
	text-align: center;
	font-size: 10px;
	font-weight: 400;
	line-height: 14px; /* 140% */
	letter-spacing: -0.2px;
}
.motivationBlockWeekText{
	color: #FFF;
	text-align: center;	
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px; /* 140% */
	letter-spacing: -0.2px;
}

.motivationBlockSeparator{
	border-right: 2px #3C4553 solid;
}
.motivationBlockRight{
	max-width: 792px;
	min-width: 80%;
}
.motivationBlockWeekBlock{
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
}
.motivationBlockWeekDay{
	color: #C1C1C1;
	width: 24px;
	height: 24px;
	padding: 4px 2px;
	border-radius: 100px;
	border: 0.5px rgba(193, 193, 193, 0.50);
	background: rgba(222, 223, 225, 0.50);
	cursor: pointer;
}
.motivationBlockWeekDay.dayNone{
	border: 0.5px #FFF;
	background: #F4575D;
	color: #FFF;
}
.motivationBlockWeekDay.dayParticical{
	border: 0.5px #FFF;
	background: #F3BB13;
	color: #FFF;
}
.motivationBlockWeekDay.dayFull{
	border: 0.5px #FFF;
	background: #B0C105;
	color: #FFF;
}

@keyframes fillAnimation {
	0% {
	  stop-color: rgba(0,0,0,0);
	}
	100% {
	  stop-color: #1BA8B2;
	}
  }
  
  .stop1, .stop2 {
	animation: fillAnimation 5s forwards;
  }

.apLoginFormContainer>svg {
	position: absolute;
	right: 0px;
	top: 0px;
	margin-right: 22px;
	margin-top: 22px;
	fill: var(--apColorDark);
	width: 24px;
	height: 24px;
	cursor: pointer;
}
.apLoginFormContainer>svg:hover {
	fill: var(--apColorDark);
}
.apLoginFormFiledWithButton .apInputText {
	min-width: initial;
	width: 108px;
	flex-grow: 1;
}
.apLoginFormFiledWithButton button {
	margin-bottom: 18px;
	width: initial;
	margin-left: 16px;
	flex-grow: 1;
}
.apLoginFormFiledWithButton {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.apLink.mainNavButtonIconActive:hover {
	background-color: var(--apColorMediumGray);
}
.apLink.active {
	color: var(--apColorBrandBG2);
}
.apLink:hover {
	text-decoration: none;
}
.apLink {
	border-radius: 8px;
	font-weight: 600;
}
.apMapZoomButton {
	margin: 2px 10px 2px 0;
}
.apNameDeactivated span {
	color: var(--apColorDarkGray2);
}
.apNameDeleted span {
	color: var(--apColorError);
}
.apOnRepair {
	background-color: var(--apColorBrandBG4);
}
.apOpenedList .apKeywordButtonChevronDown {
	display: none;
}
.apOpenedList .apKeywordButtonChevronUp {
	display: block;
}
.apOpenedList .apKeywordList {
	display: flex;
}
.apOptionsList div {
	color: var(--apColorDark);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 6px 8px 6px 8px;
	min-height: 36px;
	line-height: 100%;
	border-radius: 4px;
	cursor: pointer;
}
.apOptionsList.searchAdress div{
	min-height: auto;
   /* max-height: 36px;
	*/
	font-size: 12px;
	font-weight: 400;
}
.apOptionsList div.apActive {
	color: var(--apColorAccent);
}
.calendarFilterItem.secondaryButtonIconTextDefault.apActive{
	color: white;
	background-color: var(--apColorAccent);
}
.calendarFilterItem.secondaryButtonIconTextDefault.apActive svg{
	fill: white;
	color: white;
}

.apOptionsList div:hover {
	background-color: var(--apColorMediumGray);
}
.loginSeparator{
	text-align: center;
	margin-bottom: 24px;
	position: relative;
	color: var(--apColorDark);
}
.loginSeparator::before{
	position: absolute;
	left: 0;
	top: 12px;
	width: 50px;
	border-bottom: 1px solid var(--apColorDark);
	content: '';
}
.loginSeparator::after{
	position: absolute;
	right: 0;
	top: 12px;
	width: 50px;
	border-bottom: 1px solid var(--apColorDark);
	content: '';
}
.buttonBlock{
	display: flex; 
	gap: 15px;
}

.apOptionsList {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	background-color: var(--apColorLight);
	border-radius: 4px;
	position: absolute;
	top: 50px;
	z-index: 120000000;
	padding: 8px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
	max-height: 60vh;
	overflow-y: auto;
}
.apOverlay {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: rgba(68, 79, 96, 0.8);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	z-index: 100;
}
.moreButton, .moreButtonBefore{
	background: #1BA8B2;
	border-radius: 8px;
	align-items: center;
	padding: 8px 16px 8px 8px;
	color: white;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	margin: 0 auto;
}
.moreButton{
	margin-top: 20px;
}
.moreButton svg{
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	padding: 0 8px;
}
.moreButtonBefore svg{
	padding: 0 8px;
}
.apPaginator button {
	margin-right: 2px;
	margin-left: 2px;
	background-color: var(--apColorLightGray);
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	min-width: 32px;
	height: 32px;
	padding: 4px;
	text-align: center;
	justify-content: center;
}
.apPaginator button.apActive {
	background-color: var(--apColorAccent);
	color: var(--apColorLight);
	cursor: initial;
}
.apPaginator button:first-child {
	margin-right: 14px;
}
.apPaginator button:last-child {
	margin-left: 14px;
}
.apPaginator span {
	width: 40px;
	text-align: center;
}
.apPaginator {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 44px;
}
.apParkCoverImageContainer div span {
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
	color: var(--apColorLight);
	margin-bottom: 6px;
	min-width: 24px;
	text-align: center;
}
.apParkCoverImageContainer div {
	width: 208px;
	height: 126px;
	background-image: linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), URL('./assets/images/emptyImage.png');
	background-position: center;
	background-size: cover;
	border-radius: 4px;
	border: 1px solid rgba(68, 79, 96, 0.1);
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}
.activeItemDate{
	color: #FFF;
	font-size: 13px;
	font-weight: 700;
	line-height: 18px;
	letter-spacing: -0.26px;
	padding-left: 20px;
}

.groundPropertiesImageSection .apParkCoverImageContainer div{
	height:auto;
}
.apParkCoverImageContainer div+span {
	width: 207px;
	text-align: center;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	margin-top: 4px;
}
.apParkCoverImageContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-right: 8px;
	margin-left: 8px;
	flex-grow: 1;
	width: 207px;
	margin-bottom: 16px;
}
.apParkImage {
	width: 110px;
	height: 65px;
	background-image: linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1)), URL('./assets/images/emptyImage.png');
	background-position: center;
	background-size: cover;
	border-radius: 4px;
	border: 1px solid rgba(68, 79, 96, 0.1);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
	position: relative;
}
.apParkImageTools div.apSettingsList {
	top: 32px;
	z-index: 7;
}
.apParkImageTools span {
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
	text-align: center;
   /* letter-spacing: 0.006em;
	*/
	color: var(--apColorLight);
	margin-bottom: 6px;
	min-width: 24px;
}
.apParkImageTools svg {
	width: 24px;
	height: 24px;
	cursor: pointer;
	fill: var(--apColorLight);
}
.apParkImageTools {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	position: absolute;
	right: 0px;
}
.apParkImagesContainer {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	align-items: flex-start;
	flex-wrap: wrap;
	flex-grow: 10;
	width: 285px;
   /* margin-bottom:16px;
	*/
	margin-right: 8px;
	margin-left: 8px;
}

.apParkImagesContainer.apParkImagesContainerRow{
	column-gap: 12px;
	width:100%;
}
.groundPropertiesPhotosBlockTrack .apParkCoverImageContainer, .groundPropertiesPhotosBlockTrack .apParkCoverImageContainer div{
	width: 282px;
	height: 175px;
}
.apParkImagesContainer div span {
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
	text-align: center;
   /* letter-spacing: 0.006em;
	*/
	color: var(--apColorLight);
	margin-bottom: 6px;
	min-width: 24px;
}
.apParkImagesDropArea svg {
	margin-bottom: 8px;
}
.apParkImagesDropArea {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	width: 207px;
	height: 111px;
	background-color: var(--apColorLightGray);
	border: 2px dashed var(--apColorDark);
	border-radius: 8px;
	fill: var(--apColorDark);
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	text-align: center;
	margin-right: 8px;
	margin-left: 8px;
   /* margin-top: -64px;
	*/
	margin-bottom: 16px;
}

.imageItemGalleryButtons{
	position: absolute;
	display: none;
	gap: 5px;
	top: 0px;
	right: 0px;
	margin: 0 auto;
	padding: 7px;
	/* background: var(--apColorBrandBG6); */
	border-radius: 5px;
}

.imageItemGalleryButtons svg path{
	stroke: black!important;

}
.imageItemGalleryButtons button.fill:hover svg{
	fill: var(--apColorAccent);
}

.imageItemGalleryButtons button:hover svg path{
	stroke: var(--apColorAccent)!important;
} 

.imageItemGallery:hover .imageItemGalleryButtons{
	display: flex;
}
.roundUser{
	position: absolute;
	right: 20px;
	bottom: 10px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background: var(--apColorAccent);
	color: white;
	border: 1px solid var(--apColorAccent);
	padding-left: 8px;
	padding-top: 4px;
	font-size: 10px;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;	
}
.amountUser{
	position: absolute;
	right: 20px;
	bottom: 3px;
	color: white;
	font-size: 10px;
}
.roundUser.amountUserAdd{
	bottom: 24px;
}
.roundUser.roundUserAdded{
	right: 2px;
}
.rightSideInfoStartPage{
	position: absolute;
	right: 10px;
	bottom: 10px;
	color: white;
	font-weight: 600;
	cursor: pointer;

}
.petalStartPageBlock{
	display: flex;
	margin-top: 20px;
	margin-bottom: 40px;
	justify-content: space-evenly;
	align-items: center;
}
.petalStartPageBlock button{
	width: 100%;
}
.specWidth{
	width: 280px;
}
.leftsideInfoStartPage{
	color: white;
	position: absolute;
	bottom: 10px;
	left: 10px;
	width: 83%;
}
.leftsideInfoStartPageHeader{
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	padding-top: 2px;
	padding-bottom: 2px;
}
.leftsideInfoStartPageSubText{
	color: #959595;
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
	line-height: 14px; 
	display: flex;
	align-items: center;
}
/* .leftsideInfoStartPageHeader:hover{
	color: var(--apColorAccent);
	cursor: pointer;
} */
.headerRoute{
	display: flex;
	gap: 10px;
	align-items: flex-start;
	justify-content: space-between;
}
.galleryList{
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	margin-top: 40px;
}
.imageItemGallery{
	display: flex;
	min-width: 282px;
	min-height: 175px;
	max-width: 282px;
	max-height: 175px;
	border-radius: 5px;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.startPageItem{
	display: flex;
	min-width: 362px;
	min-height: 208px;
	max-width: 362px;
	max-height: 208px;
	border-radius: 5px;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
}
.imageItemGallery.imageItemGalleryUpload{
	background: var(--apColorGray1);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
	border: 1px solid var(--apColorDarkGray2);
}
.imageItemGallery.imageItemGalleryUpload:hover{
	cursor: pointer;
	background:  rgba(222, 223, 225,0.4);
	color:  var(--apColorAccent);
}
.imageItemGallery.imageItemGalleryUpload:hover svg{
	fill: var(--apColorAccent);
}
.imageItemGallery svg{
	width: 24px;
	height: 24px;
}

.apParkImagesDropArea:hover:not(.apDisabled) {
	background-color: var(--apColorLight);
	border: 2px dashed var(--apColorAccent);
}
.apParkImagesDropArea:hover:not(.apDisabled) svg {
	fill: var(--apColorAccent);
}
.apParkImagesHintArea span {
	width: calc(100% - 16px);
}
.apParkImagesHintArea svg {
	width: 24px;
	height: 24px;
}
.approveButtonIcon.primaryButtonIconTextDefault{
	padding: 0 0 0 7px;
    height: 40px;
    margin-top: 6px;
}
.modalwithButtons{
	position: absolute;
	display: flex;
	/* bottom: -10px; */
	background: white;
	z-index: 10;
	padding: 10px;
	left: -58px;
	border-radius: 5px;
}
.modalwithButtonsMap{
	position: absolute;
	display: flex;
	/* bottom: -10px; */
	background: white;
	z-index: 10;
	padding: 10px;
	right: 0px;
	gap: 20px;
	border-radius: 5px;
}
.apParkImagesHintArea {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	color: var(--apColorAttention);
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	align-items: center;
	padding: 11px 16px 11px 16px;
	width: 285px;
	min-height: 48px;
	flex-grow: 10;
	background: var(--apColorLight);
	border: 1px solid var(--apColorAttention);
	border-radius: 8px;
	fill: #F2994A;
	margin-bottom: 16px;
	margin-right: 8px;
	margin-left: 8px;
	
}
.apPublished {
	background-color: var(--apColorAccent);
}
.apPublished, .apDeactivated, .apCandidate, .apOnRepair, .apDeleted {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-left: 8px;
	padding-right: 8px;
	font-weight: 400;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	height: 17px;
	border-radius: 8px;
	color: var(--apColorLight);
}
.apSelectorTableHeader {
	background-color: var(--apColorLight);
	font-weight: 600;
	font-size: 18px;
	line-height: 120%;
	letter-spacing: 0.006em;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	min-height: 64px;
	padding: 16px 64px 16px 16px;
}
.sportEventsCardTimeTime span{
	font-size: 10px;
	vertical-align: top;
}
.apSelectorTableRow {
	background-color: var(--apColorLightGray);
	font-weight: 600;
	font-size: 18px;
	line-height: 120%;
	letter-spacing: 0.006em;
	margin-top: 1px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 16px 64px 16px 16px;
	cursor: pointer;
}
.apSelectorTableRow:hover {
	background-color: var(--apColorGray1);
}
.apSelectorTableRowImage svg {
	width: 24px;
	height: 24px;
	fill: var(--apColorAccent);
}
.apSelectorTableRowImage {
	background-color: var(--apColorGray1);
	width: 48px;
	height: 48px;
	min-width: 48px;
	min-height: 48px;
	border-radius: 24px;
	border: 1px solid var(--apColorDarkGray2);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 16px;
	position: relative;
}
.apSelectorTableRowText span {
	font-weight: 500;
	font-size: 14px;
	line-height: 120%;
}
.apSelectorTableRowText {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.apSettingDropDown {
	position: relative;
}
.apSettingsList div {
	color: var(--apColorDark);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 6px 8px 6px 8px;
	min-height: 36px;
	line-height: 100%;
	border-radius: 4px;
	cursor: pointer;
}
.apSettingsList div.apActive {
	color: var(--apColorAccent);
}
.apSettingsList div:hover {
	background-color: var(--apColorMediumGray);
}
.apSettingsList {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	background-color: var(--apColorLight);
	border-radius: 4px;
	width: max-content;
	position: absolute;
	top: 56px;
	right: 0;
	z-index: 2;
	padding: 8px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.petalsPageTopMenuBlock .apSettigsList {
	right: 12px;
}
.apSpinner div:not(.cancelUploadButton) {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 32px;
	height: 32px;
	margin: 8px;
	border: 2px solid var(--apColorAccent);
	border-radius: 50%;
	background: none;
	animation: apSpinnerAnimation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: var(--apColorAccent) transparent transparent transparent;
	z-index: 5;
}
.apSpinner div:nth-child(2) {
	animation-delay: -0.45s;
}
.apSpinner div:nth-child(3) {
	animation-delay: -0.3s;
}
.apSpinner div:nth-child(4) {
	animation-delay: -0.15s;
}
.apSpinner div:nth-child(5) {
	background-color: var(--apColorMediumGray);
	animation: none;
	border: 4px solid var(--apColorMediumGray);
	width: 34px;
	height: 34px;
	z-index: 4;
}
.apSpinner {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 60px;
	height: 60px;
}
.apSwitch button {
	border-radius: 8px 0px 0px 8px;
}
.apSwitch button+button {
	border-radius: 0px 8px 8px 0px;
	margin-left: 0px;
}
.apSwitch {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.swiper-slide{
	/* flex-shrink: 1!important; */
	/* margin-right: 30px!important; */
}
.swiper-slide.swiper-slide-active{
	/* margin-right: 30px!important;
	margin-left: 30px!important; */
}
.apTextarea div span {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	padding: 0 16px 0 16px;
	position: relative;
	top: -10px;
}
.apTextarea div span+span {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	padding: 0 16px 0 16px;
	position: relative;
	top: -10px;
	text-align: end;
}
.apTextarea div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	position: relative;
	top: 13px;
}
.apTextarea label {
	font-weight: 600;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	background-color: var(--apColorLightGray);
	background-position: center;
	padding: 0 6px 0 6px;
	position: relative;
	left: 8px;
}
.apTextarea textarea {
	height: 95px;
	width: 100%;
	resize: none;
	background-color: var(--apColorLightGray);
	border: 1px solid var(--apColorDark);
	color: var(--apColorDark);
	border-radius: 8px;
	padding: 8px 14px 8px 14px;
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	margin-top: -7px;
}
.apTextarea {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 196px;
	width: 100%;
	margin-bottom: 16px;
	margin-top: 20px;
}
.apTooltip {
	position: relative;
   /* display: inline-block;
	*/
	border-bottom: 1px dotted var(--apColorGray1);
}
.apTooltip .apTooltipText {
	visibility: hidden;
	position: absolute;
	max-width: 300px;
	width: max-content;
	background-color: var(--apColorDark);
	color: var(--apColorLight);
	text-align: center;
	padding: 8px 16px 8px 16px;
	border-radius: 8px;
	z-index: 10;
	opacity: 0;
	transition: opacity .1s;
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	filter: drop-shadow(0px 16px 48px rgba(0, 0, 0, 0.2));
}
.apTooltip:hover .apTooltipText {
	visibility: visible;
	opacity: 1;
}
.apTooltipBottom {
	top: 135%;
	left: 50%;
	margin-left: -60px;
}
.apTooltipBottom::after {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent var(--apColorDark) transparent;
}
.apTooltipLeft {
	top: -5px;
	bottom: auto;
	right: 105%;
}
.apTooltipLeft::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent transparent var(--apColorDark);
}
.apTooltipRight {
	top: -5px;
	left: 105%;
}
.apTooltipRight::after {
	content: "";
	position: absolute;
	top: 50%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent var(--apColorDark) transparent transparent;
}
.apTooltipTop {
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
}
.apTooltipTop::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: var(--apColorDark) transparent transparent transparent;
}
.apgmtt1 {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	height: 100%;
	width: 100%;
}
.apgmtt2 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 120px;
	width: 100%;
	background-size: cover;
	background-position: center;
}
.apgmtt3 {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	color: var(--apColorLight);
	width: 100%;
	padding: 8px 16px 8px 16px;
}
.apgmtt4 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
   /* padding: 0 16px 16px 16px;
	*/
}
.authContainer {
	margin: 0 auto;
	display: flex;
	width: 790px;
	padding-top: 72px;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}
.authForm .primaryButtonTextDefault, .authForm .primaryButtonTextDisabled {
	margin-bottom: 24px;
	text-align: center;
	display: inline-block;
}
.authForm span {
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	margin-bottom: 16px;
	margin-right: 8px;
}
.authForm {
	width: 300px;
	display: flex;
	flex-direction: column;
}
.authForm h4 {
	margin-top: 0;
	margin-bottom: 0;
}
.cancelUploadButton svg {
	width: 24px;
	height: 24px;
	cursor: pointer;
	fill: var(--apColorDarkGray1);
}
.cancelUploadButton {
	display: flex;
	position: absolute;
	width: 32px;
	height: 32px;
	margin: 8px;
	justify-content: center;
	align-items: center;
	z-index: 6;
}
.circle-background {
	stroke: #ddd;
}
.circle-background, .circle-progress {
	fill: none;
}
.circle-progress {
	stroke: var(--apColorAccent);
	stroke-linecap: round;
	stroke-linejoin: round;
}
.circle-text {
	font-size: 3em;
	font-weight: bold;
	fill: var(--apColorAccent);
}
.codeBlock {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 10px;
}
.codeBlock.cus {
	flex-wrap: nowrap;
	gap: 5px;
}
.currentStatusDivider {
	width: 1px;
	height: 24px;
	margin-right: 8px;
	margin-left: 8px;
	border-right: 1px solid var(--apColorGray1);
}
.groundPropertiesImageSection{
	width: 100%;
	
}
.currentStatusHeader h4 {
	margin: 0 8px 0 8px;
	display: inline;
}
.currentStatusHeader svg {
	width: 24px;
	height: 24px;
	margin-left: 8px;
	fill: var(--apColorDark);
}
.currentStatusHeader {
	margin-top: -24px;
	margin-bottom: 40px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}
.dict2ndLevelRowsGroup .dictRowNormal:nth-child(n+2) {
	display: none;
}
.dict2ndLevelRowsGroupOpen .dictRowNormal:nth-child(1) {
	background-color: var(--apColorAccent);
	color: var(--apColorLight);
	cursor: pointer;
}
.dict2ndLevelRowsGroupOpen {
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.dict2ndLevelRowsGroup .dictRowNormal:nth-child(1):hover {
	background-color: var(--apColorLight);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 0px rgba(0, 0, 0, 0.08);
	cursor: pointer;
}
.dictAllRowsContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: flex-start;
	align-items: stretch;
	background-color: var(--apColorGray1);
	padding-top: 1px;
}
.dictAllRowsContainer.routeLibrary .catVideoValue{
	min-width: auto;
}
.dictAllRowsContainer.routeLibrary .catVideoValue:nth-child(7){
	display: flex;
	flex-direction: column;
	justify-content:center;
}
.dictRowButtonsContainer button {
	margin-left: 16px;
}
.dictRowButtonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	flex-wrap: wrap;
}
.dictRowEmpty {
	display: flex;
	width: 100%;
	min-height: 56px;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background-color: var(--apColorLightGray);
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
	color: var(--apColorDarkGray1);
	padding: 4px 16px 4px 16px;
	margin-bottom: 1px;
}
.dictRowLeftSpace {
	margin: 0;
	padding: 0;
	font-size: 1px;
	width: 80px;
}
.apFilterContainer.apFilterContainerHeight{
	height: fit-content;
}
.dictRowNormal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 56px;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
	color: var(--apColorDark);
	padding: 4px 16px 4px 0;
	margin-bottom: 1px;
	cursor: pointer;
}
.dictRowNormal:nth-child(even) {
	background: var(--apColorMediumGray);
}
.dictRowNormal:nth-child(odd) {
	background: var(--apColorMediumGray);
}
.dictRowNormal:hover {
	background-color: var(--apColorLight);
}
.dictRowValue .apInputText input, .dictRowValueSplit .apInputText input {
	margin-top: 2px;
	margin-bottom: 2px;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
	background-color: var(--apColorLight);
}
.dictRowValue .apInputText, .dictRowValueSplit .apInputText {
	margin-bottom: 0;
	margin-right: 16px;
	width: 100%;
}
.dictRowValue {
	min-height: 40px;
	margin-left: 16px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
	border-right: 1px solid rgba(0, 0, 0, 0.08);
	background: var(--apColorDarkGray);
	width: 240px;
}
.dictRowValueSplit span {
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
}
.dictRowValueSplit {
	min-height: 40px;
	margin-left: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex-grow: 1;
	border-right: 1px solid rgba(0, 0, 0, 0.08);
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	width: 100px;
}
.dictRowValuesContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	flex-wrap: wrap;
}
.dictionaryHeaderWithButton .groundPropertiesHeader {
	border-bottom: none;
}
.dictionaryHeaderWithButton button {
	margin-top: 0px;
}
.dictionaryHeaderWithButton h2 {
	margin: 0 0 0 0px;
	position: relative;
}
.dictionaryHeaderWithButton h2::before {
	position: absolute;
	content: url('./assets/images/icon24DropDown.svg');
	right: -24px;
	top: -2px;
	transform: rotate(-90deg);
}
.dictionaryHeaderWithButton h2.active::before {
	position: absolute;
	content: url('./assets/images/icon24DropDown.svg');
	right: -24px;
	top: -6px;
	transform: rotate(180deg);
}
.dictionaryHeaderWithButton {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 20px 8px;
	cursor: pointer;
	max-height: 65px;
	border-top: 1px solid var(--apColorDarkGray2);
}
.eventCard {
	padding-bottom: 20px;
	cursor: pointer;
}
.eventCard:hover {
	font-weight: bold;
}
.mainCommonBlock .dictionaryHeaderWithButton:hover {
	cursor: pointer;
	background-color: var(--apColorMediumGray);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.filterBlock button {
	margin-right: 16px;
}
.filterBlock span {
	font-weight: 600;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
}
.filterBlock span {
	margin-right: 12px;
}
.filterBlock {
	margin-top: 2px;
}
.filterBlock {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}
.gm-control-active.gm-fullscreen-control {
	display: flex !important;
	flex-direction: row !important;
	justify-content: flex-start !important;
	align-items: center !important;
	height: 48px !important;
	width: 48px !important;
	border-radius: 50% !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 160% !important;
   /* letter-spacing: 0.03em!important;
	*/
	padding: 8px !important;
	background-color: var(--apColorDark) !important;
	color: var(--apColorLight) !important;
	background-image: URL('./assets/images/icon24MaximizeLight.svg') !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	background-size: 24px !important;
}
.gm-control-active.gm-fullscreen-control img, .gm-control-active.gm-fullscreen-control:hover img {
	display: none;
}
.gm-control-active.gm-fullscreen-control:hover {
	background-color: var(--apColorAccent) !important;
}
.gm-style .gm-style-iw-c {
	color: var(--apColorLight) !important;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	width: 280px;
	min-height: 73px !important;
   /* height: 193px;
	*/
	padding: 0;
}
.gm-style .gm-style-iw-c, .gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece, .gm-style .gm-style-iw-t::after {
	background: none !important;
	background-color: var(--apColorDark) !important;
}
.gm-style-iw-d {
	overflow: hidden !important;
}
.gm-style-mtc button {
	display: flex !important;
	flex-direction: row !important;
	justify-content: flex-start !important;
	align-items: center !important;
	height: 40px !important;
	border-radius: 8px !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 160% !important;
   /* letter-spacing: 0.03em!important;
	*/
	padding: 16px !important;
	background-color: var(--apColorDark) !important;
	color: var(--apColorLight) !important;
	margin-right: 4px !important;
}
.gm-style-mtc button:hover {
	background-color: var(--apColorAccent) !important;
}
.groundPropertiesMainBlock h3 {
	margin-top: 0;
}
.groundPropertiesMainBlock {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-top: 32px;
}
.groundPropertiesMainBlock+.groundPropertiesHeader h2 {
	margin-top: 48px;
}
.groundPropertiesAddressAndTrains {
	order: 2;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 450px;
	flex-grow: 1;
}
.groundPropertiesAddressBlock h3 {
	margin-bottom: 25px;
}
.groundPropertiesAddressBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 280px;
	min-width: 280px;
	flex-grow: 1;
	padding-left: 24px;
	padding-right: 24px;
	margin-bottom: 32px;
	order: 1;
	border-right: 1px solid var(--apColorGray1);
}
.groundPropertiesOwnerBlock h3 {
	margin-bottom: 25px;
}
.groundPropertiesOwnerBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 24px;
	padding-right: 24px;
	margin-bottom: 32px;
	width: 280px;
	min-width: 280px;
	flex-grow: 1;
	order: 2;
}
.groundPropertiesPhotosBlock h3 {
	width: 100%;
	margin-left: 8px;
}
.groundPropertiesPhotosBlock svg {
	width: 24px;
	height: 24px;
}
.groundPropertiesPhotosBlock {
	display: flex;
	flex-direction: row;
	border-left: 1px solid var(--apColorGray1);
	padding-left: 16px;
	padding-right: 16px;
	margin-bottom: 32px;
	width: 464px;
	flex-grow: 1;
	order: 1;
	flex-wrap: wrap;
}

.groundPropertiesPhotosBlock.groundPropertiesPhotosBlockTrack{
	width: 100%;
	flex-wrap: nowrap;
	border: none;
	padding: 0;
}
.groundPropertiesExtraBlock h3 {
	margin-top: 0;
	margin-bottom: 25px;
}
.groundPropertiesExtraBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-top: 32px;
}
.parkItemCardList {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin: 0 -12px;
}
.searchMapTab {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}
.buttonControl {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.buttonControl button {
	padding: 0 15px;
}
.parkItemCardMap {
	display: flex;
	flex-direction: column;
	width: 280px;
	border-radius: 10px;
	background-color: var(--apColorLight);
	padding: 10px;
	margin-bottom: 24px;
}
.parkItemCardMapImg {
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 10px;
	height: 147px;
	width: 262px;
	background-repeat: round;
}
.groundPropertiesHeader h2 {
	display: inline-block;
	margin-right: 13px;
}
.groundPropertiesHeader {
	border-bottom: 1px solid var(--apColorGray1);
}
.groundPropertiesNameBlock .apTextarea textarea {
	height: 151px;
}
.groundPropertiesNameBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	border-right: 1px solid var(--apColorGray1);
	width: 450px;
	flex-grow: 1;
	padding-right: 24px;
	padding-left: 24px;
	margin-bottom: 32px;
}
.groundPropertiesOtherParams2Columns {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}
.groundPropertiesOtherParamsCommonBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
   /* padding-left: 32px;
	*/
	width: 450px;
	flex-grow: 1;
   /* flex-wrap: wrap;
	*/
}
.groundPropertiesOtherParamsLeftBlock .apInputTextIcon, .groundPropertiesOtherParamsLeftBlock .apDropDownList, .groundPropertiesOtherParamsRightBlock .apInputTextIcon, .groundPropertiesNameBlock .apInputText, .groundPropertiesAddressBlock .apInputText, .groundPropertiesOtherParamsRightBlock .apInputText, .groundPropertiesOtherParamsRightBlock .apDropDownList, .groundPropertiesOwnerBlock .apDropDownList, .groundPropertiesOwnerBlock .apInputText, .groundPropertiesAddressBlock .apDropDownList {
	width: 100%;
}
.groundPropertiesOtherParamsLeftBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 280px;
	flex-grow: 1;
	padding-right: 24px;
	padding-left: 24px;
	margin-bottom: 32px;
}
.groundPropertiesOtherParamsRightBlock .apTextarea textarea {
	height: 105px;
}
.editorWidth{
width: 100%;
}
.infoPage .infoSide{
	display: none;
}
.groundPropertiesOtherParamsRightBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-grow: 1;
	width: 280px;
	padding-left: 24px;
	padding-right: 24px;
	border-left: 1px solid var(--apColorGray1);
	margin-bottom: 32px;
}
.groundPropertiesTrainsSetBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	border-left: 1px solid var(--apColorGray1);
	padding-left: 32px;
	padding-right: 32px;
	width: 345px;
	order: 2;
}
.groundPropertiesTrainsSetBlock span {
	margin-right: 16px;
	margin-bottom: 4px;
}
.headerCommonBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	width: 1200px;
	margin-left: 16px;
	margin-right: 16px;
	position: relative;
	z-index: 10000;
}
.headerCommonBlock.mobile {
	padding-right: 46px;
}
.headerCommonBlock.mobile {
	z-index: 1041;
}
.headerCommonBlock.mobile .apBurgerButton {
	position: absolute;
	right: 0;
	height: 40px;
	top: 50%;
	margin-top: -20px;
	z-index: 1040;
}
.apBurgerButton path {
	transform-origin: center;
	transition: .2s;
}
.apBurgerButton {
	transition: .2s;
}
.apBurgerButton.isOpen {
	background-color: transparent;
}
.apBurgerButton.isOpen path:last-child {
	fill: transparent;
}
.apBurgerButton.isOpen path:first-child {
	rotate: 45deg;
}
.apBurgerButton.isOpen path:nth-child(2) {
	rotate: -45deg;
	translate: 75px 75px;
}
.headerCommonBlock.isOpen .headerMenuBlock {
	position: absolute;
	right: 0;
	top: 0;
	padding: 32px 0 16px 0;
	width: 300px;
	display: flex;
	flex-direction: column;
	background-color: #fff;
}
.headerCommonBlock.isOpen .headerMenuBlock>* {
	box-sizing: border-box;
	text-align: center;
	background-color: transparent;
	margin: 0 0 16px 0;
	padding: 0;
}
.headerCommonBlock.isOpen .headerMenuBlock>*:hover {
	color: var(--apColorAccent);
}
.headerLine {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: var(--apColorMediumGray);
	min-height: 80px;
}
.headerLogoBlock img {
	cursor: pointer;
}
.headerLogoBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 8px;
	margin-bottom: 8px;
	width: 150px;
}
.headerMenuBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-right: 25px;
}
.headerMenuBlock button {
	margin-left: 16px;
}
.headerMenuBlock .links {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
}
.headerMenuBlock .links.isMobile {
	position: absolute;
	right: -32px;
	top: 0;
	background-color: var(--apColorLight);
	z-index: 998;
	flex-direction: column;
	justify-content: flex-start;
	padding: 24px 64px 32px 64px;
	max-height: 1000px;
	box-sizing: border-box;
	overflow: hidden;
	flex-wrap: nowrap;
	transition: .3s;
}
.headerMenuBlock .links.isMobile.isClose {
	max-height: 0;
	pointer-events: none;
	padding: 0 64px;
	opacity: 0;
}
.headerMenuBlock .links.isMobile.isClose button {
	opacity: 0;
}
.headerMenuBlock .links.isMobile button {
	margin: 0 0 16px 0 !important;
}
.headerMenuBlock button {
	padding: 0;
	background-color: transparent;
	color: var(--apColorDark);
	transition: .2s 
}
/* .headerMenuBlock .links button:first-child {
	margin: 0;
}
*/
.headerMenuBlock .links button:hover, .headerMenuBlock .links button.active {
	color: var(--apColorAccent) 
}
.headerMenuBlock .additionalButton {
	display: flex;
   /* justify-content: flex-end;
	*/
}
.headerMenuBlock .additionalButton button svg path {
	transition: .2s;
}
.headerMenuBlock .additionalButton button svg {
	fill: var(--apColorDark);
}
.headerMenuBlock .additionalButton button.hamburger {
	position: relative;
	z-index: 999;
	flex: 0 0 24px;
	max-width: 24px;
	height: 24px;
}
.headerMenuBlock .additionalButton button.notification svg path, .headerMenuBlock .additionalButton button:hover.notification svg path {
	stroke: transparent;
}
.headerMenuBlock .additionalButton button:hover.notification {
	opacity: .6;
}
.headerMenuBlock .additionalButton button.hamburger span {
	position: absolute;
	left: 2px;
	right: 2px;
	height: 2px;
	border-radius: 20px;
	background-color: var(--apColorDark);
	transition: .2s;
}
.headerMenuBlock .additionalButton button.hamburger:hover span {
	background-color: var(--apColorAccent);
}
.headerMenuBlock .additionalButton button.hamburger span:first-child {
	top: 5px;
}
.headerMenuBlock .additionalButton button.hamburger span:nth-child(2), .headerMenuBlock .additionalButton button.hamburger span:nth-child(3) {
	top: 11px;
}
.headerMenuBlock .additionalButton button.hamburger span:last-child {
	top: 17px;
}
.headerMenuBlock .additionalButton button.hamburger.isOpen span:first-child {
	left: 100%;
	right: 30%;
	opacity: 0;
}
.headerMenuBlock .additionalButton button.hamburger.isOpen span:last-child {
	left: 30%;
	right: 100%;
	opacity: 0;
}
.headerMenuBlock .additionalButton button.hamburger.isOpen span:nth-child(2) {
	transform: rotate(45deg);
}
.headerMenuBlock .additionalButton button.hamburger.isOpen span:nth-child(3) {
	transform: rotate(-45deg);
}
.headerMenuBlock .additionalButton button:hover svg {
	fill: var(--apColorAccent);
}
.headerWithButtons div {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.headerWithButtons div+div button {
	margin-left: 8px;
}
.headerWithButtons div+div {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.headerWithButtons {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 2px;
}
.headerWithButtonsButtonBlock button {
	margin-left: 16px;
}
.headerWithButtonsButtonBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding-top: 40px;
}
.headerWithButtonsCommonBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}
.headerWithButtonsHeaderBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}
.headerWithButtonsHeaderBlock.dateOfPublish{
	justify-content: space-between;
	flex-wrap: wrap;
}
.headerWithButtonsHintBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-top: 78px;
	padding-right: 58px;
   /* width: 380px;
	*/
}
.lastVisitBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.mainCommonBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	width: 1200px;
	height: 100%;
	margin-right: 16px;
	margin-left: 16px;
}
.formBackground {
	width: 100vw;
	height: 100vh;
	z-index: 90000;
	background-color: rgba(0, 0, 0, 0.3);
	position: fixed;
	left: 0;
	top: 0;
	overflow-y: hidden;
	overflow-x: hidden;
}
.weekList {
	display: flex;
}
.weekListItem {
	background-color: var(--apColorLightGray);
	color: #B7B7B7;
	width: 68px;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	font-weight: 700;
	font-size: 16px;
	border-radius: 10px;
	margin-right: 2px;
}
.weekListItem:hover {
	cursor: pointer;
	background-color: var(--apColorBrandBG6);
	color: var(--apColorLight);
}
.weekListItem.active {
	cursor: pointer;
	background-color: var(--apColorAccent);
	color: var(--apColorLight);
}
.formBlock {
	width: 500px;
	padding: 20px;
	z-index: 95000;
	margin: 5% auto;
	background-color: var(--apColorLight);
	position: relative;
	border-radius: 10px;
}
#editor{
	border: 1px solid var(--apColorBrandBG6);
}

.planBlock {
	margin-left: 24px;
}
.mainLine {
	display: flex;
	flex:1 1 auto;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	min-height: calc(100% - 81px);
	position: relative;
	/* padding-bottom: 80px; */
}
.tro {
	color: white !important;
	z-index: 100;
}
.tro::before {
	content: '';
	position: absolute;
	width: 100vw;
	height: -webkit-fill-available;
	left: -0;
	z-index: -10000;
	background-image: URL('./assets/images/troBack.png');
	background-repeat: no-repeat;
	background-size: cover;
}
.tro .petalsPageTopMenuBlock span {
	color: white;
}
.tro .petals svg text {
	fill: inherit;
}
.mainNavButtonIconActive svg, .primaryLargeButtonIconTextDefault svg {
	fill: var(--apColorAccent);
}
.primaryLargeButtonIconTextDefault.cus svg{
	fill: none;
} 
.primaryLargeButtonIconTextDefault.cus svg path{
	stroke: #39D0D3;
}
.mainNavButtonIconDefault.apLink button, .mainNavButtonIconActive.apLink button {
	margin-left: 0px;
}
.mainNavButtonTextActive, .mainNavButtonIconActive, .mainNavButtonTextActive.apLink {
	color: var(--apColorAccent);
}
.mainNavButtonTextDefault, .mainNavButtonTextActive, .mainNavButtonIconDefault, .mainNavButtonIconActive, .tabButtonIconTextDefault, .tabButtonIconTextActive {
	margin-left: 8px;
	text-align: right;
}
.mainNavButtonTextDefault, .mainNavButtonTextActive, .primaryButtonTextDisabled, .primaryButtonTextDefault, .secondaryButtonTextDefault {
	padding: 8px 16px 8px 16px;
}
.mainNavButtonTextDefault:hover, .mainNavButtonIconDefault:hover {
	background-color: var(--apColorGray1);
}
.primaryButtonTextDisabled {
	background: var(--apColorGray1);
}
.mainNavButtonTextActive .menuFunctionLine {
	padding-top: 3px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	min-height: 88px;
}
.menuTabsLine {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	min-height: 56px;
	margin-top: 24px;
	background: var(--apColorLight);
	flex-wrap: wrap;
}
.menuTabsLine button {
	margin-top: 8px;
	margin-bottom: 8px;
}
.messagesEmpty svg {
	width: 24px;
	height: 24px;
	fill: var(--apColorDarkGray1);
	margin-right: 8px;
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
}
.messagesEmpty {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: var(--apColorMediumGray);
	border-radius: 8px;
	color: var(--apColorDarkGray1);
	text-align: center;
	min-height: 48px;
	margin-bottom: 174px;
}
.messagesHeaderSearch {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-end;
	margin-bottom: 24px;
}
.messagesHeaderWithSearch h2 {
	margin-bottom: 24px;
}
.messagesHeaderWithSearch {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	flex-wrap: wrap;
	border-bottom: 1px solid var(--apColorMediumGray);
	margin-top: 8px;
	margin-bottom: 16px;
}
.messagesNewMessage div {
	padding: 4px 16px;
}
.messagesNewMessage div:first-child {
	padding-right: 0;
}
.messagesNewMessage div:nth-child(2) .apInputText input {
	margin-top: 0;
}
.messagesNewMessage div:nth-child(2) .apInputText {
	margin: 0;
	padding: 0;
}
.apInputText{
	position: relative;
}
.messagesNewMessage div:nth-child(2) {
	flex-grow: 1;
	border-right: 1px solid var(--apColorMediumGray);
   /* padding-left: 0;
	*/
}
.messagesNewMessage {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-top: 1px solid var(--apColorMediumGray);
	background-color: var(--apColorLight);
	min-height: 56px;
	margin-top: 8px;
	flex-wrap: wrap;
}
.overlayCenterBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	min-width: 300px;
	border-radius: 4px;
	background-color: var(--apColorLight);
	overflow: hidden;
	position: relative;
}
.overlayCenterBlock>svg {
	position: absolute;
	right: 0px;
	top: 0px;
	margin-right: 8px;
	margin-top: 8px;
	fill: var(--apColorDarkGray2);
	width: 24px;
	height: 24px;
	cursor: pointer;
}
.overlayCenterBlock>svg:hover {
	fill: var(--apColorDark);
}
.petal6 text, .petal5 text {
	font-size: 10px;
}
.petal6 div, .petal5 div {
	font-size: 8px;
}
.petal1 text, .petal4 text {
	font-size: 41px;
}
.petal1 div, .petal4 div {
	font-size: 33px;
}
.petal2 text, .petal3 text {
	font-size: 21px;
}
.petal2 div, .petal3 div {
	font-size: 16px;
}
.petal6, .petal2, .petal5, .petal3 {
	width: 169px;
}
.petal1, .petal4 {
	width: 155px;
}
.petal1, .petal2, .petal3, .petal4, .petal5, .petal6 {
	position: absolute;
	opacity: 0.1;
}
.petal1:not(.apDisabled):not(.apAbsent), .petal2:not(.apDisabled):not(.apAbsent), .petal3:not(.apDisabled):not(.apAbsent), .petal4:not(.apDisabled):not(.apAbsent), .petal5:not(.apDisabled):not(.apAbsent), .petal6:not(.apDisabled):not(.apAbsent) {
	-moz-transition-duration: var(--apTransitionDurationPetal);
	-webkit-transition-duration: var(--apTransitionDurationPetal);
	-o-transition-duration: var(--apTransitionDurationPetal);
	transition-duration: var(--apTransitionDurationPetal);
}
.petal1 {
	-webkit-clip-path: polygon(0px 10px, 20px 0px, 130px 0px, 160px 10px, 160px 50px, 95px 165px, 60px 165px, 0px 55px);
	clip-path: polygon(0px 10px, 20px 0px, 130px 0px, 160px 10px, 160px 50px, 95px 165px, 60px 165px, 0px 55px);
	left: 123px;
	top: 4px;
	fill: var(--apColorBrandBG3);
}
.petal1.anim {
	animation-name: everyone;
	animation-duration: 600ms;
	animation-delay: 525ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}
.petal1:active:not(.apDisabled) {
	transform: translate(0, -3px);
}
.petal1:hover:not(.apDisabled), .petal1.apSelected {
	transform: translate(0, -5px);
}
.petal2 {
	-webkit-clip-path: polygon(90px 0px, 120px 30px, 150px 70px, 170px 120px, 170px 170px, 10px 170px, 0px 140px, 0px 120px, 70px 0px);
	clip-path: polygon(90px 0px, 120px 30px, 150px 70px, 170px 120px, 170px 170px, 10px 170px, 0px 140px, 0px 120px, 70px 0px);
	left: 230px;
	top: 34px;
	fill: var(--apColorBrandLogo3);
}
.petal2.anim {
	animation-name: everyone;
	animation-duration: 600ms;
	animation-delay: 150ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}
.petal2:active:not(.apDisabled) {
	transform: translate(3px, -1px);
}
.petal2:hover:not(.apDisabled), .petal2.apSelected {
	transform: translate(4px, -3px);
}
.petal3 {
	-webkit-clip-path: polygon(10px -10px, 170px -10px, 175px 40px, 155px 90px, 115px 140px, 85px 165px, 70px 165px, 58px 145px, -4px 40px, -4px 20px);
	clip-path: polygon(10px -10px, 170px -10px, 175px 40px, 155px 90px, 115px 140px, 85px 165px, 70px 165px, 58px 145px, -4px 40px, -4px 20px);
	left: 230px;
	top: 207px;
	fill: var(--apColorBrandBG4);
}
.petal3.anim {
	animation-name: everyone;
	animation-duration: 600ms;
	animation-delay: 225ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}
.petal3:active:not(.apDisabled) {
	transform: translate(3px, 1px);
}
.petal3:hover:not(.apDisabled), .petal3.apSelected {
	transform: translate(4px, 3px);
}
.petal4 {
	-webkit-clip-path: polygon(58px -10px, 97px -10px, 160px 100px, 160px 140px, 130px 155px, 100px 165px, 60px 165px, 20px 155px, -2px 140px, -2px 95px);
	clip-path: polygon(58px -10px, 97px -10px, 160px 100px, 160px 140px, 130px 155px, 100px 165px, 60px 165px, 20px 155px, -2px 140px, -2px 95px);
	left: 123px;
	top: 246px;
	fill: var(--apColorBrandBG1);
}
.petal4.anim {
	animation-name: everyone;
	animation-duration: 600ms;
	animation-delay: 300ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}
.petal4:active:not(.apDisabled) {
	transform: translate(0, 3px);
}
.petal4:hover:not(.apDisabled), .petal4.apSelected {
	transform: translate(0, 5px);
}
.petal5 {
	-webkit-clip-path: polygon(0px -10px, 160px -10px, 170px 20px, 173px 40px, 100px 165px, 90px 165px, 50px 135px, 20px 95px, 0px 50px);
	clip-path: polygon(0px -10px, 160px -10px, 170px 20px, 173px 40px, 100px 165px, 90px 165px, 50px 135px, 20px 95px, 0px 50px);
	left: 2px;
	top: 207px;
	fill: var(--apColorBrandBG6);
}
.petal5.anim {
	animation-name: everyone;
	animation-duration: 600ms;
	animation-delay: 375ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}
.petal5:active:not(.apDisabled) {
	transform: translate(-3px, 1px);
}
.petal5:hover:not(.apDisabled), .petal5.apSelected {
	transform: translate(-4px, 3px);
}
.petal6 {
	-webkit-clip-path: polygon(100px 0px, 170px 120px, 170px 140px, 160px 165px, 0px 165px, 0px 110px, 30px 50px, 70px 0px);
	clip-path: polygon(100px 0px, 170px 120px, 170px 140px, 160px 165px, 0px 165px, 0px 110px, 30px 50px, 70px 0px);
	left: 2px;
	top: 34px;
	fill: var(--apColorBrandBG5);
}
.petal6.anim {
	animation-name: everyone;
	animation-duration: 600ms;
	animation-delay: 450ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}
.petal6:active:not(.apDisabled) {
	transform: translate(-3px, -1px);
}
.petal6:hover:not(.apDisabled), .petal6.apSelected {
	transform: translate(-4px, -3px);
}
@keyframes everyone {
	from {
		opacity: 0.1;
   }
	to {
		opacity: 1;
   }
}
.petalCenterCircle {
	position: relative;
	background: none;
	left: 1px;
	bottom: 243px;
	width: 88px;
	height: 88px;
	margin-bottom: -88px;
	border-radius: 50%;
	z-index: 2;
	text-align: center;
	font-size: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 1px;
}
.petalTextPath {
	fill: none;
	stroke: none;
}
.petals svg text {
	fill: var(--apColorDark);
	line-height: 120%;
}
.petals svg.apDisabled text {
	fill: var(--apColorGray1);
}
.petals svg.apDisabled {
	fill: var(--apColorGray1);
}
/*.petals svg.apInactive {
	fill: var(--apColorOverlayLight);
}
*/
.petals svg.apAbsent {
	opacity: 0 !important;
	visibility: hidden;
	-moz-transition-duration: 0s;
	-webkit-transition-duration: 0s;
	-o-transition-duration: 0s;
	transition-duration: 0s;
}
/* .petals svg:active:not(.apDisabled):not(.apAbsent) {
	-moz-transition-duration: var(--apTransitionDurationPetal);
	-webkit-transition-duration: var(--apTransitionDurationPetal);
	-o-transition-duration: var(--apTransitionDurationPetal);
	transition-duration: var(--apTransitionDurationPetal);
}
*/
.petals svg:hover:not(.apDisabled):not(.apSelected) {
	cursor: pointer;
}
.petals svg:hover.apDisabled, .petals svg:hover.apSelected {
	cursor: unset;
}
.petals svg div {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	width: 100%;
	line-height: 120%;
	font-weight: 500;
	color: var(--apColorLight);
	overflow-wrap: anywhere;
   /* background-color: yellow;
	*/
}
.petals {
	position: relative;
	width: 400px;
	height: 400px;
	border-radius: 200px;
}
.apSelected {
	opacity: 0.3 !important;
}
.petalsPageBottomBlock {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	min-height: 160px;
	padding-top: 54px;
	flex-wrap: wrap;
}
.petalsPageCenterBlock {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 400px;
}
.petalsPageHeaderBlock h4 {
	margin-top: -12px;
}
.petalsPageHeaderBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.petalsPageTopMenuBlockFull {
	display: flex;
	flex-direction: column;
	justify-items: end;
	margin-top: 30px;
	align-content: space-between;
	height: 105px;
	margin-left: auto;
}

.petalsPageTopMenuBlock span {
	font-weight: 600;
	font-size: 14px;
	line-height: 160%;
	color: var(--apColorDark);
	cursor: pointer;
}
.petalsPageTopMenuBlock:hover span {
	color: var(--apColorAccent);
}
.petalsPageTopMenuBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	flex-grow: 1;
	height: 35px;
}
.petalsPageTopMenuBlock>svg {
	width: 24px;
	min-width: 24px;
	height: 24px;
	fill: var(--apColorAccent);
	margin-right: 8px;
}
.petalsPageUpperBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 48px;
}
.petalCenterMessageContainer {
	position: relative;
   /* background: yellow;
   */
	opacity: 0;
	left: 0px;
	bottom: 170px;
	width: 390px;
	height: 170px;
	margin-bottom: -170px;
	z-index: 2;
	text-align: center;
	flex-direction: row;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	animation-delay: 2s;
	transition-delay: 2s;
	animation-name: header;
	animation-duration: 600ms;
	animation-delay: 200ms;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
}
.petalCenterMessageContainer h2 {
	margin-top: 48px;
}
@keyframes header {
	from {
		opacity: 0;
   }
	to {
		opacity: 1;
   }
}
.pageContentHeaderDivider {
	border-bottom: 2px solid var(--apColorDark);
}
.primaryButtonIconTextDefault svg, .secondaryButtonIconTextDefault svg, .secondaryGhostButtonIconTextDefault svg, .tabButtonIconTextDefault svg, .tabButtonIconTextActive svg, .primaryButtonIconTextDisabled svg {
	margin-right: 8px;
}
.primaryLargeButtonIconTextDefault.cusTom.choosed{
	background-color:var(--apColorBrandLogo2);
}
.primaryButtonIconTextDefault, .primaryButtonTextDefault, .primaryLargeButtonIconTextDefault, .primarySmallButtonTextDefault, .primaryMediumButtonIconDefault {
	background-color: var(--apColorDark);
}
.primaryLargeButtonIconTextDefault.cusTom{
	background-color:var(--apColorAccent);
}
.primaryLargeButtonIconTextDefault.cusTom:hover{
	background-color: var(--apColorDark);
}
.primaryButtonTextDefault.auth{
	background-color: var(--apColorDarkGray2);
}
.primaryButtonTextDefault.auth.active{
	background-color: var(--apColorAccent);
}
.primaryButtonTextDefault.auth.active:hover{
	background-color: var(--apColorDark);
}
.primaryButtonIconTextDefault, .primaryButtonIconTextDisabled, .secondaryButtonIconTextDefault, .secondaryGhostButtonIconTextDefault, .tabButtonIconTextDefault, .tabButtonIconTextActive {
	padding: 8px 16px 8px 10px;
}
.primaryButtonIconTextDefault:hover:not(.active), .primaryButtonTextDefault:hover, .primaryLargeButtonIconTextDefault:hover, .primarySmallButtonTextDefault:hover, .primaryMediumButtonIconDefault:hover, .secondaryButtonIconActive, .tabButtonIconTextActive, .secondarySmallButtonTextActive {
	background-color: var(--apColorAccent);
}
.primaryButtonTextActent {
	background-color: var(--apColorAccent);
	color: var(--apColorLight);
	height: 100%;
}
.primaryButtonTextDefault:disabled{
	background-color: var(--apColorLight);
	color: var(--apColorDarkGray2);
}
.secondaryButtonIconTextDefault:disabled{
	background-color: var(--apColorLight);
	color: var(--apColorDarkGray2);
}
.secondaryButtonIconTextDefault:disabled svg{
	fill:var(--apColorDarkGray2);
}
.primaryButtonTextActent:hover {
	color: var(--apColorDark);
}
.active.secondaryGhostButtonIconTextDefault {
	color: var(--apColorAccent);
}
.primaryLargeButtonIconTextDefault div {
	height: 48px;
	min-width: 48px;
	border-radius: 50%;
	background-color: var(--apColorLight);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-bottom: 1px;
	margin-right: 12px;
}
.primaryLargeButtonIconTextDefault {
	height: 56px;
	border-radius: 28px;
	font-weight: bold;
	font-size: 16px;
	line-height: 120%;
	letter-spacing: 0.006em;
}
.primaryLargeButtonIconTextDefault {
	padding: 4px 20px 4px 4px;
	margin: 8px;
}
.primaryMediumButtonIconDefault {
	border-radius: 50%;
	width: 48px;
	min-width: 48px;
	height: 48px;
	justify-content: center;
}
.primarySmallButtonTextDefault, .secondarySmallButtonTextDefault, .secondarySmallButtonIconTextDefault, .secondarySmallButtonTextActive {
	min-height: 32px;
	border-radius: 4px;
}
.profileContacts {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 300px;
	width: 49%;
	flex-grow: 1;
	border-right: 1px solid var(--apColorGray1);
	padding-left: 32px;
	padding-right: 32px;
	margin-bottom: 32px;
}
.profileContactsAndWorkArea h3 {
	margin-top: 0;
}
.profileContactsAndWorkArea {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
	width: 42%;
	flex-grow: 1;
	flex-wrap: wrap;
}
.trackInfoRow, .profileDetailedInfoRow .apInputText, .profileDetailedInfoRow .apDropDownList, .profileContacts .apInputTextIcon {
	width: 100%;
}
.profileDetailedInfoRow h3 {
	margin-top: 0;
}
.profileDetailedInfoRow {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
    /* border-top: 1px solid var(--apColorGray1);
  	padding-top: 31px; */
	flex-wrap: wrap;
	margin: 20px -32px;
}
.trackInfoRow{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
    border-top: 1px solid var(--apColorGray1);
  	padding-top: 31px;
	flex-wrap: nowrap;
	margin: 20px -32px;
}
.profileMainInfoRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	margin-top: 62px;
	flex-wrap: wrap;
}
.profileNameBlock .secondarySmallButtonTextDefault {
	margin-top: 16px;
}
.profileNameBlock h2 {
	margin-top: 0;
	margin-bottom: 8px;
}
.profileNameBlock span {
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	letter-spacing: 0.03em;
   /* letter-spacing: initial;
	*/
	margin-right: 8px;
}
.profileNameBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 320px;
	width: 330px;
	flex-grow: 1;
	padding-left: 16px;
	padding-right: 32px;
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
}
.profileOwnRegionColumn {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 342px;
	flex-grow: 1;
	padding-left: 32px;
	padding-right: 31px;
	border-right: 1px solid var(--apColorGray1);
	min-height: 270px;
	max-width: 50%;
}
.profilePersonalDataColumn {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-grow: 1;
	padding-left: 32px;
	padding-right: 31px;
   /* border-right: 1px solid var(--apColorGray1);
	*/
	min-height: 390px;
	flex: 0 0 100%;
	max-width: 100%;
	flex-wrap: wrap;
}
.profilePersonalDataColumn .apInputText, .profilePersonalDataColumn .apDropDownList {
	flex: 0 0 31%;
	max-width: 31%;
}
.profilePersonalDataHeaderWithButton {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}
.profilePersonalDataHeaderWithButton button {
	margin-top: -8px;
	margin-left: 32px;
}
.profilePhotoAndName {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 42%;
	min-width: 400px;
   /* margin-left: 16px;
	*/
   /* margin-bottom: 32px;
	*/
	flex-grow: 1;
	border-right: 1px solid var(--apColorGray1);
	flex-wrap: wrap;
}
.profilePhotoImageAndChangeBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 178px;
   /* margin-bottom: 32px;
	*/
	flex-shrink: 0;
	margin-right: 14px;
}

.profilePhotoImageDiv {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 176px;
	height: 176px;
	background-color: var(--apColorMediumGray);
	border: 1px dashed rgba(68, 79, 96, 0.5);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
	margin-bottom: 3px;
	border-radius: 4px;
}
.apFCSectionItem .profilePhotoImageDiv{
	aspect-ratio: 16/10;
	height:auto;
	width: 100%;
}
.apFCSectionItem  .profilePhotoImageAndChangeBlock{
	width: auto;
}

.profileWorkArea {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 300px;
	width: 49%;
	flex-grow: 1;
	padding-left: 32px;
	padding-right: 32px;
	margin-bottom: 32px;
}
.profileWorkArea span {
	margin-right: 8px;
}
.profileWorkPositionColumn {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-grow: 1;
	padding-right: 31px;
	padding-left: 32px;
	border-right: 1px solid var(--apColorGray1);
	min-height: 270px;
	flex: 0 0 50%;
	max-width: 50%;
}

.trackColumn .apInputText, .trackColumn .apInputTextIcon, .trackColumn .apDropDownList{
	width: 100%;
}
.trackColumn h3{
	padding-top: unset;
	margin-top: unset;
}

.trackColumn .apTextarea{
	margin-top: unset;
	margin-bottom: unset;
}

.trackColumn textarea{
	height: 150px;
}

.trackColumn {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-grow: 1;
	padding-right: 31px;
	padding-left: 32px;
	border-right: 1px solid var(--apColorGray1);
	min-height: 270px;
	flex: 0 0 33%;
	max-width: 33%;
}
.trackRightSide {
	flex: 0 0 74%;
	max-width: 74%;

}
.trackColumnPhoto {
	flex: 0 0 25%;
	max-width: 25%;

}
.trackColumnLast{
	padding-right:unset;
	border-right:unset;
}

.rightSide {
	height: 100%;
   /* width: 45%;
	margin-left: 40px;
	*/
	text-align: left;
   /* padding-top: 72px;
	*/
}
.rightSide div {
	padding-left: 75px;
	font-weight: 500;
	font-size: 32px;
	line-height: 38px;
}
.rightSide div span {
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 22px;
}
.rightSide div.registrPage {
	padding-left: 0;
}
.secondaryButtonIconActive:hover, .mainNavButtonIconTextActive:hover, .mainNavButtonTextActive:hover, .tabButtonIconTextActive:hover, .active.secondaryGhostButtonIconTextDefault, .active.primaryButtonIconTextDefault {
	cursor: initial;
}
.secondaryButtonIconDefault svg, .secondaryButtonIconTextDefault svg, .secondaryGhostButtonIconTextDefault svg, .tetriaryButtonIconDefault svg, .secondarySmallButtonIconTextDefault svg, .mainNavButtonIconDefault svg, .mainNavButtonIconActive svg, .tabButtonIconTextDefault svg {
	fill: var(--apColorDark);
}
.active.secondaryGhostButtonIconTextDefault svg {
	fill: var(--apColorAccent);
}
.secondaryButtonIconDefault, .secondaryButtonIconActive, .tetriaryButtonIconDefault, .mainNavButtonIconDefault, .secondaryButtonTextDefault, .secondaryButtonTextActive {
	min-height: 40px;
}
.secondaryButtonIconTextDefault:hover, .secondaryButtonTextDefault:hover, .secondaryButtonIconDefault:hover, .secondaryGhostButtonIconTextDefault:hover:not(.active), .secondarySmallButtonTextDefault:hover, .secondarySmallButtonIconTextDefault:hover, .secondarySmallRoundedButtonTextDefault:hover, .tabButtonIconTextDefault {
	background-color: var(--apColorLight);
}
.secondaryButtonTextDefault, .secondaryButtonIconTextDefault, .secondaryGhostButtonIconTextDefault, .secondarySmallButtonTextDefault, .secondarySmallButtonIconTextDefault, .secondarySmallRoundedButtonTextDefault, .mainNavButtonTextDefault, .tabButtonIconTextDefault {
	color: var(--apColorDark);
}
.secondaryGhostButtonIconTextDefault {
	background-color: var(--apColorLightGray);
}
.secondarySmallButtonTextActive:hover {
	background-color: var(--apColorDarkGray2);
}
.secondarySmallRoundedButtonTextDefault {
	height: 32px;
	border-radius: 16px;
}
.secondarySmallRoundedButtonTextDefault.apQr:hover{
	background-color: var(--apColorAccent);
	color: var(--apColorLight);
}
.secondarySmallRoundedButtonTextDefault.apQr{
	position: absolute;
	right: 10px;
	top: 10px;
}
.secondarySmallRoundedButtonTextDefault {
	padding: 5px 12px 5px 12px;
}
.secondarySmallRoundedButtonTextDefault:hover {
	fill: var(--apColorError);
}
.filterClearButton {
	border-radius: 8px;
	width: 40px;
	min-width: 40px;
	height: 40px;
	transition: .2s;
	position: relative;
	padding: 0;
	font-size: 0;
	margin: 0;
}
.filterClearButton:before, .filterClearButton:after {
	position: absolute;
	content: '';
	width: 16px;
	height: 2px;
	background-color: var(--apColorDark);
	left: 50%;
	top: 50%;
	border-radius: 2px;
	transform: translate(-50%, -50%) rotate(45deg);
}
.filterClearButton:after {
	transform: translate(-50%, -50%) rotate(-45deg);
}
.apFilterWithSelected {
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 0 16px;
   /* margin-top: -8px;
	*/
}
.apFilterWithSelected>span {
	flex: 0 0 100%;
	max-width: 100%;
	position: relative;
}
.apFilterSelectedValues {
	display: flex;
	flex-wrap: wrap;
	margin-left: -12px;
}
.filterButtonWrap {
	display: flex;
}
.actionButtonsBlock .primaryButtonIconTextDefault.onUsers {
	margin-top: 0;
	margin-bottom: 0;
}
.serviceProviderSwiper {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 16px;
}
.sortAndFilterBlock .filterBlock {
	width: 560px;
	flex-grow: 1;
	flex-wrap: nowrap;
	align-items: center;
	margin-top: 0;
}
.sortAndFilterBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}
.sortAndFilterBlock .sortBlock .apDropDownList {
	margin-bottom: 8px;
}
.sortBlock .apDropDownList {
   /* margin-bottom: 0;
	*/
	margin-top: 2px;
}
.sortBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 200px;
	flex-grow: 1;
}
.spinner div {
	box-sizing: border-box !important;
	display: block !important;
	position: absolute !important;
	width: 48px !important;
	height: 48px !important;
	margin: 8px !important;
	border: 8px solid var(--apColorBrandLogo1) !important;
	border-radius: 50% !important;
	background: none !important;
	animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: var(--apColorBrandLogo1) transparent transparent transparent !important;
}
.spinner div:nth-child(1) {
	animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
	animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
	animation-delay: -0.15s;
}
.spinner {
	/* display: inline-block !important; */
	position: relative !important;
	width: 80px;
	height: 80px;
	margin: 0 auto;
}
.apTicketValue {
	flex-wrap: wrap;
}
.apTikcketOwner {
	flex: 0 0 100%;
	max-width: 100%;
}
.apTikcketOwner a {
	margin-left: 6px;
}
.tabButtonIconTextActive svg, .secondaryButtonIconActive svg, .primaryButtonIconTextDefault svg, .primaryMediumButtonIconDefault svg {
	fill: var(--apColorLight);
}
.tabButtonIconTextActive, .primaryButtonIconTextDefault, .primaryButtonTextDefault, .primarySmallButtonTextDefault, .primaryLargeButtonIconTextDefault, .secondarySmallButtonTextActive {
	color: var(--apColorLight);
}
.tabButtonIconTextDefault:hover, .mainNavButtonTextDefault, .mainNavButtonTextActive, .mainNavButtonIconDefault, .mainNavButtonIconActive {
	background-color: var(--apColorMediumGray);
}
.primaryButtonIconTextDisabled svg {
	fill: var(--apColorDarkGray1);
}
.table1Column {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: var(--apColorGray1);
	width: 100%;
	height: 100%;
}
.tetriaryButtonIconDefault {
	background-color: initial;
}
.tetriaryButtonIconDefault:hover {
	background-color: var(--apColorMediumGray);
}
.logRowNormal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 80px;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	padding: 4px 0 4px 0;
	margin-bottom: 1px;
	background-color: var(--apColorLightGray);
	flex-wrap: wrap;
}
.videoCard2Columns h3 {
	margin-top: 8px;
}
.videoCard2Columns {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	flex-wrap: wrap;
	margin-top: 24px;
}
.videoCard2Columns+.groundPropertiesHeader {
	margin-top: -10px;
}

.groundPropertiesHeaderNews{
	padding-left: 20px;
	width: 50%;
}
.groundPropertiesHeaderNews h2{
	font-size: 16px;
	margin-top: 8px;
}
.videoCard2ColumnsShort {
	margin-top: 32px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	flex-wrap: wrap;
}
.videoCardCoverContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 570px;
	flex-grow: 1;
	padding-right: 24px;
	padding-left: 24px;
	border-right: 1px solid var(--apColorGray1);
	margin-bottom: 16px;
}

.videoCardCoverImage,.eventCardCoverImage {
	width: 320px;
	height: 193px;
	background-image: URL('./assets/images/emptyImage.png');
	background-position: center;
	background-size: cover;
	border-radius: 2px;
	border: 1px solid rgba(68, 79, 96, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
/* .eventCardCoverImage{
	width: 282px;
	height: 282px;
} */
.videoCardCoverImageAndDrop {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	flex-wrap: wrap;
}
.videoCardCoverImageAndText {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-right: 16px;
}
.videoCardCoverText {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	margin-top: 8px;
}
.videoCardDropArea svg {
	width: 24px;
	height: 24px;
	fill: var(--apColorDark);
}
.videoCardDropArea {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 129px;
	background-color: var(--apColorLightGray);
	border: 2px dashed var(--apColorDark);
	border-radius: 8px;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	text-align: center;
}
.videoCardDropArea:hover:not(.apDisabled) {
	background-color: var(--apColorLight);
	border: 2px dashed var(--apColorAccent);
}
.videoCardDropArea:hover:not(.apDisabled) svg {
	fill: var(--apColorAccent);
}
.videoCardDropAreaAndHint .apParkImagesHintArea {
	padding: 11px;
	margin-left: 0;
	margin-right: 0;
}
.videoCardDropAreaAndHint .videoCardDropArea, .videoCardDropAreaAndHint .apParkImagesHintArea {
	width: 209px;
	margin-bottom: 16px;
}
.videoCardDropAreaAndHint {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.videoCardNameDescriptionContainer .apInputText {
	width: 100%;
	margin-top: -6px;
}
.videoCardNameDescriptionContainer .apTextarea textarea {
	height: 151px;
}
.videoCardNameDescriptionContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 570px;
	flex-grow: 1;
	padding-left: 24px;
	padding-right: 24px;
}
.videoCardSettingsFirstColumn .apInputText {
	width: 100%;
}
.videoCardSettingsFirstColumn {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 300px;
	flex-grow: 1;
	padding-right: 24px;
	padding-left: 24px;
	border-right: 1px solid var(--apColorGray1);
   /* margin-top: 24px;
	*/
	margin-bottom: 16px;
}
.videoCardSettingsFirstColumn .apDropDownList {
	width: 100%;
}
.videoCardSettingsRegions {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 24px;
	padding-right: 24px;
	width: 300px;
	flex-wrap: wrap;
	flex-grow: 1;
}
.videoCardSettingsRegions h3 {
	margin-top: 0;
}
.videoCardSettingsRegions span {
	margin-bottom: 4px;
	margin-right: 16px;
}
.videoCardTextUnderVideo button {
	margin-left: 8px;
	padding-right: 0px;
}
.videoCardTextUnderVideo {
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-top: 8px;
	margin-bottom: 16px;
}
.videoCardVideoDropAndPlayerArea .videoPlayerContainer {
	height: initial;
}
.videoCardVideoDropAndPlayerArea {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.videoCardVideoWithTextBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 50%;
	min-width: 300px;
}
.videoCardVideoWithTextBlock:first-child {
	padding-right: 8px;
	padding-left: 8px;
}
.videoCardVideoWithTextBlock:last-child {
	padding-right: 8px;
	padding-left: 8px;
}
.videoDescriptionBlock p {
	margin-bottom: 20px;
}
.videoDescriptionBlock {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 16px;
}
.videoLikesBlock .secondaryGhostButtonIconTextDefault {
	padding: 4px 13px 4px 10px;
}
.videoLikesBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 4px;
	margin-bottom: 4px;
	padding-right: 4px;
}
.videoNameAndLikes {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 71px;
	padding-top: 8px;
	padding-bottom: 8px;
	flex-wrap: wrap;
	border-bottom: 1px solid var(--apColorGray1);
}
.videoNameBlock h2 {
	margin-bottom: 0;
	margin-top: 0;
}
.videoNameBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	min-width: 280px;
	flex-grow: 1;
	margin-top: 4px;
	margin-bottom: 4px;
	padding-left: 4px;
}
.videoPlayerAndThumbnailsBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	flex-wrap: wrap;
}
.videoPlayerColumn {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 530px;
	flex-grow: 1;
	margin-right: 16px;
	margin-bottom: 16px;
}
.videoPlayerContainer svg {
	fill: var(--apColorLight);
	width: 80px;
	height: 80px;
	cursor: pointer;
	position: absolute;
}
.iconButton svg{
	width: 24px;
	height: 24px;
}
.videoPlayerContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 100%;
	position: relative;
   /* height: 444px;
	*/
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 8px;
	background: linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1));
}
.qualityButtons button{
	position: absolute;
	bottom: 32px;
	right: 6px;
	background: black;
	color: white;
	display: none;
}
.videoPlayerContainer:hover .qualityButtons button{
	display: block;
}
/* .videoPrimaryButtonsBlock button:not(:first-child) {
	margin-left: 16px;
}
*/
.videoPrimaryButtonsBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	padding-top: 24px;
	padding-bottom: 24px;
}
.videoPrimaryButtonsBlock button {
	margin-top: 8px;
	margin-bottom: 8px;
	margin-right: 16px;
}
.videoThumbnailImage span {
	padding: 4px;
	background: rgba(68, 79, 96, 0.5);
	border-radius: 8px 0px 8px 0px;
	font-weight: 600;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorLight);
}
.videoThumbnailImage {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	width: 175px;
	height: 98px;
	background: linear-gradient(rgba(68, 79, 96, 0.1), rgba(68, 79, 96, 0.1));
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 8px;
	flex-shrink: 0;
}
.videoThumbnailText {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 130px;
	flex-grow: 1;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
	padding: 8px 8px 8px 12px;
}
.videoThumbnailWithText {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	cursor: pointer;
}
.videoThumbnailWithText:hover {
	background-color: var(--apColorLight);
}
.videoThumbnailWithText:not(:first-child) {
	margin-top: 16px;
}
.videoThumbnailsColumn {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 380px;
}
.viewTriggerBlock button {
	margin-left: 8px;
}
.viewTriggerBlock span {
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	margin-right: 8px;
	letter-spacing: 0.03em;
   /* letter-spacing: normal;
	*/
}
.viewTriggerBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}
.apError input, .apError textarea {
	border-color: var(--apColorError);
	background-color: var(--apColorLight);
}
.apError label, .apError span, .apError div span, .apError div span+span {
	color: var(--apColorError);
}
span.apError {
	color: var(--apColorError);
}
.apError label, .apFilled label {
	background-color: var(--apColorLightGray);
	background-image: URL('./assets/images/inputFocusedLabelBG.png');
}
.apFilled input, .apFilled textarea {
	background-color: var(--apColorLight);
}
.messagesNewMessage .secondaryButtonIconDefault:hover {
	background-color: var(--apColorAccent);
}
.messagesNewMessage .secondaryButtonIconDefault:hover svg {
	fill: var(--apColorLight);
}
.messagesRow {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 8px;
	margin-bottom: 8px;
}
.messagesRowUserPhotoCell {
	min-width: 60px;
	margin-left: 12px;
	padding-bottom: 2px;
}
.messagesRowContentCell {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
	width: 100%;
}
.messagesRowDate {
	padding: 8px 16px 8px 16px;
	border-radius: 20px;
	background-color: var(--apColorOverlayMedium);
	color: var(--apColorLight);
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-wrap: wrap;
	min-height: 34px;
}
.messagesRowTextCell {
	max-width: 740px;
	width: 220px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: var(--apColorLight);
	border-radius: 8px;
	padding: 16px;
}
.messagesRowTextCell.apCurrentUserMessage {
	background-color: var(--apColorMediumGray);
}
.messagesRowTextCellUserName {
	margin-bottom: 8px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}
.messagesRowTextCellUserName h4 {
	margin: 2px 0 0 0;
	cursor: pointer;
}
.messagesRowTextCellUserName span {
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
}
.messagesRowTextCellMessage {
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
}
.messagesRowTextCellOrigin {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 8px;
}
.messagesRowTextCellOrigin span {
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
}
.messagesRowTextCellOrigin span:first-child {
	text-align: left;
	color: var(--apColorError);
	cursor: pointer;
}
.messagesRowTextCellOrigin span:last-child {
	text-align: right;
}
.messagesRowDeletedFile {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	color: var(--apColorDarkGray1);
}
.messagesRowDeletedFile svg {
	fill: var(--apColorDarkGray1);
	width: 24px;
	height: 24px;
}
.messagesRowDeletedFile span {
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	margin-left: 8px;
}
.messagesRowTextCellFile {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.messagesRowTextCellFile div:first-child {
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: var(--apColorDark);
	width: 48px;
	height: 48px;
	margin-right: 12px;
}
.messagesRowTextCellFile div:last-child {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.messagesRowTextCellFile svg {
	width: 24px;
	height: 24px;
	fill: var(--apColorLight);
}
.messagesRowTextCellFile h4 {
	margin: 0;
   /* word-break: break-all;
	*/
	overflow-wrap: anywhere;
	overflow: hidden;
}
.messagesRowTextCellFile span {
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
}
.apToggledMainMenu {
	flex-direction: column;
	align-items: flex-end;
	padding-top: 8px;
	padding-bottom: 8px;
}
.profileNameBlock .apParkImagesHintArea {
	height: auto;
	max-width: 340px;
	width: initial;
	margin: 24px 0 16px 0;
}
.apCheckBoxWithLabel {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
   /* margin-bottom: 8px;
	margin-top: 8px;
	*/
	margin-right: 16px;
}
.apCheckBoxWithLabel input {
	margin-left: 0;
}
.secondaryButtonIconDefault.apInputTypeFile {
	padding: 0;
	width: 40px;
	height: 40px;
}
.secondaryButtonIconDefault.apInputTypeFile label {
	width: 100%;
	height: 100%;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}
.logActionButtonsBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	flex-wrap: wrap;
}
.logActionButtonsBlock .apInputText {
	width: 300px;
}
.logActionButtonsBlock .apDropDownList {
	width: 160px;
}
.logActionButtonsBlock .apInputText, .logActionButtonsBlock .apDropDownList {
	margin-right: 16px;
	flex-grow: 1;
}
.logActionButtonsBlock button {
	margin-bottom: 12px;
}
.apLogValue {
	display: flex;
	flex-direction: column;
	min-height: 48px;
	padding: 8px 8px 8px 8px;
	justify-content: center;
	align-items: flex-start;
	flex-grow: 1;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
}
.apLogValue:not(:first-child) {
	border-left: 1px solid var(--apColorGray1);
}
.apLogValue:last-child {
	align-items: flex-end;
}
.apLogValue span {
   /* font-weight: normal;
	*/
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
}
.apDisplayNone {
	display: none;
}
.coordinatorsRowNormal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	min-height: 64px;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	padding: 4px 0;
	margin-bottom: 1px;
	background-color: var(--apColorLightGray);
   /* flex-wrap: wrap;
	*/
}
.coordinatorsRowNormal.apDisabled {
	color: var(--apColorDarkGray2);
}
.coordinatorsRowNormal.apDisabled button svg {
	fill: var(--apColorDarkGray2);
}
.coordinatorsRowNormal:hover {
	background-color: var(--apColorLight);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 0px rgba(0, 0, 0, 0.08);
	cursor: pointer;
}
.coordinatorsRowNormal:hover.apDisabled {
	background-color: var(--apColorLightGray);
}
.coordinatorValueImage {
	width: 48px;
	min-width: 48px;
	height: 48px;
	min-height: 48px;
	margin-right: 4px;
	margin-left: 4px;
	margin-top: 4px;
	margin-bottom: 5px;
	background-size: cover;
	border: 1px solid rgba(68, 79, 96, 0.1);
	border-radius: 24px;
	background-position: center;
	background-color: var(--apColorGray1);
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
	display: flex;
	justify-content: center;
	align-items: center;
}
.coordinatorAllSecPropsBlock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	flex-wrap: wrap;
	margin-top: 4px;
	margin-bottom: 4px;
}
.apFcRoutingPointItem.selectedPoint{
	background-color: var(--apColorBrandBG6);
}
.coordinatorValueName {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex-grow: 3;
	margin: 8px 8px 8px 8px;
	min-width: 190px;
	width: 160px;
	border-right: 1px solid var(--apColorGray1);
}
.coordinatorValueName span {
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
	display: block;
}
.coordinatorRegionAndPhoneAndMailBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 4;
	flex-wrap: wrap;
}
.coordinatorRegionBlock {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: 4px 8px 4px 8px;
	flex-grow: 1;
	width: 150px;
	border-right: 1px solid var(--apColorGray1);
}
.coordinatorPhoneAndMailBlock {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	flex-grow: 9;
	width: 200px;
	margin: 4px 8px 4px 4px;
   /* border-right: 1px solid var(--apColorGray1);
	*/
}
.coordinatorPhoneAndMailBlock div {
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
	margin: 1px 8px 1px 4px;
	flex-grow: 1;
	min-width: 180px;
	width: 120px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.coordinatorStatusIndicatorBlock {
	margin: 4px 8px 4px 8px;
	min-width: 105px;
	width: 80px;
}
.coordinatorButtonsBlock {
	margin-right: 8px;
	margin-top: 6px;
	margin-left: 2px;
	margin-bottom: 8px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
	flex-wrap: wrap;
}
.catVideosRowNormal {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	min-height: 92px;
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
   /* letter-spacing: 0.03em;
	*/
	color: var(--apColorDark);
	padding: 8px 0 8px 0;
	margin-bottom: 1px;
   /* background-color: var(--apColorLightGray);
	*/
	flex-wrap: wrap;
}
.catVideosRowNormal:nth-child(even) {
	background: var(--apColorLightGray);
}
.catVideosRowNormal:nth-child(odd) {
	background: var(--apColorMediumGray);
}
.catVideosRowNormal:hover {
	background-color: var(--apColorLight);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 0px rgba(0, 0, 0, 0.08);
	cursor: pointer;
}
.catVideosRowNormal:hover.apDisabled {
	background-color: var(--apColorLightGray);
}
.catVideoValue {
	min-height: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-right: 16px;
	padding-left: 16px;
	background-repeat: no-repeat;
	background-size: 1px;
	background-position-x: left;
	background-position-y: center;
	background-image: URL('./assets/images/lineVertical.png');
	min-width: 108px;
	overflow-wrap: anywhere;
}

.catVideoValue:nth-child(1) {
	margin-top: 4px;
	margin-bottom: 4px;
}
.catVideoValue:nth-child(2) {
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
	min-width: 170px;
	width: min-content;
	flex-grow: 1;
}
.catVideoValue.firstLink{
	flex-direction: column;
	justify-content: center;
	align-items: baseline;
}
.profileLinkVideo{
	padding-top: 17px;
}
.catVideoValue.firstLink a{
	font-size: 10px;
}
.catVideoValue:nth-child(3) {
	margin-top: 4px;
	margin-bottom: 4px;
	background: initial;
	line-height: 140%;
	align-items: flex-end;
}
.catVideoValue:nth-child(5) {
	align-items: center;
	padding-left: 12px;
	padding-right: 12px;
}
.catVideoValue:nth-child(6) {
	max-width: 128px;
	line-height: 120%;
}
.catVideoValue:nth-child(7) {
	min-width: 97px;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}
.catVideoValue:nth-child(7) svg {
	margin-right: 8px;
}
.catVideoValue span {
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
}
.catVideoValue svg {
	width: 24px;
	height: 24px;
}
.usersRowNormal {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	min-height: 80px;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	padding: 8px 0 8px 0;
	margin-bottom: 1px;
	background-color: var(--apColorLightGray);
	flex-wrap: wrap;
}
.usersRowNormal:hover {
	background-color: var(--apColorLight);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 0px rgba(0, 0, 0, 0.08);
	cursor: pointer;
}
.searchMapTabList {
	display: none;
}
.leaflet-control.leaflet-bar.headline.apInputText:hover .searchMapTabList{
	display: flex;
}
.searchMapTabInput:focus+.searchMapTabList, .searchMapTabInput:target+.searchMapTabList, .searchMapTabInput:hover+.searchMapTabList,.searchMapTabInput:active+.searchMapTabList  {
	display: flex;
}
.searchMapTabList:hover {
	display: flex;
}
.usersRowNormal:hover.apDisabled {
	background-color: var(--apColorLightGray);
}
.userValue {
	min-height: 48px;
	min-width: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-right: 16px;
	padding-left: 16px;
	background-repeat: no-repeat;
	background-size: 1px;
	background-position-x: left;
	background-position-y: center;
	background-image: URL('./assets/images/lineVertical.png');
}
.userValue:nth-child(1) {
	min-width: 300px;
	width: min-content;
	flex-grow: 1;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 4px;
	margin-bottom: 4px;
}
.userValue:nth-child(2) {
	background: initial;
}
.userValue:nth-child(3) {
	max-width: 150px;
}
.userValue:nth-child(4) {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex-grow: 1;
	max-width: 250px;
	overflow-wrap: anywhere;
	overflow-x: hidden;
}
.userValue:last-child {
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.userValue button {
	margin-right: 8px;
	margin-left: 8px;
}
.userValue svg {
	width: 24px;
	height: 24px;
	fill: var(--apColorAccent);
	margin-right: 8px;
}
.userValueEmail {
	font-weight: normal;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.03em;
	*/
}
.userValueImage {
	width: 48px;
	min-width: 48px;
	height: 48px;
	min-height: 48px;
	margin-right: 12px;
	border: 1px solid rgba(68, 79, 96, 0.1);
	border-radius: 24px;
	background-position: center;
	background-color: var(--apColorGray1);
	background-size: cover;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
	display: flex;
	justify-content: center;
	align-items: center;
}
.userValueNumbers {
	font-weight: normal;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.03em;
	*/
}
.userValueName {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.userValueName span {
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
	display: block;
}
.myObjectsRowNormal {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	min-height: 76px;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	padding: 8px 0 8px 0;
	margin-bottom: 1px;
   /* background-color: var(--apColorLightGray);
	*/
	flex-wrap: wrap;
}
.myObjectsRowNormal:nth-child(even) {
	background: var(--apColorLightGray);
}
.myObjectsRowNormal:nth-child(odd) {
	background: var(--apColorMediumGray);
}
.myObjectsRowNormal:hover {
	background-color: var(--apColorLight);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 0px rgba(0, 0, 0, 0.08);
	cursor: pointer;
}
.myObjectValue {
	min-height: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	min-width: 120px;
	padding-right: 16px;
	padding-left: 16px;
	background-image: URL('assets/images/lineVertical.png');
	background-repeat: no-repeat;
	background-size: 1px;
	background-position-x: left;
	background-position-y: center;
	overflow-wrap: anywhere;
	margin-top: 4px;
	margin-bottom: 4px;
}
.myObjectValue:nth-child(2) {
	min-width: 240px;
	flex-grow: 1;
	width: min-content;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.myObjectValue:nth-child(3) {
	background: initial;
   /* min-width: 200px;
	*/
	max-width: 200px;
	flex-grow: 1;
}
.myObjectValue:nth-child(4) {
	align-items: center;
}
.myObjectValue:nth-child(5) {
	max-width: 120px;
}
.myObjectValueImage {
	width: 96px;
	height: 60px;
	background-size: cover;
	border: 1px solid rgba(68, 79, 96, 0.1);
	border-radius: 4px;
	background-position: center;
	background-image: URL('assets/images/emptyImage.png');
}
.myObjectValueImageCus {
    width: 96px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid rgba(68, 79, 96, 0.1);
    border-radius: 4px;
    position: relative;
    background-position: center;
    background-image: URL('assets/images/emptyImage.png');
	background: border-box;
}

.myObjectValueImageCus::before {
    content: '';
    width: 96px;
    height: 60px;
    z-index: -1; /* Change the z-index to 10 */
    position: absolute;
    background-image: URL('assets/images/backgroundRoute.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid rgba(68, 79, 96, 0.1);
    border-radius: 4px;
}
.myObjectValueName {
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.006em;
	*/
}
.myObjectValueNumbers {
	font-weight: normal;
	font-size: 14px;
	line-height: 120%;
   /* letter-spacing: 0.03em;
	*/
}
.apFooterFullHeightContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 192px;
	flex-grow: 1;
	justify-content: flex-end;
	align-items: center;
	z-index: 100;
}
.apColorDiff01 {
	fill: var(--apColorAccent);
}
.apColorDiff02 {
	fill: var(--apColorAttention);
}
.apColorDiff03 {
	fill: var(--apColorError);
}
.apColorCategory01 {
	fill: var(--apColorBrandBG3);
}
.apColorCategory02 {
	fill: var(--apColorBrandLogo3);
}
.apColorCategory03 {
	fill: var(--apColorBrandBG4);
}
.apColorCategory04 {
	fill: var(--apColorBrandBG1);
}
.apColorCategory05 {
	fill: var(--apColorBrandBG6);
}
.apColorCategory06 {
	fill: var(--apColorBrandBG5);
}
.menuFunctionLine button:disabled {
	opacity: .3;
	filter: grayscale(1);
	pointer-events: none;
}
.apCollapseInner {
	transition: ease .2s;
	overflow: hidden;
}
.apFcHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 80px 0 30px;
}
.apFcHeader>div {
	display: flex;
	flex-basis: 33%;
	flex-grow: 1;
}
.apFcEventStatusContainer {
	justify-content: flex-end;
}
.apFcHeader .apStatusItem {
	width: auto;
	padding-right: 16px;
	padding-left: 16px;
}
.apFcHeader h2 {
	margin: 0;
	font-weight: 700;
	font-size: 32px;
	line-height: 120%;
	letter-spacing: 0.006em;
}
.apFcHeader h2 span {
	display: block;
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	letter-spacing: 0.03em;
	margin-top: 6px;
}
.apFcEvent {
	flex: 0 0 100%;
	max-width: 304px;
	position: relative;
	background-color: var(--apColorBrandLogo2);
	border-radius: 8px;
	padding: 18px 18px 18px 47px;
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0.006em;
	min-height: 78px;
}
.apFcEvent.empty {
	padding: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(140, 140, 140, 0.16);
	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
}
.apFcEvent span {
	display: block;
	font-weight: 600;
	font-size: 11px;
	line-height: 120%;
	color: var(--apColorDark);
	margin-top: 5px;
}
.apFcEvent svg {
	position: absolute;
	top: 19px;
	left: 18px;
}
.apFcEventHeader {
	display: flex;
	margin: 0 -12px 20px;
	justify-content: space-between;
}
.addPointForm {
	background-color: #fff;
	padding: 30px 8px 20px;
}
.apAdmitPointList .addPointForm h3 {
	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
	letter-spacing: 0.006em;
	text-align: center;
	margin: 0 0 30px 0;
}
.addPointForm .flex-wrp {
	display: flex;
	justify-content: center;
}
.addPointForm .flex-wrp .input-wrp {
	padding: 0 10px;
	margin: 0 0 10px 0;
}
.addPointForm .flex-wrp .input-wrp>* {
	width: 100%;
	min-width: 0;
	box-sizing: border-box;
}
.addPointForm .flex-wrp .input-wrp:nth-child(1) {
	flex: 0 0 8.3%;
	max-width: 8.3%;
}
.addPointForm .flex-wrp .input-wrp:nth-child(2) {
	flex: 0 0 33.3%;
	max-width: 33.3%;
}
.addPointForm .flex-wrp .input-wrp:nth-child(3) {
	flex: 0 0 25%;
	max-width: 25%;
}
.addPointForm .flex-wrp .input-wrp:nth-child(4) {
	flex: 0 0 15%;
	max-width: 15%;
}
.addPointForm .flex-wrp .input-wrp:nth-child(5) {
	flex: 0 0 15%;
	max-width: 15%;
}
.addPointForm label {
	background-color: #fff;
}
.addPointForm input {
	background-color: #fff;
}
.apItinerary {
	padding-top: 24px;
	display: flex;
	align-items: stretch;
	margin: 0 -12px;
}
.apItinerary.isEmpty {
	margin: 0;
}
.apItinerary .map {
	flex: 0 0 50%;
	max-width: 50%;
	padding: 0 12px;
	max-height: 358px;
}
.apItinerary .map iframe {
	width: 100%;
	height: 358px;
	border-radius: 10px;
}
.apItinerary.isEmpty .map {
	flex: 0 0 100%;
	max-width: 100%;
	padding: 0;
}
.apPointList {
	flex: 0 0 50%;
	max-width: 50%;
	padding: 0 12px;
}
.apPointList .listContainer {
	border: 2px solid var(--apColorBrandBG1);
	border-radius: 7px;
	overflow: hidden;
	height: 100%;
}
.apPointList .listContainer .item {
	display: flex;
	padding: 20px 0;
	border-bottom: 1px solid var(--apColorGray1);
	align-items: center;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
}
.apPointList .listContainer .item>div {
	box-sizing: border-box;
	text-align: center;
}
.apPointList .listContainer .item>div span {
	display: block;
}
.apPointList .listContainer .item>div:first-child {
	flex: 0 0 64px;
	max-width: 64px;
	position: relative;
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	text-align: center;
	letter-spacing: 0.006em;
}
.apPointList .listContainer .item>div:first-child span {
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	text-align: center;
	color: var(--apColorDark) 
}
.apPointList .listContainer .item>div:first-child:before {
	position: absolute;
	content: '';
	right: 0;
	top: 50%;
	margin-top: -15px;
	height: 30px;
	width: 1px;
	background-color: var(--apColorGray1);
}
.apPointList .listContainer .item>div:nth-child(2) {
	min-width: 240px;
	max-width: 240px;
	text-align: left;
	padding-left: 16px;
}
.apPointList .listContainer .item>div:nth-child(2) .headline {
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorDark);
}
.apPointList .listContainer .item>div:nth-child(2) span {
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	color: var(--apColorDark);
}
.apPointList .listContainer .item>div:nth-child(3) {
	flex: 0 0 93px;
	max-width: 93px;
	padding-left: 16px;
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	text-align: center;
}
.apPointList .listContainer .item>div:nth-child(3) span {
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 120%;
}
.apPointList .listContainer .item>div:last-child {
	flex: 1 1 auto;
	text-align: center;
}
.apItinerary .status, .apItineraryAdmin .status {
	background-color: #DEDFE1;
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	border-radius: 60px;
	width: 132px;
	text-align: center;
	display: inline-block;
}
.qrComponentContainer{
 margin-left: 'auto';
 margin-right: 'auto';
 width: '105mm';

 /* maxHeight:'135mm' */
}
.apItinerary .status.current, .apItineraryAdmin .status.current {
	background-color: var(--apColorDark);
	color: #fff;
}
.apItinerary .status.finished, .apItineraryAdmin .status.finished {
	background-color: var(--apColorBrandLogo2);
}
.apFcEventHeader>* {
	padding: 0 12px;
	box-sizing: border-box;
}
.apFcEventHeader .img-wrp {
	flex: 0 0 306px;
	max-width: 306px;
}
.apFcEventHeader .img-wrp img {
	width: 100%;
	/* height: 282px; */
	object-fit: cover;
	border-radius: 10px;
	vertical-align: bottom;
	aspect-ratio: 16/10;
}
.apFcEventHeader .main-description {
	flex: 1 1 auto;
	width: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.apFcEventHeader .main-description>* {
	width: 100%;
	box-sizing: border-box;
	max-width: 410px;
}
.apFcEventHeader .main-description .headline {
	display: flex;
	align-items: center;
}
.apFcEventHeader .main-description .fcTitle {
	font-weight: 600;
	font-size: 11px;
	line-height: 120%;
	color: var(--apColorDark);
	position: relative;
	text-decoration: none;
	transition: .2s;
	margin-right: 10px;
}
.apFcEventHeader .main-description .fcTitle:before {
	position: absolute;
	content: '';
	left: 0;
	right: 0;
	bottom: 0;
	height: 1px;
	background-color: var(--apColorAccent);
}
.apFcEventHeader .main-description .fcTitle:hover {
	color: var(--apColorAccent);
}
.workoutRating{
	display: flex;
	flex-direction: column;
	width: fit-content;
	float: right;
	margin-top: -20px;
}
.apFcEventHeader .main-description .status {
	font-weight: 500;
	font-size: 11px;
	color: var(--apColorLight);
	background-color: var(--apColorAccent);
	border-radius: 60px;
	line-height: 17px;
	padding: 0 8px;
	cursor: default;
}
.apTabbar nav button.apButtonShare {
	font-weight: 600;
	font-size: 14px;
	line-height: 160%;
	letter-spacing: 0.03em;
	color: var(--apColorLightGray);
	background-color: var(--apColorDark);
	height: 40px;
}
.apTabbar nav button.apButtonShare:hover {
	background-color: var(--apColorBrandBG1);
}
.apTabbar nav button.apButtonShare svg {
	filter: none !important;
}
.apTabbar nav button.apButtonShare:last-child svg path {
	stroke: #fff;
}
.apFcEventHeader .main-description h3 {
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0.006em;
	margin: 5px 0 10px 0;
}
.apFcEventHeader .main-description .date {
	margin-bottom: 8px;
}
.apFcEventHeader .main-description .date, .apFcEventHeader .main-description .address {
	display: flex;
	align-items: center;
	text-decoration: none;
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	letter-spacing: 0.03em;
	color: var(--apColorDark) 
}
.apFcEventHeader .main-description .date svg, .apFcEventHeader .main-description .address svg {
	margin-right: 5px;
}
.apFcEventButton {
	display: flex;
	justify-content: center;
	background-color: var(--apColorAccent);
	color: var(--apColorLight);
	height: 52px;
	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
	letter-spacing: 0.006em;
	transition: .2s;
}
.apFcEventButton:hover {
	opacity: .2s;
}
.apFcEventButton svg {
	margin-right: 10px;
}
.apFcEventButton svg path, .apFcEventButton svg circle {
	stroke: var(--apColorLight);
}
.apFcEventHeader .aside {
	flex: 0 0 484px;
	max-width: 484px;
	max-height: 300px;
}
.apConferenceBlock {
	height: 100%;
	background-color: #596776;
	border-radius: 10px;
	padding: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.apConferenceBlock>div {
   /* position: relative;
	*/
}
.apConferenceBlock p {
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorLight);
   /* position: absolute;
	*/
   /* width: 320px;
	*/
   /* left: 50%;
	*/
   /* transform: translateX(-50%);
	*/
   /* margin: 0;
	*/
	top: -56px;
	text-align: center;
}
@-webkit-keyframes apConferencPulce {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(30, 189, 193, 0.4);
   }
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(30, 189, 193, 0);
   }
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(30, 189, 193, 0);
   }
}
@keyframes apConferencPulce {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(30, 189, 193, 0.4);
		box-shadow: 0 0 0 0 rgba(30, 189, 193, 0.4);
   }
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(30, 189, 193, 0);
		box-shadow: 0 0 0 10px rgba(30, 189, 193, 0);
   }
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(30, 189, 193, 0);
		box-shadow: 0 0 0 0 rgba(30, 189, 193, 0);
   }
}
.apConferenceBlock button {
	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorLight);
	background-color: var(--apColorAccent);
	height: 46px;
	margin: 0 auto;
	padding: 0 24px;
	box-shadow: 0 0 0 rgba(30, 189, 193, 0.4);
	animation: apConferencPulce 2s infinite;
}
/* kill this stylesheet */
.apFcEventHeader .aside iframe {
	width: 100%;
	height: 282px;
	border-radius: 10px;
	box-sizing: border-box;
}
.apBreadCrumbs {
	padding: 30px 0;
	display: flex;
	margin: 0 0 24px 0;
	width: 100%;
	box-sizing: border-box;
	justify-content: flex-start;
	border-bottom: 2px solid var(--apColorDark);
}
.apBreadCrumbs li {
	vertical-align: center;
	list-style: none;
}
.apBreadCrumbs .arrow {
	height: 24px;
	width: 24px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
.apBreadCrumbs .arrow:before {
	transform: rotate(45deg);
	border-top: 2px solid #000;
	border-right: 2px solid #000;
}
.apBreadCrumbs button {
	background-color: transparent;
	padding: 0;
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	letter-spacing: 0.03em;
	display: inline-block;
	transition: .2s;
}
.apBreadCrumbs button:not(.active):hover {
	opacity: .5;
}
.apBreadCrumbs button.active {
	color: var(--apColorAccent);
	cursor: default;
}
.apItineraryAdmin {
	display: flex;
	flex-direction: row;
	padding-top: 48px;
}
.apItineraryAdmin h3 {
	margin: 20px 0 70px;
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	text-align: center;
	letter-spacing: 0.006em;
	color: var(--apColorDark);
}
.cross.active{
	fill: #1BA8B2;
}
.cross:hover{
	fill: #1BA8B2;
	cursor: pointer;
   /* stroke: #1BA8B2;
	*/
}
.apItineraryAdmin .map {
	height: 369px;
	overflow: hidden;
	border-radius: 7px;
	margin-bottom: 60px;
}
.apItineraryAdmin .map iframe {
	width: 100%;
	height: 369px;
}
.apAdmitPointList{
	width: 35%;
	margin-left: 24px;
}
.apRoutingPointList{
	width: 100%;
}
.apFcRoutingPointItem{
	width: 180px;
	min-width: 180px;
	max-width: 180px;
	margin-right: 15px;
	border-radius: 8px;
	background-color: var(--apColorGray1);
}
.apFcRoutingPointItem.passed{
	background-color: var(--apColorBrandLogo2) 
}
.apFcRoutingPointItem .info .point{
	margin: 18px auto 9px;
	text-align: center;
	font-weight: 700;
	font-size: 20px;
   /* line-height: 120%;
	*/
}
.apFcRoutingPointItem .info .point i{
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	font-style: normal;
}
.info .point{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.info .point button{

}

.apFcRoutingPointItem .controls button{
	margin: 0 auto;
}
.apFcRoutingPointItem .info .point span svg{
	fill:var(--apColorDark);
}
.apFcRoutingPointItem .info .point span{
	vertical-align: middle;
	padding-right: 5px;
}
.apRoutingPointList .headline{
	margin: 40px 0 55px;
}
.apRoutingPoints{
	margin-bottom: 10px;
}
.apRoutingPoints ul{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	box-sizing: border-box;
	overflow-y: auto;
}
.apMapContainer {
	display: flex;
	flex-direction: row;
}
.apFcAdmitPoint{
	list-style: none;
	padding: 20px 10px;
	border-bottom: 1px solid #DEDFE1;
}
.apFcAdmitPoint span{
	color: var(--apColorError);
}
.apFcAdmitPointItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 20px;
}
.apFcAdmitPointItem:last-child {
	border: none;
}
.apFcAdmitPointItem .info {
	display: flex;
	align-items: center;
	flex: 0 0 310px;
}
.apFcAdmitPointItem .controls {
	display: flex;
	align-items: center;
	flex: 0 0 80px;
	max-width: 247px;
	justify-content: space-between;
	position: relative;
}
.apFcAdmitPointItem .controls .cross {
	padding: 0;
	background-color: transparent;
}
.apFcAdmitPointItem .controls .dragButton {
	padding: 0;
	background-color: transparent;
}
.apAdmitPointList .resultIcon {
	flex: 0 0 42px;
	max-width: 42px;
	height: 42px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: .2s;
}
.apAdmitPointList .resultIcon svg path {
	transition: .2s;
}
.apAdmitPointList .resultIcon:hover {
	background-color: var(--apColorDark);
}
.apAdmitPointList .resultIcon svg path {
	stroke: var(--apColorDark);
}
.apAdmitPointList .resultIcon:hover svg path {
	stroke: var(--apColorLight);
}
.apAdmitPointList .resultIcon.finished {
	background-color: var(--apColorBrandLogo2);
	cursor: default;
}
.apAdmitPointList .resultIcon.finished:hover svg path {
	stroke: var(--apColorDark);
}
.apFcEventsAdmitPoints{
	height: 540px;
	overflow-y: auto;
}
.apFcEventsAdmitPoints ul {
	padding: 0;
}
.apFcAdmitPointItem .info>* {
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	text-align: center;
	letter-spacing: 0.006em;
	position: relative;
}
.apFcAdmitPointItem .info>*:before {
	position: absolute;
	content: '';
	right: 0;
	top: 50%;
	height: 30px;
	width: 1px;
	margin-top: -15px;
	background-color: var(--apColorGray1);
}
.apFcAdmitPointItem .info>* span {
	display: block;
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 120%;
	color: var(--apColorDark);
}
.apAdmitPointList .point {
	flex: 0 0 77px;
	max-width: 77px;
	text-align: center;
}
.apAdmitPointList .point span {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 5px;
}
.apAdmitPointList .date {
	text-align: left;
	flex: 0 0 131px;
	max-width: 131px;
	padding-left: 29px;
}
.apAdmitPointList .title {
	padding-left: 29px;
	flex: 0 0 300px;
	max-width: 300px;
	text-align: left;
}
.apAdmitPointList .headline {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorDark);
	padding: 0 0 20px 0;
	border-bottom: 2px solid var(--apColorDark);
}
.apAdmitPointList .headline button, .addPointForm>button {
	padding: 8px 16px 8px 8px;
	background-color: var(--apColorAccent);
	color: #fff;
	transition: .2s;
}
.addPointForm>button {
	margin: 0 auto;
}
.apAdmitPointList .headline button:hover, .addPointForm>button:hover {
	background-color: var(--apColorDark);
}
.apAdmitPointList .headline button svg {
	margin-right: 8px;
	fill: white;
}

.apAdmitPointList .headline button.primaryMediumButtonIconDefault svg {
	margin-right: 0px;
	margin-left: 8px;

}
.apFcContacts {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.apFcContacts .iconList {
	display: flex;
	justify-content: center;
}
.apFcContacts .iconList a {
	width: 40px;
	height: 40px;
	border-radius: 8px;
	border: 2px solid var(--apColorAccent);
	background-color: transparent;
	transition: .2s;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 7px 7px;
}
.apFcContacts .iconList a:hover {
	background-color: var(--apColorAccent);
}
.apFcContacts .iconList a svg path {
	fill: var(--apColorAccent);
}
.apFcContacts .iconList a:hover svg path {
	fill: var(--apColorGray1);
}
.apFcContactsTel {
	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorDark);
	transition: .2s;
}
.apFcContactsTel:hover {
	opacity: .7;
	text-decoration: none;
}
.apTabbar {
	padding: 24px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 2px solid var(--apColorDark);
	border-bottom: 2px solid var(--apColorDark);
}
.apTabbar nav {
	display: flex;
	column-gap: 24px;
}
/* .apTabbar nav button:first-child {
	margin: 0;
}
.apTabbar>button {
	height: 40px;
}
.apTabbar button a {
	display: flex;
}
.apTabbar nav button svg {
	margin: 0 8px 0 0;
}
.apTabbar nav button.additional {
	background-color: var(--apColorDark);
	color: var(--apColorLight);
	padding: 8px 16px 8px 8px;
}
.apTabbar nav button.additional.blue {
	background-color: var(--apColorBrandBG1);
}
.apTabbar nav button.additional:hover {
	background-color: var(--apColorBrandBG1);
}
.apTabbar nav button.additional.blue:hover {
	background-color: var(--apColorBrandLogo1);
}
.apTabbar button svg {
	transition: .2s;
}
*/
.fcNews {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px;
	position: relative;
	align-items: flex-start;
	padding: 40px 0 0 0;
}
.apFcNewsItemContainer {
	flex: 0 0 50%;
	max-width: 50%;
	box-sizing: border-box;
	padding: 0 12px;
}
.fcNewstItem {
	background-color: var(--apColorLight);
	border: 1px solid #B7B7B7;
	border-radius: 8px;
	padding: 16px 16px 20px;
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 24px;
}
.fcNewstItem h3:hover{
	cursor: pointer;
	color: var(--apColorAccent);
}
.swiper {
	position: relative;
	overflow: hidden;
}
.swiper.swiper-pointer-events{
	padding: 0 0 40px 0;
}

.swiper .swiper-pagination {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	z-index: 3;
}
.swiper .swiper-pagination .swiper-pagination-bullet {
	position: relative;
	margin: 0 10px 20px;
	background-color: #C1C1C1;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	cursor: pointer;
	transition: background-color .2s;
}
.swiper .swiper-pagination .swiper-pagination-bullet::before {
	position: absolute;
	content: '';
	left: -4px;
	top: -4px;
	bottom: -4px;
	right: -4px;
	border: 2px solid #C1C1C1;
	border-radius: 50%;
	transition: border-color .2s;
}
.swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: var(--apColorBrandLogo2);
	width: 10px;
	height: 10px;
}
.swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
	border-color: var(--apColorBrandLogo2);
}
.swiper .swiper-pagination .swiper-pagination-bullet:hover {
	opacity: .8;
}
.swiper .swiper-button-prev, .swiper .swiper-button-next {
	width: 52px;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	/* background: rgba(0, 0, 0, .05) url('assets/images/sliderArrow.svg') center center no-repeat; */
	z-index: 3;
	transition: .2s;
	border-radius: 5px;
}
.swiper .swiper-button-prev:not(.swiper-button-disabled), .swiper .swiper-button-next:not(.swiper-button-disabled) {
	cursor: pointer;
}
.swiper .swiper-button-prev:not(.swiper-button-disabled):hover, .swiper .swiper-button-next:not(.swiper-button-disabled):hover {
	/* opacity: .7; */
	/* background-color: rgb(0 0 0 / 15%); */
	cursor: pointer;
}
.swiper .swiper-button-prev {
	transform: translateY(-50%) scale(-1, 1);
}
.swiper .swiper-button-next {
	left: auto;
	right: 0;
}
.swiper .swiper-button-disabled {
	opacity: 0;
}
.apFcNewsItemContainer .imgWrp {
	margin: 0 0 12px 0px;
}
.apFcNewsItemContainer .imgWrp img {
	width: 100%;
	height: 306px;
	overflow: hidden;
	object-fit: cover;
	vertical-align: bottom;
	border-radius: 8px;
}
.fcNewsInner .swiper .swiper-pagination {
	position: relative;
	padding: 24px 0;
}
.fcNewsInner .swiper .swiper-pagination .swiper-pagination-bullet {
	margin-bottom: 0;
}
.apFcNewsItemContainer .imgWrp button {
	position: absolute;
	right: 19px;
	top: 7px;
	padding: 0;
	color: #fff;
	display: flex;
	align-items: center;
	background-color: transparent;
}
.apFcNewsItemContainer h3 {
	margin: 0;
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
   /* min-height: 72px;
	*/
	letter-spacing: 0.006em;
	text-transform: uppercase;
	color: var(--apColorDark);
}
/* .text .apCheckBoxWithLabel{
}
*/
.apFcNewsItemContainer .date {
	background: var(--apColorBrandLogo2);
   /*C5E505*/
	border-radius: 16px;
	padding: 2px 10px;
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	margin: 0 0 10px 0;
	display: inline-block;
}
.apFcNewsItemContainer p {
	font-weight: 300;
	font-size: 15px;
	line-height: 160%;
	letter-spacing: 0.03em;
	color: var(--apColorDark);
	height: 100%;
	max-height: 100%;
	margin: 0 0 15px 0;
}
.apFcNewsItemContainer .buttonWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.apFcNewsItemContainer .more {
	font-weight: 300;
	font-size: 15px;
	line-height: 160%;
	text-align: center;
	letter-spacing: 0.03em;
   /* text-decoration-line: underline;
	*/
	cursor: pointer;
	color: #1BA8B2;
	transition: .2s;
}
.apFcNewsItemContainer .more:hover {
	text-decoration-line: underline;
	text-decoration: none;
	opacity: .7;
}
.apFcNewsItemContainer .buttonWrap button {
	background-color: transparent;
	border-radius: 0;
	padding: 0;
	position: relative;
	font-weight: 300;
	font-size: 15px;
	line-height: 160%;
	text-align: center;
	letter-spacing: 0.03em;
	text-decoration: underline;
	transition: .2s;
	display: flex;
}
.apFcNewsItemContainer .buttonWrap button svg {
	margin-right: 10px;
}
.apFcNewsItemContainer .buttonWrap button:hover {
	text-decoration: none;
	opacity: .7;
}
@keyframes shake {
	0%, 50%, 100% {
		translate: 0 0 0;
   }
	25% {
		translate: 0 -4px 0;
   }
	75% {
		translate: 0 4px 0;
   }
}
.apLoadMore {
	justify-self: center;
	width: 246px;
	box-sizing: border-box;
	margin: 16px auto 0;
	padding: 8px 16px 8px 8px;
	transition: .2s;
}
.apLoadMore svg {
	rotate: 180deg;
	margin-right: 8px;
}
.apLoadMore:hover svg {
	animation: shake .3s 0s ease forwards;
}
.fcCollapseButton {
	padding: 0;
	background: transparent;
	transition: .2s;
	width: 210px;
	margin: 0 auto 26px;
}
.fcCollapseButton:hover {
	opacity: .7;
}
.fcCollapseButton svg {
   /* transition: .1s;
	*/
	transform: rotate(180deg);
}
.fcCollapseButton.isOpen svg {
	transform: rotate(0);
}
.apFcInfoBlock {
	transition: .2s;
	border-top: 2px solid var(--apColorDark);
	display: flex;
	justify-content: space-between;
	margin: 0 -38px;
	padding: 40px 0;
	font-family: 'Montserrat', sans-serif;
}
.apFcInfoBlock .info {
	flex: 0 0 20%;
	max-width: 20%;
	padding: 0 38px;
	display: flex;
	flex-direction: column;
	margin-bottom: 17px;
}
.apFcInfoBlock .imgWrp {
	margin-bottom: 17px;
	display: flex;
	justify-content: center;
}
.apFcInfoBlock .imgWrp .img {
	width: 180px;
	/* height: 180px; */
	aspect-ratio: 16/10;
	vertical-align: bottom;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: var(--apColorLightGray);
	border-radius: 16px;
}
.apFcInfoBlock .art {
	margin-bottom: 12px;
	width: 100%;
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	text-align: center;
	letter-spacing: 0.006em;
}
.apFcInfoBlock .art span {
	display: block;
	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
	letter-spacing: 0.006em;
	margin-bottom: 5px;
}
.apFcInfoBlock .text {
	flex: 0 0 80%;
	max-width: 80%;
	padding: 0 38px;
}
.apFcInfoBlock .text h3 {
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0.006em;
	margin-top: 0;
}
.apFcInfoBlock .text p {
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	letter-spacing: 0.03em;
}
.apFcUsersRow {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px;
	position: relative;
}
.ratingList{
	display: flex;
	flex-direction: row;
}
.tabRating{
	display: flex;
	flex-direction: row;
	width: 420px;
	justify-content: space-evenly;
}
.ratingText{
	font-weight: 400;
	font-size: 11px;
	text-align: center;
	margin: 0;
}
.star{
	cursor: pointer;
	margin-right: 10px;
	font-size: 24px;
	fill:#B7B7B7;
	color:#B7B7B7;
}
.star.selected{
	color: var(--apColorBrandBG2);
	fill: var(--apColorBrandBG2);
}
.star:hover{
	color: var(--apColorBrandBG6);
	fill: var(--apColorBrandBG6);
}
.apFcUsersRow h3 {
	flex: 0 0 100%;
	max-width: 100%;
	padding: 0px 12px 22px;
	border-bottom: 1px solid #DEDFE1;
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorDark) 
}
.apUserTile {
	flex: 0 0 25%;
	max-width: 25%;
	min-width: 25%;
	padding: 0 12px;
	margin-bottom: 24px;
}
.apUserTileinvite{
position: relative;
}
.apUserTileinviteController{
	position: absolute;
	
}
.apUserTileinviteController{

	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	content: '';
	z-index: 10000;
	background: rgba(0, 0, 0, .6);
	backdrop-filter: blur(1.5px);
	top: 0;
	left: 0;
	border-radius: 15px;
}

.apUserTileinviteControllerButton{
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-evenly;
}
.apUserTileinviteController p{
color: var(--apColorLight);
font-weight: 700;
font-size: 12px;
}
.apUserTileinviteController button.tetriaryButtonIconDefault {
	background: var(--apColorLight);
}
.apUserTileinviteController button.tetriaryButtonIconDefault svg{
	fill:var(--apColorBrandBG2) ;
}
.apUserTileinviteController button.tetriaryButtonIconDefault:hover{
	background: var(--apColorDark);
}
.mySwiperSlide .apComment{
	background-color:var(--apColorLightGray);
	padding: 10px;
	border-radius: 5px;
	margin-top: 5px;
}
.apUserTileItem {
	background-color: var(--apColorLight);
	border-radius: 15px;
	display: flex;
	box-sizing: border-box;
	position: relative;
	width: fit-content;
	min-width:100%;
	max-width: fit-content;
	padding: 10px;
	flex-wrap: wrap;
	font-family: 'Montserrat', sans-serif;
}
.apUserTileItem .top {
	flex: 0 0 100%;
	max-width: 100%;
	display: flex;
}
.apUserTileItem .top .imgWrp {
	flex: 0 0 90px;
	max-width: 90px;
	overflow: hidden;
}
.apUserTileItem .top img {
	width: 64px;
	height: 64px;
	border-radius: 50%;
	object-fit: cover;
	vertical-align: bottom;
}
.apUserTileItem .top .main {
	flex: 1 1 auto;
	padding-left: 10px;
	display: flex;
	flex-direction: column;
}
.apUserTileItem a {
	display: block;
	text-decoration: none;
}
.apUserTileItem .main svg path {
	fill: var(--apColorAccent);
}
.apPersonalPageInfoContainer .about h3.name{
	margin: 0;
}
.apUserTileItem .name {
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorDark);
	position: relative;
   /* padding-left: 7px;
	*/
	margin: 0 0 8px 0;
}

.apUserTileItem .role {
	font-weight: 400;
	font-size: 10px;
	line-height: 14px;
	letter-spacing: 0.006em;
	color: var(--apColorDarkGray1);
	/* margin: 0 0 8px 0; */
}
/* .apUserTileItem .name:before {
	position: absolute;
	content: '';
	left: 0;
	right: 0;
	bottom: 0;
	height: 1px;
	background-color: var(--apColorDark);
} */
.apUserTileItem .contact, .apUserTileItem .age {
	display: flex;
	align-items: center;
	color: var(--apColorDark);
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 120%;
	margin-bottom: 3px;
	transition: .2s;
}
.apUserTileItem .contact:hover {
	color: var(--apColorBrandBG1);
}
.apUserTileItem .age svg {
	width: 16px;
	height: 16px;
}
.apUserTileItem .contact svg, .apUserTileItem .age svg {
	margin-right: 5px;
}
.apUserTileItem .status {
	margin-top: 4px;
	background-color: var(--apColorAccent);
	color: var(--apColorLight);
	text-align: center;
	font-weight: 500;
	font-size: 11px;
	line-height: 17px;
	border-radius: 60px;
}
.apUserTileItem .status.owner {
	background-color: var(--apColorBrandBG4);
}
.apUserTileItem .status.selfs {
	background-color: var(--apColorAttention);
}
.apUserTileItem .bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-top: 10px;
}
.apUserTileItem .bottom a,.apUserTileItem .bottom span {
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	color: var(--apColorDark);
	max-width: 150px;
	transition: .2s;
}
.apUserTileItem .bottom a:hover {
	cursor: auto;
}
.apUserTileItem .bottom a svg {
	margin: 0 8px 0 0;
}
.apUserTileItem .bottom .rating {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	letter-spacing: 0.03em;
	color: var(--apColorDark) 
}
.apUserTileItem .bottom .rating svg {
	margin-right: 5px;
}
.apTrainingsHistoryContainer {
	display: flex;
	justify-content: space-between;
	margin: 0 -13px;
}
.apColumn {
	flex: 0 0 50%;
	max-width: 50%;
	padding: 0 13px;
	box-sizing: border-box;
}
.apTrainingItemContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.apTrainingDate {
	padding: 6px 20px;
	border-radius: 15px 15px 0 0;
	font-weight: 700;
	font-size: 16px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorLight);
	background-color: var(--apColorDark);
}
.apTrainingItemContainer.current .apTrainingDate {
	background-color: var(--apColorAccent);
}
.apTrainingItem {
	border: 1px solid #444F60;
	width: 100%;
	background: var(--apColorMediumGray);
	border: 1px solid var(--apColorDark);
	border-bottom: none;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}
.apTrainingItemContainer .apTrainingItem:nth-child(2) {
	border-top-right-radius: 10px;
}
.apTrainingItemContainer .apTrainingItem:last-child {
	border-bottom: 1px solid var(--apColorDark);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	margin-bottom: 52px;
}
.apTrainingItemContainer.current .apTrainingItem {
	border-color: var(--apColorAccent);
}
.apTrainingItem {
	display: flex;
	align-items: stretch;
}
.apTrainingItem .item {
	flex: 1 1 auto;
	padding: 0 16px;
}
.apTrainingItem ul {
	padding: 0 0 0 24px;
	margin: 0 0 32px 0;
}
.apTrainingItem ul li {
	list-style: none;
	position: relative;
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	margin-bottom: 16px;
}
.apTrainingItem ul li:before {
	position: absolute;
	content: '';
	left: -24px;
	top: 1px;
	width: 10px;
	height: 10px;
	background: var(--apColorDark);
	border-radius: 50%;
	border: 2px solid #DEDEDE;
	box-sizing: border-box;
	-webkit-box-shadow: 0px 0px 0px 2px rgba(68, 79, 96, 1);
	-moz-box-shadow: 0px 0px 0px 2px rgba(68, 79, 96, 1);
	box-shadow: 0px 0px 0px 2px rgba(68, 79, 96, 1);
}
.apTrainingItem .headline {
	border-bottom: 1px solid #DEDEDE;
	padding: 20px 0 5px 0;
	margin: 0 0 16px 0;
}
.apTrainingItem .title {
	margin: 0;
}
.apTrainingItem .btm-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
	margin: 0 -16px;
	border-top: 1px solid #DEDEDE;
	position: relative;
}
.apTrainingItem .btm-line .apInputText {
	position: relative;
	margin: 0;
}
.apTrainingItem .btm-line .apInputText input {
	padding-right: 32px;
}
.apTrainingItem .btm-line .apInputText button {
	padding: 0;
	margin: 0;
	background-color: transparent !important;
	position: absolute;
	top: 50%;
	margin-top: -22px;
	right: 8px;
	z-index: 3;
}
.apTrainingItem button {
	padding: 0 16px;
	height: 40px;
	color: var(--apColorDark);
	font-weight: 600;
	font-size: 14px;
	line-height: 160%;
	text-align: center;
	letter-spacing: 0.03em;
	transition: .2s;
}
.apTrainingItem button:hover {
	background: var(--apColorDark);
	color: var(--apColorLight) 
}
.apTrainingItem .viewComents {
	padding: 0;
	height: auto;
	background-color: transparent;
	color: var(--apColorAccent);
	font-weight: 500;
	font-size: 12px;
	line-height: 120%;
	text-decoration: underline;
}
.apTrainingItem .viewComents.floated {
	position: absolute;
	bottom: 4px;
}
.apTrainingItem .viewComents:hover {
	text-decoration: none;
	color: var(--apColorAccent);
	background-color: transparent;
}
.apTrainingItem .time {
	flex: 0 0 80px;
	max-width: 80px;
	border-right: 2px solid rgba(200, 200, 200, 0.6);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0 26px;
}
.apTrainingItem .time div {
	text-align: center;
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	text-align: center;
	letter-spacing: 0.006em;
	color: var(--apColorDark);
}
.apTrainingItem .time span {
	display: block;
	font-weight: 500;
	font-size: 11px;
}
.apCommentsSlider {
	flex: 0 0 100%;
	width: 100%;
	max-width: 100%;
	position: relative;
	display: block;
	background: #E0E2E5;
	border: 1px solid var(--apColorDark);
	border-top: 1px solid rgba(200, 200, 200, 0.4);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
	border-radius: 0px 0px 10px 10px;
	padding-top: 20px;
	margin-bottom: 53px;
}
.apTrainingItemContainer.current .apCommentsSlider {
	border-color: var(--apColorAccent);
	border-top-color: rgba(200, 200, 200, 0.4);
}
.apCommentsSlider h3 {
	margin: 0 0 0 0;
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: #444F60;
	padding: 0 60px;
}
.apCommentsSlider p {
	margin: 0;
}
.apComment>span {
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	color: #C1C1C1;
}
.apCommentsSlider .swiper-button-prev {
	left: 16px;
}
.apCommentsSlider .swiper-button-next {
	right: 16px;
}
.apCommentsSlider .swiper-slide {
	padding: 20px 60px;
	margin: 0 -60px;
	opacity: 0 !important;
}
.apCommentsSlider h3~button, .apCommentsSlider .swiper-slide>button {
	position: absolute;
	padding: 0;
	font-weight: 400;
	font-size: 15px;
	line-height: 120%;
	letter-spacing: 0.03em;
	text-decoration-line: underline;
	color: var(--apColorAccent);
	right: 60px;
	top: 0;
}
.apCommentsSlider h3~button {
	top: 16px;
}

.apCommentsSlider .swiper-slide.swiper-slide-active {
	opacity: 1 !important;
}
.apCommentsSlider .apTextarea label {
   /* background-color:;
	*/
	position: relative;
	z-index: 1;
}
.apCommentsSlider .apTextarea label:before {
	position: absolute;
	content: '';
	left: 0;
	right: 0;
	top: 0;
	bottom: 50%;
	background-color: #E0E2E5;
	z-index: -1;
}
.apComment.edit {
	padding: 12px 60px 0 60px;
}
.apComment .apTextarea {
	margin: 0 0 16px 0;
}
.apComment .apTextarea textarea {
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	min-height: 115px;
	max-height: 115px;
	min-width: 100%;
	max-width: 100%;
}
.apCommentBtmLine {
	display: flex;
	justify-content: flex-start;
	padding-bottom: 10px;
}
.apCommentBtmLine button {
	height: 40px;
	transition: .2s;
}
.apCommentBtmLine button:first-child {
	background-color: var(--apColorAccent);
	color: #fff;
	margin-right: 16px;
}
.apCommentBtmLine button svg {
	margin-right: 8px;
}
.apCommentBtmLine button svg path {
	transition: .2s;
}
.apCommentBtmLine button:first-child svg path {
	stroke: var(--apColorLight) 
}
.apCommentBtmLine button:first-child:hover {
	background-color: var(--apColorDark);
}
.apCommentBtmLine button:last-child:hover {
	color: var(--apColorAccent) 
}
.apCommentBtmLine button:last-child:hover svg path {
	stroke: var(--apColorAccent) 
}
.apSlideCounter {
	text-align: center;
	margin-bottom: 15px;
	color: var(--apColorAccent) 
}
.apComment {
	padding: 20px 60px 
}
/* .swiper-slide .apComment {
	padding: 0;
}
*/
.apTrainigPlan {
	position: fixed;
	top: 20%;
	right: 0;
	transition: .3s;
   /* overflow: hidden;
	*/
	display: flex;
	flex-wrap: nowrap;
}
.apTrainigPlan .trigger {
	height: 60px;
	color: transparent;
	font-weight: 600;
	font-size: 14px;
	line-height: 160%;
	letter-spacing: 0.03em;
	white-space: nowrap;
	transition: .2s;
	border-radius: 8px 0 0 8px;
	position: relative;
	right: calc(-100% + 40px);
	z-index: 3;
}
.apTrainigPlan .trigger svg {
	margin-right: 8px;
	transition: ease .2s;
}
.apTrainigPlan:not(.isOpen) .trigger:hover {
	color: var(--apColorDark);
	right: 0;
}
.apTrainigPlan.isOpen .trigger {
	left: 130px;
	color: var(--apColorDark);
}
.apTrainigPlan.isOpen .trigger svg {
	margin-right: 16px;
}
.apTrainigPlan .plus {
	height: 40px;
	position: absolute;
	top: 60px;
	left: 100%;
	width: 48px;
	border-radius: 8px 0 0 8px;
	opacity: 0;
	transition: ease .3s;
	background-color: var(--apColorAccent);
}
.apTrainigPlan .plus path {
	fill: var(--apColorLight) 
}
.apTrainigPlan.isOpen .plus {
	left: 130px;
	opacity: 1;
}
.apTrainigPlan .plus svg {
	transition: .2s;
}
.apTrainigPlan .plus:hover {
	background-color: var(--apColorDark);
}
.apPlanContentBox {
	width: 528px;
	max-width: 0px;
	white-space: nowrap;
	background-color: #DEDFE1;
	overflow: hidden;
	transition: .2s;
	z-index: 2;
	box-shadow: -10px 4px 41px rgba(0, 0, 0, 0.0);
	border-radius: 0px 0px 16px 16px;
	position: relative;
	overflow-y: auto;
	max-height: 80vh;
}
.apPlanContentBox .cross {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 4;
	opacity: 0;
	transition: .2s;
}
.isOpen .apPlanContentBox .cross {
	opacity: 1;
}
.isOpen .apPlanContentBox {
	max-width: 528px;
	box-shadow: -10px 4px 41px rgba(0, 0, 0, 0.15);
}
.apPlanContentBox .swiper-slide .apComment {
	white-space: pre-wrap;
}
.apTrainigPlan .apTrainingItemContainer {
	padding: 0 20px 20px;
}
.apTrainigPlan .apTrainingItemContainer .apTrainingItem:last-child {
	margin-bottom: 20px;
}
.apTrainigPlan .contentBoxHeader {
	position: relative;
	padding: 64px 20px 16px;
   /* margin-top: -53px;
	*/
}
.apTrainigPlan .apTrainingItemContainer:last-child .apTrainingItem:last-child {
	margin: 0;
}
.apTrainigPlan .weekListItem {
	padding-top: 4px;
	padding-bottom: 4px;
}
.apPersonalPage {
	font-family: 'Montserrat', sans-serif;
}
input[type="time"]::-webkit-calendar-picker-indicator {
	display: none !important;
	opacity: 0;
}
.timePicker {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: flex-end;
	margin-right: 20px;
}
.timePickerWrap, .timeList {
	display: flex;
	flex-wrap: wrap;
}
.timePickerWrap {
	justify-content: space-between;
}
.timePicker svg {
	margin-right: 5px;
}
.timePicker input {
	width: 110px;
	height: 26px;
	background: #F1F2F4;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	font-size: 16px;
	font-weight: 700;
	line-height: 120%;
	text-align: center;
	letter-spacing: 0.03em;
	color: #B7B7B7;
}
.trainingBlock .formBlock {
	width: 680px;
	height: -webkit-fill-available;
	overflow-y: auto;
}
.title.apLink:hover{
	color: var(--apColorAccent);
	cursor: pointer;
}
.timePickerWrap .apCheckBoxWithLabel {
	margin: 0;
}
.trainingAppInput {
	position: relative;
}
.trainingAppInput button {
	background-color: transparent;
	position: absolute;
	height: 40px;
	top: -8px;
	padding-top: 0;
	padding-bottom: 0;
	right: 0;
}
.trainingReordeItem {
	display: flex;
	justify-content: space-between;
	padding: 8px 0;
}
.searchMapTab .buttonGroup{
	margin-bottom: 10px;
}
.searchMapTab .buttonGroup button{
	/* height: 20px; */
	width: 140px;
}
.buttonGroup,
.formButtonGroup {
	display: flex;
}
.formButtonGroup button {
	margin-right: 16px;
}
.formButtonGroup button svg {
	margin-right: 8px;
}
.formButtonGroup button:first-child {
	background-color: var(--apColorAccent);
	color: var(--apColorLight);
}
.formButtonGroup button:first-child path {
	stroke: var(--apColorLight);
}
.trainingReordeItem button {
	background-color: transparent;
	padding: 0;
}
.trainingReordeItem .controls {
	display: flex;
}
.timePicker input:focus, .timePicker input:active {
	color: var(--apColorAccent) 
}
.timePicker input:focus~svg path, .timePicker input:active~svg path {
	stroke: var(--apColorAccent);
}
.apPersonalPage .apPersonalPageHeadline {
	padding-bottom: 16px;
	margin-bottom: 40px;
	border-bottom: 2px solid var(--apColorDark);
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.apPersonalPage .apPersonalPageHeadline button {
	color: var(--apColorDark);
	transition: .2s;
	padding: 8px 16px;
}
.apPersonalPage .apPersonalPageHeadline button:hover {
	background-color: var(--apColorAccent);
	color: var(--apColorLight) 
}
.apPersonalPage .apPersonalPageHeadline button svg {
	margin-right: 8px;
	fill: var(--apColorDark);
}
.apPersonalPage .apPersonalPageHeadline button svg path {
	transition: .2s;
}
.apPersonalPage .apPersonalPageHeadline button:hover svg {
	fill: var(--apColorLight);
}
.apPersonalPage h1 {
	padding: 0;
	margin-bottom: 0;
}
.apPersonalPage h1 span {
	display: block;
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	letter-spacing: 0.03em;
}
h1 svg{
	margin-bottom: -5px;
}
.apPersonalPageInfoContainer {
	display: flex;
	margin: 0 0 40px;
   /* justify-content: space-between;
	*/
	flex-wrap: wrap;
}
.apPersonalPageInfoContainer .avatar {
	flex: 0 0 20%;
	max-width: 20%;
	display: flex;
   /* justify-content: center;
	*/
	align-items: flex-start;
	padding: 0 8px;
	margin-bottom: 20px;
}
.apPersonalPageInfoContainer .avatar .img {
	border-radius: 50%;
	min-width: 230px;
	width: 230px;
	height: 230px;
	min-height: 230px;
	object-fit: cover;
	vertical-align: bottom;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: var(--apColorGray1);
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}
.apPersonalPageInfoContainer .about {
	flex: 0 0 66%;
	max-width: 66%;
	padding: 0 8px;
	/* margin-bottom: 64px; */
	margin-left: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.profileFlex{
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	justify-content: space-between;

}
.withFill{
	align-items: center;
    display: flex;
	margin-bottom: 5px;
}
.withFill span{
	font-weight: 700;
}
.withFill svg{
	margin-right: 5px;
}

.apPersonalPageInfoContainer .about h3, .apPersonalPageInfoContainer .about h4 {
	font-weight: 700;
	font-size: 20px;
	line-height: 160%;
	letter-spacing: 0.03em;
	color: var(--apColorDark);
	margin-bottom: 0px;
	margin-top: 0;
}
.apPersonalPageInfoContainer .about h4 {
	font-size: 15px;
	line-height: 160%;
}
.apPersonalPageInfoContainer .about p {
	margin-bottom: 20px;
}
.apPersonalPageInfoContainer .infoItem {
	flex: 0 0 41%;
	padding: 0 8px;
}
.apPersonalPageInfoContainer .infoItem h3 {
	margin: 0 0 20px;
	font-weight: 700;
	font-size: 15px;
	line-height: 160%;
	letter-spacing: 0.03em;
}
.apPersonalPageInfoContainer .infoItem ul {
	padding: 0;
	display: flex;
	flex-wrap: wrap;
}
.infoItem.withSelect{
	flex: 0 0 30%;
}
.apPersonalPageInfoContainer .infoItem.withSelect h3 {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.infoItem.contacts{
	flex: 1 0 12%;
	margin: 0 auto;
}
.profileViewMode {
	margin: 0;
}
.apPersonalPageInfoContainer .infoItem ul div{
	padding: 0 5px 0 0;
	width: 50%;
}
.apPersonalPageInfoContainer .infoItem ul li, .apPersonalPageInfoContainer .infoItem ul div li {
	align-items: center;
	box-sizing: border-box;
	flex: 0 0 50%;
	list-style: none;
   /* max-width: 50%;
	*/
	font-size: 14px;
	padding-left: 34px;
	position: relative;
	line-height: 1;
	margin-bottom: 12px;
	letter-spacing: 0.03em;
	font-weight: 700;
	font-size: 15px;
	line-height: 160%;
}
.apPersonalPageInfoContainer .defBlock {
	flex: 0 0 66%;
	max-width: 66%;
}
.apPersonalPageInfoContainer .infoItem ul li span {
	flex: 0 0 100%;
	max-width: 100%;
	font-weight: 400;
}
.apPersonalPageInfoContainer .infoItem.contacts ul {
	align-items: flex-start;
}
.apPersonalPageInfoContainer .infoItem.contacts ul li {
	flex: 0 0 100%;
	max-width: 100%;
	justify-content: flex-start;
	text-align: left;
	align-items: flex-start;
}
.apPersonalPageInfoContainer .infoItem ul li svg {
	width: 24px;
	max-width: 24px;
	position: absolute;
	left: 0;
	top: 0;
	fill: var(--apColorDark);
}
.apPersonalPageInfoContainer .infoItem ul li.withFill svg path, .apPersonalPageInfoContainer .infoItem ul li.withFill svg circle {
	stroke: transparent;
	fill: var(--apColorDark) 
}
.apPersonalPageInfoContainer .infoItem ul li.default svg path, .apPersonalPageInfoContainer .infoItem ul li.default svg circle {
	stroke: initial;
	fill: var(--apColorDark);
}
.apPersonalPageInfoContainer .infoItem ul li .apPersonalPageInfoContainer .contacts ul {
	padding: 0;
	margin: 0;
}
.apPersonalPageInfoContainer .contacts li {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 10px;
}
.apPersonalPageInfoContainer .contacts span {
	font-weight: 600;
	font-size: 11px;
	line-height: 120%;
}
.apPersonalPageInfoContainer .contacts a {
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	text-align: center;
	letter-spacing: 0.03em;
	text-decoration-line: underline;
	color: var(--apColorAccent);
	transition: .2s;
}
.apPersonalPageInfoContainer .contacts a:hover {
	text-decoration: none;
	color: var(--apColorDark) 
}
.apFcEvents h3 {
	display: flex;
}
.apUserFcPage h1 {
	margin: 0;
}
.apUserFcPage h2 {
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorDark);
	margin: 60px 0 0px 0;
	padding: 0 0 22px 0;
	border-bottom: 1px solid #DEDFE1;
}
.apUserFcPage .headline {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 75px 0 35px;
	border-bottom: 2px solid var(--apColorDark);
}
.flex-container {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	align-items: flex-end;
	width: 100%;
}
.flex-container .apSearch{
	flex: 0 ;
}
.apUserFcPage .headline .addBtn {
	background-color: var(--apColorDark);
	color: var(--apColorLight);
	height: 40px;
	margin-right: 8px;
}
.apUserFcPage .headline .addBtn:hover {
	background-color: var(--apColorAccent);
}
.apUserFcPage .filter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0 24px 0;
	border-bottom: 2px solid var(--apColorDark);
}
.apUserFcPage .filter ul {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 0;
	margin: 0 -8px;
}
.apUserFcPage .filter li {
	list-style: none;
	padding: 0 8px;
}
.apUserFcPage .filter li button {
	padding: 8px, 16px, 8px, 8px;
	font-weight: 600;
	font-size: 14px;
	line-height: 160%;
	letter-spacing: 0.03em;
	color: var(--apColorDark);
	height: 40px;
	transition: .2s;
}
.apUserFcPage .filter li button:hover {
	opacity: .9;
}
.apUserFcPage .filter li button svg {
	margin-right: 8px;
}
.apUserFcPage .filter li button.active {
	background-color: var(--apColorDark);
	color: var(--apColorLight);
}
.swiper-slide .apTileContainer {
	flex: 0 0 100%;
	max-width: 100%;
}
.apUserFcPage .filter li.rocket button svg path, .apUserFcPage .filter li.rocket button svg circle {
	stroke: var(--apColorDark);
}
.apUserFcPage .filter li.rocket button.active svg path, .apUserFcPage .filter li.rocket button.active svg circle {
	stroke: #fff;
}
.apUserFcPage .filter li.users button.active svg path, .apUserFcPage .filter li.users button.active svg circle {
	fill: #fff;
}
.apSearch {
	display: flex;
	align-items: flex-end;
	height: 47px;
}
.apSearch .apInputTextIcon {
	margin: 0 12px 0 0;
	width: 288px;
}
.apSearch button {
	transition: .2s;
	padding: 0;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.apSearch button svg path {
	stroke: var(--apColorDark);
	transition: .2s;
}
.apSearch button.active {
	background-color: var(--apColorDark);
}
.apSearch button.active svg path {
	stroke: var(--apColorLight);
}
.apSearchButton {
	height: 40px;
}
.apUserPanel {
	display: flex;
}
.apUserPanel .text {
	padding-right: 12px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}
.apUserPanel .name {
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorDark);
	border: none;
	background-color: transparent;
	padding: 0;
	text-align: right;
}
.apUserPanel .text a {
	text-align: right;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
	color: var(--apColorDark);
	transition: .2s;
}
.apUserPanel .text a:hover {
	color: var(--apColorAccent);
}
.apUserPanel .text .plug {
	width: 100%;
	box-sizing: border-box;
	text-align: center;
	padding: 3px 8px;
	background-color: var(--apColorGray1);
	border-radius: 4px;
	margin-top: 4px;
}
.apUserPanel .img-wrp {
	flex: 0 0 48px;
	max-width: 48px;
	height: 48px;
	border-radius: 50%;
	border: 1px solid rgba(68, 79, 96, 0.1);
	cursor: pointer;
}
.apUserPanel .img-wrp img {
	width: 48px;
	height: 48px;
	vertical-align: bottom;
	object-fit: cover;
	border-radius: 50%;
}
.apFcItemTileContainer {
	padding: 22px 0;
	border-bottom: 1px solid #DEDFE1;
}
.apFcItemTileContainer:hover{
	cursor: pointer;
}
.apFcItemTileContainer:last-child {
	border-bottom: none;
}
.apFcItemTile {
	display: flex;
}
.apFcItemTile .col {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0 16px;
	font-family: 'Montserrat', sans-serif;
}
.apFcItemTile .contect-col.col {
	min-width: 160px;
}
.col {
	font-size: 12px;
}
.apFcItemTile .col a {
	text-decoration: none;
	color: var(--apColorDark);
	transition: .2s;
}
.apFcItemTile .col a:hover {
	color: var(--apColorAccent);
}
.apFcItemTile .col span {
	display: block;
	font-style: normal;
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	letter-spacing: 0.006em;
}
.apFcItemTile .col:before {
	position: absolute;
	content: '';
	right: 0;
	top: 50%;
	translate: 0 -50% 0;
	height: 30px;
	width: 1px;
	background-color: #DEDFE1;
}
.apFcItemTile .col:first-child:before, .apFcItemTile .col:last-child:before {
	content: none;
}
.apFcItemTile .img-wrp {
	padding: 0 22px;
}
.apFcItemTile .img-wrp .img {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	object-fit: cover;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.apFcItemTile .title-col {
	min-width: 296px;
	max-width: 296px;
	box-sizing: border-box;
	padding: 0;
}
.apFcItemTile .address-col {
	width: 188px;
}
.apFcItemTile .status-col {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1 1 auto;
}
.apFcItemTile .status {
	flex: 0 0 132px;
	max-width: 132px;
	border-radius: 60px;
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	color: var(--apColorLight);
	background-color: var(--apColorAccent);
	line-height: 20px;
	text-align: center;
}
.apFcItemTile .status.moderator {
	background-color: var(--apColorBrandBG4);
}
.apFcItemTile .status.feedback {
	background-color: var(--apColorBrandLogo3);
}
.apFcItemTile .status.restricted {
	background-color: var(--apColorError);
}
.apFcItemTile button {
	padding: 0;
	background-color: transparent;
	transition: .2s;
	border-radius: 0;
	margin: 0 0 0 26px;
}
.apFcItemTile button:hover {
	opacity: .8;
}
/*MAIN*/
.main-section {
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 40px;
}
.main-section .apMapTileContainer {
	flex: none;
	max-width: 100%;
	padding: 0;
	margin-bottom: 0;
	min-width: 300px;
}
.main-section .headline {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* padding-bottom: 20px; */
	/* border-bottom: 2px solid var(--apColorDark); */
	margin-bottom: 20px;
}
.main-section .headline.withTitle{
	padding-bottom: 18px;
}
.main-section .headline h2 {
	margin: 0;
	font-weight: 700;
	font-size: 32px;
	line-height: 120%;
	letter-spacing: 0.006em;
}
.main-section .headline button {
	background-color: transparent;
	border-radius: 0;
	padding: 0;
   /* text-decoration: underline;
	*/
	font-weight: 400;
	color: var(--apColorAccent);
}
.main-section .headline button:hover {
	text-decoration: underline;
}
.main-section .swiper .swiper-pagination {
	padding: 32px 0 4px 0;
	bottom: 0;
}
.main-section .swiper .swiper-pagination .swiper-pagination-bullet {
	margin-bottom: 0;
}
.main-section .swiper-wrapper {
	padding-bottom: 32px;
}
.main-hero {
	display: flex;
	align-items: center;
	margin: 0 -12px;
	padding: 40px 0;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
}
.main-hero .text {
	flex: 0 0 50%;
	max-width: 50%;
	padding: 0 12px;
}
.main-hero .welcomeBack {
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorDark);
}
.main-hero .welcomeBack .name {
	color: var(--apColorAccent);
}
.name.apName{
	color: var(--apColorAccent);
}
.main-hero h1 {
	font-weight: 700;
	font-size: 48px;
	line-height: 120%;
	letter-spacing: 0.006em;
	text-transform: uppercase;
	margin: 80px 0 40px;
}
.main-hero .location {
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	letter-spacing: 0.006em;
}
.main-hero .location span {
	color: var(--apColorAccent);
}
.main-hero .img-wrp {
	flex: 0 0 50%;
	max-width: 50%;
	padding: 0 12px;
}
.main-hero .img-wrp img {
	width: 100%;
	height: auto;
	vertical-align: bottom;
}
.main-clubs .flex-wrp {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	margin: 0 -10px;
}
.main-clubs .clubItemWrap {
	flex: 0 0 25%;
	max-width: 25%;
	padding: 0 10px;
	margin-bottom: 20px;
}
.main-clubs .clubItem {
	background-color: #fff;
	padding: 16px 5px;
	display: flex;
	align-items: center;
	border: 1px solid #DEDFE1;
	border-radius: 10px;
	cursor: pointer;
	margin-right: 10px;
}
.main-clubs .clubItem .img-wrp {
	flex: 0 0 60px;
	max-width: 60px;
}
.main-clubs .clubItem .img-wrp img {
	width: 60px;
	height: 60px;
	object-fit: cover;
}
.clubItem .text{
}
.main-clubs .clubItem .text {
   /* flex: 1 1 auto;
	*/
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 10px;
	box-sizing: border-box;
	height: 55px;
	width: 100%;
}
.main-clubs .clubItem .text:hover {
	color: var(--apColorAccent);
}
.main-clubs .clubItem .text h4 {
	margin: 0;
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	letter-spacing: 0.006em;
}
.clubItem .text span {
	font-weight: 400;
	font-size: 11px;
	line-height: 120%;
	color: #444F60;
	margin: 0 0 0px 0;
}
.main-news .apTileContainer {
	flex: 0 0 100%;
	max-width: 100%;
}
.clubItem {
	border-radius: 10px;
	width: 24%;
	max-width: 24%;
	min-width: min-content;
}
.main-clubs .clubItem .img-wrp {
	border-radius: 10px;
	width: 60px;
	height: 60px;
}
.apEventTile {
	display: flex;
	background: #FEFEFE;
	border: 1px solid #DEDFE1;
	border-radius: 10px;
	padding: 10px;
	align-items: stretch;
}
.apEventTile .img-wrp {
	flex: 0 0 136px;
	max-width: 136px;
	height: 126px;
}
.apEventTile img {
	width: auto;
	object-fit: cover;
	border-radius: 8px;
	vertical-align: bottom;
	height: 126px;
	width: 126px;
}
.apEventTile h4 {
	font-weight: 700;
	font-size: 12px;
	line-height: 120%;
	letter-spacing: 0.006em;
	color: var(--apColorDark);
	margin: 0 0 8px 0;
}
.apEventTile p {
	font-weight: 400;
	font-size: 11px;
	line-height: 160%;
	letter-spacing: 0.03em;
}
.apEventTile .text {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-between;
	flex: 1 1 auto;
	max-width: 100%;
}
.apEventTile button {
	height: 40px;
	background: #FB4A59;
	border-radius: 10px;
	justify-content: center;
	border: none;
	width: 100%;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
	letter-spacing: 0.006em;
	cursor: pointer;
}
.apEventTile button:hover {
	background-color: var(--apColorDark);
}
.user-tabbar {
	display: flex;
	align-items: center;
	position: relative;
	border-bottom: 2px solid var(--apColorDark);
	padding-bottom: 24px;
	column-gap: 24px;
}
.apStatusItem {
	display: inline-flex;
	align-self: center;
	justify-content: center;
	padding: 3px 9px;
	width: 100%;
	max-width: 136px;
	border-radius: 60px;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	line-height: 120%;
	text-align: center;
	color: var(--apColorLight);
	background-color: var(--apColorGray1);
}
.apStatusItem.gray {
	color: var(--apColorDark);
}
.apStatusItem.red {
	background-color: var(--apColorBrandLogo3);
}
.apStatusItem.orange {
	background-color: var(--apColorAttention);
}
.apStatusItem.blue {
	background-color: var(--apColorAccent);
}
.apStatusItem.green {
	background-color: var(--apColorBrandLogo2);
}
.apStatusItem.violet {
	background-color: var(--apColorBrandBG4);
}
.apStatusItem.black {
	background-color: var(--apColorDark);
}
.apTimer {
	display: flex;
	justify-content: space-between;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 24px;
	text-align: center;
	color: var(--apColorAccent);
	align-items: center;
	background-color: var(--apColorLight);
	padding: 8px 12px 20px;
	border-radius: 8px;
}
.apTimerItem {
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
	text-align: center;
	position: relative;
	min-width: 43px;
}
.apTimerItem span {
	display: block;
	margin-bottom: -12px;
	text-align: center;
	position: relative;
	font-weight: 500;
	font-size: 11px;
	line-height: 12px;
	color: var(--apColorDark) 
}
@keyframes showItem {
	0% {
		opacity: 0 
   }
	100% {
		opacity: 1;
   }
}
.apEmptyCategory {
	width: 100%;
	height: 80px;
	border-radius: 12px;
	background-color: var(--apColorGray1);
	margin: 22px 0 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	opacity: 0;
	animation: showItem .2s ease-in forwards;
}
@keyframes spinner {
	0% {
		transform: rotate(0deg);
   }
	100% {
		transform: rotate(360deg);
   }
}
@keyframes apSpinnerAnimation {
	0% {
		transform: rotate(0deg);
   }
	100% {
		transform: rotate(360deg);
   }
}
/* Только desktop */
@media (min-width: 1280px) {
	h1 {
		margin-top: 40px;
		margin-bottom: 20px;
		vertical-align: middle;
   }
	h2, h3 {
		margin-top: 40px;
		margin-bottom: 20px;
   }
	h4 {
		margin-top: 40px;
		margin-bottom: 16px;
   }
}
/* Только desktop */
@media (max-width: 1240px) {
	.apPersonalPageInfoContainer .avatar {
		flex: 0 0 25%;
		max-width: 25%;
		display: flex;
		justify-content: center;
   }
	.apPersonalPageInfoContainer .contacts {
		flex: 0 0 25%;
		max-width: 25%;
   }
	.apPersonalPageInfoContainer .info {
		flex: 0 0 50%;
		max-width: 50%;
   }
	.apPersonalPageInfoContainer .avatar img {
		flex: 0 0 120px;
		max-width: 120px;
		height: 120px;
   }
	.user-tabbar {
		flex-direction: column;
		margin-bottom: 22px;
   }
	.apItineraryAdmin{
		flex-direction: column;
   }
	.apAdmitPointList .headline{
		margin-top: 20px;
   }
	.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
		width: 100%!important;
   }
	.leaflet-control.leaflet-bar.headline.apInputText{
		max-width: 100%;
		min-width: 100%;
   }
	.apAdmitPointList {
		width: 42%;
		margin-left: 0;
   }
	.apAdmitPointList.apTableList{
		width: 100%;
		margin-left: 0;
   }
	.apFcEventsAdmitPoints.apTableList{
		width: 100%;
   }
	.leaflet-center {
		left: 14%;
   }
	.user-tabbar button {
		margin: 0 0 8px 0;
		flex: 0 0 100%;
		max-width: 100%;
		width: 100%;
		box-sizing: border-box;
   }
	.mainCommonBlock {
		width: 100%;
		box-sizing: border-box;
		padding: 0 16px;
		margin-left: 0;
		margin-right: 0;
   }
	.apFcInfoBlock {
		margin: 0;
   }
	.apFcInfoBlock .info {
		flex: 0 0 30%;
		max-width: 30%;
		padding: 0 16px;
		margin: 0;
		/* background: url(); */
   }
	.apFcInfoBlock .text {
		flex: 0 0 65%;
		max-width: 65%;
		padding: 0 16px;
		margin: 0;
   }
	.apUserTile {
		flex: 0 0 33.3%;
		max-width: 33.3%;
   }
	.apFcItemTile .col {
		font-size: 12px;
		line-height: 1.2;
		padding: 0 8px;
   }
	.apFcItemTile .title-col {
		min-width: 0;
		width: 100%;
		max-width: 160px;
   }
	.apFcItemTile .address-col {
		width: auto;
   }
}
/* Планшеты, вертикальный и горизонтальный */
@media (min-width: 640px) and (max-width: 1279px) {
	h1 {
		margin-top: 64px;
		margin-bottom: 32px;
   }
	h2 {
		margin-top: 48px;
		margin-bottom: 24px;
   }
	h3 {
		margin-top: 48px;
		margin-bottom: 16px;
   }
	h4 {
		margin-top: 40px;
		margin-bottom: 16px;
   }
	.apFooter {
		margin-top: 64px;
   }
}
@media (max-width: 1160px){
	.petalLeft2{
		display: block;
	}
	.petalLeft1{
		display: none;
	}
   .storeHeaderActive{
	   text-align: center;
   }
	.storeHeader{
		max-width: 100%;
		text-align: center;
   }
	.storeText{
		margin-bottom: 35px;
		align-items: center;
   }
	.storeImage{
		align-self:flex-start;
		min-width: 450px;
		max-width: 450px;
		min-height: 390px;
		max-height: 390px;
   }
}
@media (max-width: 460px){
	.storeImage{
		min-width: 320px;
		max-width: 320px;
		min-height: 320px;
		max-height: 320px;
   }
}
@media (min-width: 941px) and (max-width: 1160px) {
	.groundPropertiesAddressBlock {
		border-right: none;
   }
}
@media (max-width: 1199px) {
	.justifyFlex{
		display: flex;
		justify-content: space-evenly;
		width: 100%;
	}
	.petalStartPageBlock{
		flex-wrap: wrap;
	}
	.petalLeft1{
		display: none;
	}
	.petalLeft2{
		display: block;
	}
	.addPointForm .flex-wrp {
		flex-wrap: wrap;
   }
	.addPointForm .flex-wrp .input-wrp {
		flex: 0 0 25% !important;
		max-width: 25% !important;
   }
	.addPointForm .flex-wrp .input-wrp:nth-child(2) {
		flex: 0 0 100% !important;
		max-width: 100% !important;
		order: 3;
   }
	.startSection .startSectionImage {
		display: none;
   }
	.apTile {
		height: auto;
   }
	.apPointList .listContainer .item>div:last-child {
		text-align: end;
		padding-right: 16px;
   }
	.apPointList .listContainer .item {
		flex-wrap: wrap;
   }
	.apTile img, .apTile span {
		min-height: 0;
   }
	.cardsList {
		justify-content: space-evenly;
   }
	.apNewsCard {
		margin-bottom: 20px;
		min-width: 480px;
   }
	.apNewsCard div:nth-child(1) {
		height: 300px;
   }
	.apNewsCard:nth-child(3) {
		display: none;
   }
	.forthSection {
		flex-direction: column-reverse;
   }
	.forthSection .videoPlayerContainer {
		width: 100%;
   }
	.forthSectionText {
		width: 100%;
   }
	.calendar {
		margin-top: 32px;
		align-items: center;
   }
	.calendar aside {
		padding: 0;
   }
	.calendar .day, .calendar td {
		height: 42px;
   }
}
@media (max-width: 1249px) {
	.apColumn {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0;
   }
	.apTrainingsHistoryContainer {
		margin-left: 0;
		margin-right: 0;
		flex-wrap: wrap;
   }
	.apNewsCard {
		min-width: 400px;
   }
	.apMapTileContainer {
		flex: 0 0 33.3%;
		max-width: 33.3%;
   }
	.apNewsCard div:nth-child(1) {
		height: 250px;
   }
	.apTileContainer {
		flex: 0 0 50%;
		max-width: 50%;
   }
	.apPersonalPage.isFull .apPersonalPageInfoContainer .infoItem {
		flex: 0 0 50%;
		max-width: 50%;
   }
	.apPersonalPage.isFull .apPersonalPageInfoContainer .infoItem:last-child {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.apPersonalPage.isFull .apPersonalPageInfoContainer .infoItem.withSelect h3 {
		justify-content: flex-start;
   }
	.profileViewMode {
		margin-left: 16px;
   }
}
@media (max-width: 999px) {
	.sortAndFilterBlock .filterBlock{
		flex-wrap: wrap;
		width: fit-content;
   }
	.apColumn {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0;
   }
	.apTrainingsHistoryContainer {
		margin-left: 0;
		margin-right: 0;
		flex-wrap: wrap;
   }
	.apNewsCard {
		min-width: 400px;
   }
	.apMapTileContainer {
		flex: 0 0 33.3%;
		max-width: 33.3%;
   }
	.apNewsCard div:nth-child(1) {
		height: 250px;
   }
	.apTileContainer {
		flex: 0 0 50%;
		max-width: 50%;
   }
	.apPersonalPage.isFull .apPersonalPageInfoContainer .infoItem {
		flex: 0 0 50%;
		max-width: 50%;
   }
	.apPersonalPage.isFull .apPersonalPageInfoContainer .infoItem:last-child {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.apPersonalPage.isFull .apPersonalPageInfoContainer .infoItem.withSelect h3 {
		justify-content: flex-start;
   }
	.profileViewMode {
		margin-left: 16px;
   }
}
@media (max-width: 991px) {
	.trackInfoRow{
		flex-wrap: wrap;
	}
	.apPersonalPage:not(.isFull) .apPersonalPageInfoContainer .avatar {
		flex: 0 0 60%;
		max-width: 60%;
		margin-bottom: 64px;
   }
	.swiper .swiper-pagination{
		flex-wrap: nowrap;
   }
	.apPersonalPage:not(.isFull) .apPersonalPageInfoContainer {
		justify-content: center;
   }
	.apPersonalPage:not(.isFull) .apPersonalPageInfoContainer .infoItem {
		flex: 0 0 50%;
		max-width: 50%;
   }
	.apFcEventHeader .aside {
		flex: 0 0 100%;
		max-width: 100%;
		order: -1;
		margin-bottom: 32px;
   }
	.apConferenceBlock {
		display: block;
   }
	.apConferenceBlock>div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
   }
	.apConferenceBlock p {
	   /* position: static;
		translate: none;
		*/
		text-align: left;
   }
	.main-clubs .clubItemWrap {
		flex: 0 0 33.3%;
		max-width: 33.3%;
   }
	.main-hero h1 {
		font-size: 42px;
		margin: 32px 0 16px;
   }
	.apFcAdmitPointItem {
		flex-wrap: wrap;
   }
	.apAdmitPointList.apTableList .apFcEventsAdmitPoints .apFcAdmitPointItem {
		flex-wrap: nowrap;
   }
	.apFcAdmitPointItem .info {
	   flex: 0 0 75%;
		
		/* margin-bottom: 20px; */
		flex-wrap: wrap;
   }
	.apPointList .listContainer .item>div:nth-child(2) {
		min-width: 0;
   }
	.apPointList .listContainer .item>div:nth-child(3) {
		flex: 0 0 50%;
		max-width: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: row-reverse;
   }
	.apPointList .listContainer .item>div:nth-child(2), .apPointList .listContainer .item>div:first-child {
		margin-bottom: 16px;
   }
	.apPointList .listContainer .item>div:nth-child(3) span {
		margin-right: 6px;
   }
	.apItinerary {
		flex-wrap: wrap;
   }
	.apItinerary .map {
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 20px;
   }
	.apPointList {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.apFcEventHeader {
		flex-wrap: wrap;
   }
	.apFcEventHeader .map {
		flex: 0 0 100%;
		max-width: 100%;
		margin-top: 20px;
   }
	.apFCSectionItem, .apFCSectionItem.apFcSectionSmItem {
		flex: 0 0 50%;
		max-width: 50%;
   }
	.apFCSectionItem.apFcSectionLgItem {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.videoCardDropAreaAndHint {
		align-items: center;
   }
	.apFcItemWrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px;
   }
	.apFcItemTileContainer {
		flex: 0 0 33.3%;
		max-width: 33.3%;
		padding: 0 5px;
		margin-bottom: 10px;
		border: none;
   }
	.apFcItemTile {
		border: 1px solid #DEDFE1;
		padding: 12px 5px;
		border-radius: 4px;
		background-color: var(--apColorLight);
   }
	.apUserFcPage h2 {
		flex: 0 0 100%;
		max-width: 100%;
		border: none;
		padding: 0 5px;
		margin: 24px 0 24px 0;
   }
	.apFcItemTile .img-wrp {
		padding: 0 10px 0 0;
   }
	.apFcItemTile .title {
		font-weight: 700;
		font-size: 15px;
		line-height: 120%;
   }
	.apFcItemTile .slogan {
		font-weight: 400;
		font-size: 11px;
		line-height: 120%;
		margin: 2px 0 8px;
   }
	.apFcItemTile .text {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
   }
	.apFcItemTile .status {
		flex: inherit;
		width: 132px;
   }
	.apFcItemTileInner {
		background: #EDEDF0;
		border-width: 0px 1px 1px 1px;
		border-style: solid;
		border-color: #DEDFE1;
		border-radius: 0px 0px 4px 4px;
		display: flex;
		flex-wrap: wrap;
		padding-top: 16px;
   }
   .infoPage .infoSide{
	   display: block;
	   position: absolute;
	   z-index: 100;
	   height: 30px;
	   background: gray;
	   width: 30px;
	   right:-2px;
	   font-size: 20px;
	   color: white;
	   text-align: center;
	   top: 0;
	   border-radius: 5px;
	   border-top-left-radius: 0px;
	   border-bottom-left-radius: 0px;
	   padding: 5px;

   }
   .infoPageSidebar {
	position: fixed;
	z-index: 100000;
	background: white;
	max-width: 237px;
	max-height: 300px;
	overflow-y: auto;
	padding: 10px 30px 10px 20px;
	border-radius: 5px;
	left: -20px;
	transition: left 0.3s ease; /* Add transition for left property */
  }
  .infoPageSidebar.mobCheck {
	left: -218px;
  }
	.apFcItemTileInner .item {
		display: flex;
		align-items: center;
		flex: 0 0 50%;
		max-width: 50%;
		padding: 0 8px 0 16px;
		box-sizing: border-box;
   }
	.apFcItemTileInner .item>span {
		font-weight: 700;
		font-size: 15px;
		line-height: 120%;
   }
	.apFcItemTileInner .item span span {
		display: block;
		font-weight: 400;
		font-size: 11px;
		line-height: 120%;
   }
	.apFcItemTileInner .item svg {
		width: 24px;
		height: 24px;
		min-width: 24px;
		min-height: 24px;
		max-width: 24px;
		max-height: 24px;
		margin: 0 6px 0 0;
   }
	.apFcItemTileInner .btm-line {
		flex: 0 0 100%;
		max-width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 16px 0;
   }
	.apFcItemTileInner .btm-line button {
		font-weight: 400;
		font-size: 15px;
		line-height: 160%;
		letter-spacing: 0.03em;
		text-decoration-line: underline;
		color: #1BA8B2;
		background-color: transparent;
		padding: 0;
		border-radius: 0;
   }
	.apFcHeader {
		padding: 32px 0;
		flex-direction: column;
		align-items: center;
		text-align: center;
   }
	.apFcEvent.empty {
		padding: 12px 24px;
		line-height: 1;
		min-height: 48px;
   }
	.apFcContacts {
		margin: 16px 0;
   }
	.apTabbar, .apTabbar nav {
		flex-wrap: wrap;
   }
	.apUserTile {
		flex: 0 0 50%;
		max-width: 50%;
   }
	.calendar aside {
		display: none;
   }
}
@media (max-width: 900px) {
	.profileFlex{
		gap: 5px;
	}
}
@media (max-width: 940px) {

	.startPageItem{
		min-width: 340px;
		max-width: 340px;
	}
	.mobileMenu{
		display: flex;
		width: 102%;
		height: 100%;
		position: fixed;
		/* background-color: rgba(0, 0, 0, 0.2); */
		top: 0;
		z-index: 10000;
		margin-left: -24px;
	}
		.mobileMenuFull{
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 0;
		width: 100%;
		z-index: 10001;
		background-color: var(--apColorDark);
	}
	.mobileHeaderBlock{
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		height: 50px;
		width: 370px;
		margin: 0 auto;
	}
	.mobileHeaderBlock h2{
		margin: 0px 0px;
		font-size: 15px;
		font-weight: 700;
		padding-top: 8px;
		color: white;
	}
	.mobileSpan{
		font-weight: 500;
		font-size: 8px;
		color: white;
		margin-top: -5px;
	}
	.mobileHeaderBlock .mobileLogo{
		min-width: 30px;
		min-height: 30px;
		max-height: 30px;
		background-repeat: no-repeat;		
	}
	.brockenLine{
		width: 90%;
		border-bottom: 1px solid var(--apColorDarkGray2);
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
	}
	.mobileCloseButton{
		padding: 8px 0px;
		height: 26px;
		border-radius: 27px;
		width: 128px;
		background:var(--apColorBrandLogo1);
		margin:0 0px;
		color: white;
		justify-content:center;
		text-transform: uppercase;

	}
	.mobileButtonMenu{
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 30px;
		justify-content: space-between;
	}
	.closeMobile{
		text-align: center;
		font-size: 16px;
		color: white;
	}
	.mobileButtonMenu .storeButton{
		margin-right: 20px;
	}
	.apPersonalPage .apPersonalPageHeadline {
		flex-wrap: wrap;
   }
	.apPersonalPage .apPersonalPageHeadline h1 {
		flex: 0 0 100%;
		max-width: 100%;
		text-align: center;
		margin-bottom: 32px;
   }
	.parkInfoOther {
		flex-wrap: wrap;
   }
	.parkInfoOther>div:first-child {
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 24px;
   }
	.parkInfoOther>div:last-child {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.groundPropertiesNameBlock {
		border-right: none;
   }
	.groundPropertiesPhotosBlock {
		border-left: none;
   }
	.rightSide {
		display: none;
   }
	.authContainer {
		justify-content: center;
		width: 95%;
		padding-top: 8px;
   }
	.apNewsCard {
		min-width: 360px;
   }
	.apNewsCard div:nth-child(1) {
		height: 225px;
   }
}
@media (max-width: 775px) {
	.apAdmitPointList.apTableList .apInputText{
		min-width: 80px;
	}
	
	.motivationBlock{
		flex-direction: column;
		padding: 30px 20px;
		align-items: center;
	}
	.trackColumn{
		max-width: 100%;
		flex: 0 0 100%;
		padding-right: 0px;
		border: none;
	}
	.groundPropertiesPhotosBlock.groundPropertiesPhotosBlockTrack{
		flex-wrap: wrap;
	}
	.storeSection{
		flex-direction: column;
   }
   .storeImage{margin: 0 auto;}
	.petalLeft{
		min-width: 240px;
	}
	body{
		overflow-x: hidden;
   }
	.apTrainingItem {
		flex-wrap: wrap;
		white-space: pre-wrap;
   }
	.trainingBlock .formBlock{
		z-index: 100001;
		width: 100%;
		box-sizing: border-box;
   }
	.apTrainigPlan{
		top: 32px;
   }
	.apTrainigPlan.isOpen{
		width: 470px;
		z-index: 999;
   }

   .main-hero .img-wrp{
	   display: none;
   }
   .main-hero .img-wrp.img-wrp-mob{
	   display: block;
	   max-width: 100%;
   }
   .main-hero .primaryButtonIconTextDefault{
	   margin: 0 auto;
   }

	.apPersonalPage .apPersonalPageHeadline button{
		margin-bottom: 8px;
   }
	.apTrainingItem .time {
		flex-direction: row;
		text-align: left;
		flex: 0 0 100%;
		max-width: 100%;
		border-bottom: 2px solid rgba(200, 200, 200, 0.6);
		border-right: none;
		padding: 16px;
   }
	.apTrainingItem .time div{
		text-align: left;
   }
	.profileWorkPositionColumn, .profileOwnRegionColumn, .profilePersonalDataColumn {
		padding: 0;
		border: none;
   }
	.profileDetailedInfoRow, .profileWorkPositionColumn, .profileOwnRegionColumn{
		max-height: fit-content;
		margin: 0 0 20px;
		height: fit-content;
    	min-height: fit-content;
   }
	.profilePersonalDataColumn .apInputText, .profilePersonalDataColumn .apDropDownList, .profileWorkPositionColumn, .profileOwnRegionColumn {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.apPersonalPage.isFull .apPersonalPageInfoContainer .infoItem, .apPersonalPageInfoContainer .about, .apPersonalPage:not(.isFull) .apPersonalPageInfoContainer .infoItem {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.apConferenceBlock p {
		font-size: 14px;
   }
	.sportEventsBlockImage {
		width: 100%;
		height: auto;
		aspect-ratio: 16/10;
   }
	.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
		width: 100%;
		aspect-ratio: 16/10;
   }
	.headerWithButtonsHeaderBlock.zahody h1 {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		display: flex;
		width: 100%;
   }
	.sportEventsLine {
		flex-direction: column;
   }
	.sportEventsLineItem {
		margin: 0 0 16px 0;
		padding: 16px 0;
		border-right: none;
		border-bottom: 1px dashed var(--apColorBrandBG6);
		width: 100%;
   }
	.headerWithButtonsHeaderBlock.zahody h1 .headerSideText {
		margin: 0;
		padding: 16px 0 0 0;
		border: none;
		text-align: center;
		float: none;
		width: auto;
   }
	.sportEvents {
		flex-wrap: wrap;
   }
	.sportEventsBlock {
		flex: 0 0 100%;
		max-width: 100%;
		padding-right: 0;
		margin-bottom: 64px;
   }
	.sportEventsListBlock {
		flex: 0 0 100%;
		max-width: 100%;
		padding-left: 0;
		border: none;
   }
	.apPersonalPage .apPersonalPageHeadline button {
		padding: 8px;
   }
	.apUserButtonWrap {
		flex: 0 0 100%;
		max-width: 100%;
		box-sizing: border-box;
		justify-content: center;
		flex-wrap: wrap;
   }
	.apPersonalPage .apPersonalPageHeadline button svg {
		margin: 0;
   }
	.apPersonalPageInfoContainer {
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 0 16px 0;
   }
	.apPersonalPageInfoContainer .info, .apPersonalPageInfoContainer .avatar, .apPersonalPageInfoContainer .contacts {
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 16px;
		padding: 0;
   }
	.main-clubs .clubItemWrap {
		flex: 0 0 50%;
		max-width: 50%;
   }
	.main-hero {
		flex-wrap: wrap;
		justify-content: center;
   }
	.main-hero .img-wrp {
		flex: 0 0 300px;
		max-width: 300px;
   }
	.main-hero .text {
		flex: 0 0 100%;
		max-width: 100%;
		text-align: center;
		/* margin-bottom: 32px; */
   }
	.apAdmitPointList .headline {
		flex-direction: column;
		align-items: center;
		text-align: center;
   }
	.addPointForm .flex-wrp .input-wrp {
		flex: 0 0 100% !important;
		max-width: 100% !important;
   }
	.addPointForm .flex-wrp .input-wrp:nth-child(2) {
		order: initial;
   }
	.apAdmitPointList .headline button {
		margin-top: 20px;
   }
	.apAdmitPointList .title {
		padding-left: 0;
		margin: 20px 0;
		flex: 0 0 100%;
		max-width: 100%;
		text-align: center;
   }
	.apFcAdmitPointItem .info {
	   /* justify-content: center;
		*/
   }
	.apFcAdmitPointItem .info .date:before {
		content: none;
   }
	.apAdmitPointList .title:before {
		content: none;
   }
	.apPointList .listContainer .item {
		flex-wrap: wrap;
   }
	.apPointList .listContainer .item>div:nth-child(2) {
		flex: 0 0 calc(100% - 64px);
		max-width: calc(100% - 64px);
   }
	.apFcEventHeader, .apFcEventHeader .main-description .date, .apFcEventHeader .main-description .address, .apFcEventHeader .main-description .headline {
		justify-content: center;
   }
	.apFcEventButton {
		margin-top: 20px;
   }
	.apUserFcPage .filter {
		flex-wrap: wrap;
   }
	.apFcEventHeader .main-description {
		align-items: center;
		margin-top: 20px;
		text-align: center;
   }
	.apUserFcPage .headline {
		padding: 24px 0;
		flex-direction: column;
		align-items: center;
   }
	.apUserPanel {
		margin-top: 24px;
   }
	.apUserFcPage .filter ul {
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-left: -5px;
		margin-right: -5px;
   }
	.apUserFcPage .filter ul li {
		padding: 0 5px;
		margin-bottom: 10px;
   }
	.apSearch {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.apFcItemTileContainer {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.apNewsCard {
		min-width: 500px;
		margin: 10px auto 20px;
   }
	.apNewsCard div:nth-child(1) {
		height: 312px;
   }
	.apNewsCard:nth-child(3) {
		display: block;
   }
	.apFcInfoBlock {
		padding: 0 0 0 0;
   }
	.apFcInfoBlock .text h3 {
		margin-top: 0;
   }
	.apFcInfoBlock .text {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.fcCollapseButton {
		width: auto;
   }
	.apFcInfoBlock {
		border: none;
   }
	.apTabbar {
		border-top: none;
   }
	.apFcHeader+.apCollapseContainer {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
   }
	.apFcHeader+.apCollapseContainer .fcCollapseButton {
		margin: 0;
		transform: rotate(180deg);
   }
	.fcCollapseButton:hover {
		opacity: 1;
   }
	.apFcInfoBlock .text p {
		font-size: 12px;
		margin-bottom: 12px;
   }
	.apFcHeader h2 {
		text-align: left;
		font-size: 24px;
   }
	.apFcHeader h2 span {
		font-size: 11px;
		margin: 0;
   }
	.apFcHeader {
		align-items: flex-start;
		padding: 16px 0 0;
   }
	.apFcContacts {
		width: 100%;
		flex-direction: row;
		justify-content: center;
   }
	.apFcContacts .logoWrp {
		flex: 0 0 130px;
		max-width: 130px;
		margin-right: 12px;
   }
	.apFcContacts .logoWrp .img {
		width: 130px;
		height: 130px;
		vertical-align: bottom;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-color: var(--apColorLightGray);
		border-radius: 16px;
   }
	.apFcContacts .content {
		text-align: left;
		flex: 0 0 130px;
		max-width: 130px;
		margin-left: 12px;
		font-size: 11px;
   }
	.apFcContacts .iconList {
		width: 100%;
		justify-content: space-around;
		margin: 0;
   }
	.apFcContacts .iconList a {
		margin: 0;
		border: none;
   }
	.apFcContacts .art {
		display: flex;
		flex-direction: column;
		margin: 0 0 7px 0;
   }
	.apFcContacts .art span, .apFcContacts .art a {
		font-weight: 700;
		font-size: 12px;
		line-height: 120%;
		letter-spacing: 0.006em;
		color: var(--apColorDark);
   }
	.apFcContacts .iconList a {
		height: 32px;
   }
	.apFcNewsItemContainer {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.apFcNewsItemContainer .imgWrp img {
		height: 148px;
   }
	.apFcNewsItemContainer h3 {
		font-weight: 700;
		font-size: 14px;
		line-height: 120%;
   }
	.apFcNewsItemContainer p {
		font-weight: 400;
		font-size: 11px;
		line-height: 120%;
   }
	.apFcNewsItemContainer .buttonWrap button, .apFcNewsItemContainer .more {
		font-size: 12px;
   }
	.apFcNewsItemContainer .buttonWrap button svg {
		margin-right: 0px;
		transform: scale(.8);
   }
	.fcNewstItem {
		padding: 10px;
		margin-bottom: 10px;
   }
	.apTabbar nav button {
		margin: 0;
   }
	.apTabbar nav {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		margin: 0 0 10px 0;
		width: 100%;
   }
	.apTabbar nav button {
		background-color: #DEDFE1;
		font-size: 12px;
		display: flex;
		justify-content: center;
   }
   .apTabbar nav button.primaryButtonIconTextDefault{
		background-color: var(--apColorAccent);
   }
	.apTabbar button.active {
		color: #fff;
		background-color: var(--apColorDark);
   }
	.apTabbar button.active svg {
		filter: invert(84%) sepia(100%) saturate(2%) hue-rotate(237deg) brightness(112%) contrast(100%);
   }
	.apUserTile {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.apTabbar {
		align-items: flex-end;
   }
	.apTabbar nav button svg {
		min-width: 16px;
		width: 16px;
		max-width: 16px;
		min-height: 16px;
		height: 16px;
		max-height: 16px;
		margin: 0 4px 0 0;
   }
}
@media (max-width: 639px) {
	.trainingMainContent{
		flex-direction: column;
   }
   .videoCardNameDescriptionContainer .apInputText,.ck.ck-editor{
	   max-width: 360px;
   }
   .qrComponentContainer{
	   width: 75mm;
   }

   .apFilterContainer{
	height: fit-content;
	max-height: 80vh;
	min-height: fit-content;
	left: 25px;
   }

   .groundPropertiesHeaderNews{
	   width: 100%;
   }

   .apEventsContainer.fallback{
	   text-align: start;
	   padding-left: 20px;
   }
	.apFCSectionItem, .apFCSectionItem.apFcSectionSmItem, .apFCSectionItem.date {
		flex: 0 0 100%;
		max-width: 100%;
		width: 100%;
		box-sizing: border-box;
   }
	.apFCSection .videoCardDropAreaAndHint {
		margin-bottom: 18px;
   }
	.apFCSectionItem.apFcSectionSmItem .apInputTextIcon:last-child {
		margin-bottom: 18px;
   }
	.apTileContainer {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.startList {
		flex-direction: row;
		margin: 30px auto;
   }
	.startList div:nth-child(2) {
		margin: 0px 10px;
   }
   .startList div{
	min-width:100px;
   }
}
@media (max-width: 570px) {
	.specWidth{
		width: 190px;
	}
	.statisticList{
		flex-wrap: nowrap;
	}
	.statisticListItem{
		flex-direction: column;
	}
	.eapps-facebook-feed-header-actions.eapps-facebook-feed-header-actions-active{
		left: 0!important;
		top: auto!important;
		right: 0!important;
		bottom: auto!important;
		text-align: left!important;
		}
		.eapps-facebook-feed-header-actions-item-label.eapps-facebook-feed-button-label{ margin-left: 10px;
		display: block!important;
	}
	.petalLeft{
		min-width: 200px;
		max-width: 200px;
		font-size: 12px;
	}
	.apNewsCard {
		min-width: 360px;
   }
   .main-hero{
	padding-bottom: 20px;
   }
	.apNewsCard div:nth-child(1) {
		height: 225px;
   }
	.calendar header select {
		width: 100px;
   }
	.main-section{
		/* margin-bottom: 38px; */
   }
	.storeSection {
		margin-bottom: 0;
		margin-top: 20px;
   }
	.apFooterLinksBlock{
		flex-direction: column;
   }
	.apFooterLinksBlock div{
		margin: 5px 0;
   }
	.headerSideText{
		border: none;
		margin: 10px 0 0 0;
		padding: 0;
   }
}
@media (max-width: 639px) {
	.flexDirect .item1{
		width: 100%;
		flex: none;
		max-width: 100%;
	}
	.imageItemGallery{
		aspect-ratio: 16/10;
		min-width: 100%;
		min-height: 100%;
		max-width: 100%;
		max-height: 100%;
	}
	.flexDirect .itemIndex{
		width: 100%;
	}
	.flexDirect .itemIndex2{
		width: 100%;
	}
	.startsButton{
		margin-bottom: 20px;
	}
	.apEventTile {
		flex-direction: column;
		align-items: center;
   }
	.headerWithButtonsCommonBlock {
		flex-wrap: wrap;
   }
	.headerWithButtonsButtonBlock{
		padding-top: 0;
   }
	.parkInfoPage .groundPropertiesNameBlock{
		max-width: 360px;
		min-width: 360px;
		flex: 0 0 360px;
		margin-bottom: 25px;
   }
	.apParkCoverImageContainer.parkItem {
		margin: 0 auto 20px;
   }
	.apEventTile .img-wrp {
		max-width: 126px;
   }
	.apEventTile .text {
		flex: 0 0 100%;
		width: 100%;
		text-align: center;
		max-width: 100%;
   }
	.apMapTileContainer {
		flex: 0 0 50%;
		max-width: 50%;
   }
	h1 {
		font-size: 24px;
		margin-top: 40px;
		margin-bottom: 24px;
   }
	h2, h3 {
		margin-top: 40px;
		margin-bottom: 16px;
   }
	h4 {
		margin-top: 32px;
		margin-bottom: 8px;
   }
	a {
		font-size: 14px;
		line-height: 100%;
	   /* letter-spacing: 0.03em;
		*/
   }
	.petalsPageHeaderBlock h4 {
		margin-top: -24px;
   }
	.petalsPageTopMenuBlock {
		margin-top: 24px;
   }
	.mainCommonBlock {
		overflow-x: auto;
   }
	.headerLine {
		min-height: initial;
   }
   .headerCommonBlock{
	margin-left: 8px;
	margin-right: 8px;
   }
	 .mainCommonBlock {
		margin-left: 8px;
		margin-right: 8px;
		overflow-x: hidden;
		padding-bottom: 40px;
   }
	.videoPlayerColumn {
		margin-right: initial;
   }
	.apFooter {
		margin-top: 48px;
   }
	.profileOwnRegionColumn, .profilePersonalDataColumn, .profileWorkPositionColumn {
		max-width: 100%;
   }
	.profilePhotoAndName {
		justify-content: center;
   }
	.profileNameBlock {
		align-items: center;
		text-align: center;
   }
	.groundPropertiesNameBlock, .groundPropertiesOtherParamsLeftBlock, .groundPropertiesOtherParamsRightBlock, .groundPropertiesAddressBlock, .groundPropertiesOwnerBlock, .catVideoValue, .userValue, .myObjectValue, .profileContacts, .profileWorkArea, .profileWorkPositionColumn, .profileOwnRegionColumn, .profilePersonalDataColumn, .videoCardCoverContainer, .videoCardNameDescriptionContainer, .videoCardSettingsFirstColumn, .videoCardSettingsRegions {
		padding-left: 8px;
		padding-right: 8px;
   }
	.profileContacts, .profilePhotoAndName, .profileWorkPositionColumn, .profileOwnRegionColumn, .profilePersonalDataColumn, .videoCardCoverContainer, .videoCardSettingsFirstColumn {
		border: none;
   }
	.groundPropertiesPhotosBlock {
		padding-left: 0;
		padding-right: 0;
   }
	.catVideoValue:nth-child(3), .catVideoValue:nth-child(4), .catVideoValue:nth-child(6), .userValue:nth-child(3) {
		align-items: center;
		text-align: center;
   }
	.catVideoValue, .userValue, .myObjectValue {
		background: initial;
   }
}
@media (max-width: 580px) {
	.filterRowItem{
		max-width: 80px;
	}
	.statisticPage .filterBlock{
		width: auto;
	}
	.forthSection .primaryButtonIconTextDefault{
		margin: 20px auto 20px;
		background-color: var(--apColorAccent);
	}
	.leftsideInfoStartPageHeader{
		max-width: 260px;
	}
	.headerMenuBlock .links.isMobile{
		padding-right: 90px;
	}
	.cusTom{
		text-align: left;
	}
	.mobileShow{
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.filterRow{
		justify-content: space-between;
		width: 100%;
	}
	.headerCommonBlock{
		padding: 0 20px;
	}
	.main-section{
		margin-bottom: 0px;
	}
	.storeHeaderActive{
		margin-top: 0px;
	}
	.flexDirect{
		gap:40px;
	}
	.widthFullMobile .primaryButtonIconTextDefault{
		width: 100%;
		margin-right: 0px;
	}
	.storeImage{
		display:none;
	}
	.storeImage.storeImage2{
		display: block;
	}
	.startHeader h2{
		font-size: 30px;
		min-height: 140px;
	}
	.startList div {
		padding-top: 0px;
		margin-top: 0px;
	}
	.storeHeaderActive{
		font-size: 30px;
	}
	.main-hero{
		padding-top: 0px;
	}
	.groundPropertiesOtherParamsRightBlock {
		border-left: none;
   }
	.groundPropertiesAddressBlock {
		border-right: none;
   }
   .flex-wrp .clubItem{
	width: 100%;
	max-width: 100%;
}
.apStatusItem{
	max-width: 240px;
	min-width: 240px;
	align-self: baseline;
}
.editorWidth{
	width: 500px;
}
}
@media (max-width: 540px) {
	.mobileHidden{
		display: none;
	}
	.mobileShow{
		display: block;
	}
	.apMapTileContainer {
		flex: 0 0 100%;
		max-width: 100%;
   }
   .groundPropertiesPhotosBlock{
	   max-width: 360px;
   }
   .dictionaryHeaderWithButton {
	flex-wrap: wrap;
	max-height: min-content;
}
.editorWidth{
	width: 400px;
}
}
@media (max-width: 460px) {
	.infoPage .infoPageContent img{
		width: -webkit-fill-available;
	}
	.editorWidth{
		width: 400px;
	}
	.petalLeft{
		min-width: 150px;
		max-width: 150px;
		font-size: 10px;
	}
	.petalLeft .primaryLargeButtonIconTextDefault{
		font-size: 10px;
	}
}

@media (max-width: 430px) {
	.editorWidth{
		width: 370px;
	}
}


@media (max-width: 410px) {
	.editorWidth{
		width: 340px;
	}

	.apConfereceTimer {
		flex-wrap: wrap;
		justify-content: center !important;
   }
	.apConfereceTimer p {
		margin-bottom: 16px;
		text-align: center;
   }

	.main-clubs .clubItemWrap {
		flex: 0 0 100%;
		max-width: 100%;
   }
	.main-section .swiper .swiper-pagination {
		padding: 0 0 8px 0;
		position: static;
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
		transform: none;
   }
	.main-section .swiper .swiper-pagination .swiper-pagination-bullet {
		margin: 0 8px 16px;
   }
	.main-section .headline {
		padding-bottom: 12px;
		margin-bottom: 12px;
   }
	.main-section .headline h2 {
		font-size: 22px;
   }
	.main-hero h1 {
		font-size: 24px;
		margin: 24px 0;
   }
	.main-hero .welcomeBack, .main-hero .location {
		font-size: 16px;
   }
	.petals {
		transform: scale(0.85);
   }
	.petalsPageUpperBlock {
		margin-bottom: 24px;
   }
	.videoPlayerColumn {
		overflow-x: auto;
   }
	.profilePhotoAndName {
		min-width: 300px;
   }
	.menuFunctionLine {
		flex-wrap: wrap;
   }
	.videoCardCoverImage {
		width: 270px;
   }
	.coordinatorsRowNormal {
		flex-wrap: wrap;
   }

	.apFooter {
		padding: 24px;
   }
	.groundPropertiesNameBlock, .groundPropertiesOtherParamsLeftBlock, .groundPropertiesOtherParamsRightBlock, .groundPropertiesAddressBlock, .groundPropertiesOwnerBlock, .videoCardCoverContainer, .videoCardNameDescriptionContainer, .videoCardSettingsFirstColumn, .videoCardSettingsRegions {
		padding-left: 2px;
		padding-right: 2px;
   }
	.catVideoValue, .userValue, .myObjectValue {
		padding-left: 4px;
		padding-right: 4px;
   }
	.headerCommonBlock, .mainCommonBlock {
		margin-left: 6px;
		margin-right: 6px;
   }
}
@media (max-width: 360px) {
	.editorWidth{
		width: 330px;
	}
	.apPointList .listContainer .item>div:last-child, .apPointList .listContainer .item>div:nth-child(3) {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0;
		margin-top: 16px;
		text-align: center;
		justify-content: center;
   }
	.apPointList .listContainer .item>div:nth-child(2), .apPointList .listContainer .item>div:first-child {
		margin-bottom: 8px;
   }
	.petals {
		transform: scale(0.8);
   }
	.headerCommonBlock, .mainCommonBlock {
		margin-left: 4px;
		margin-right: 4px;
   }
}
@media (max-width: 320px) {
	.petals {
		transform: scale(0.75);
   }
	.petalsPageUpperBlock {
		margin-bottom: 0;
   }
}
@media (max-width: 290px) {
	.petals {
		transform: scale(0.65);
   }
}
.menuFunctionLine.forReport {
	justify-content: flex-start;
	column-gap: 12px;
	row-gap: 16px;
	padding: 8px 0px 24px 0px;
	align-items: center;
}
.menuFunctionLine.forReport .apInputText, .menuFunctionLine.forReport .apDropDownList {
	margin-bottom: 4px;
	min-width: initial;
}
.actionButtonsBlock.forReport {
	column-gap: 24px;
	row-gap: 16px;
}
.actionButtonsBlock.forReport button {
	margin: initial;
}
.reportRowValuesContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
	flex-wrap: wrap;
	column-gap: 16px;
	row-gap: 8px;
}
.reportRowButtonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	flex-wrap: wrap;
	column-gap: 16px;
	row-gap: 16px;
}
.reportRowButtonsContainer .tetriaryButtonIconDefault:hover {
	background-color: var(--apColorBrandLogo1);
}
.reportRowNormal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
	color: var(--apColorDark);
	padding: 16px;
	column-gap: 16px;
   /* margin-bottom: 1px;
	*/
	background-color: var(--apColorLightGray);
	border-bottom: 1px solid var(--apColorDarkGray1);
}
.reportRowNormal:nth-child(even) {
	background-color: var(--apColorMediumGray);
}
.reportRowNormal.inEdit {
	background-color: var(--apColorLight);
}
.reportRowValue {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.reportRowValue:nth-child(1) {
	flex-basis: 190px;
}
.reportRowValue:nth-child(2) {
	flex-basis: 310px;
}
.reportRowValue:nth-child(3) {
	flex-basis: 100px;
}
.reportRowValue:nth-child(4) {
	flex-basis: 100px;
}
.reportRowValue:nth-child(5) {
	flex-basis: 100px;
}
.reportRowValue:nth-child(6) {
	flex-basis: 100px;
}
.reportRowValue:nth-child(7) {
	flex-basis: 315px;
}
.reportRowValue:nth-child(8) {
	flex-basis: 330px;
}
.reportRowValue:nth-child(9) {
	flex-basis: 380px;
}
.reportRowValue:nth-child(10) {
	flex-basis: 140px;
}
.reportRowValue:nth-child(11) {
	flex-basis: 140px;
}
.reportRowValue:nth-child(12) {
	flex-basis: 330px;
}
.reportRowValue:nth-child(13) {
	flex-basis: 380px;
}
.reportRowValue .apInputText, .reportRowValue .apDropDownList {
	margin-bottom: 4px;
	min-width: 115px;
}
.reportRowValue label {
	overflow: hidden;
	white-space: nowrap;
}
.reportRowValue span {
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.03em;
	line-height: 120%;
}
.reportRowValue h3, .reportRowValue h4 {
	margin: 0px;
}
.confRecordRow {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	min-height: 40px;
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	padding: 8px 8px 8px 8px;
	margin-bottom: 1px;
	flex-wrap: wrap;
	column-gap: 32px;
	row-gap: 4px;
}
.instruction {
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 0.03em;
	line-height: 120%;
	color: var(--apColorDark);
	padding: 8px 8px 8px 8px;
}
.confRecordRow:nth-child(even) {
	background: var(--apColorLightGray);
}
.confRecordRow:nth-child(odd) {
	background: var(--apColorMediumGray);
}
.confRecordRow:hover {
	background-color: var(--apColorLight);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 0px rgba(0, 0, 0, 0.08);
	cursor: pointer;
}
.confRecordRow>div {
	flex-basis: 180px;
	flex-grow: 1;
}
.forthSectionHeader{
	margin: 0 0 20px;
	font-size: 30px;
}
