.calendar {
    display: flex;
    align-items: flex-start;
    margin-top: 96px;
    justify-content: space-between;
}

aside {
    padding: 152px 100px 0;
}

aside .headline {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.03em;
    color: #444F60;
    margin: 0 0 15px 0;

}

aside ul {
    padding: 0;
    margin: 0;
}

.arrows {
    padding: 0;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
    background-color: var(--apColorDark) !important;
}

.arrows:hover {
    background-color: var(--apColorAccent) !important;
}

.arrows path {
    stroke: #fff;
}

aside ul li {
    padding: 0 0 0 22px;
    position: relative;
    list-style: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #444F60;
    margin-bottom: 10px;

}

aside ul li:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 12px;
    height: 12px;
}

aside ul li:nth-child(1):before {
    background-color: #F4575D;
}

aside ul li:nth-child(2):before {
    background-color: #C5E505;
}

aside ul li:nth-child(3):before {
    background-color: #BB74D6;
}

/* aside ul li:nth-child(4):before {
    background-color: #C5E505;
}

aside ul li:nth-child(5):before {
    background-color: #F36F21;
}

aside ul li:nth-child(6):before {
    background-color: #444F60;
} */

.calendar header {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    padding: 3px;
}

.calendar header button,
.calendar header select {
    margin: 0 4px;

}

.calendar header button {
    background-color: transparent;
    color: var(--apColorLight);
}

.calendar header button:hover {
    background-color: var(--apColorBrandBG6);
}

.calendar header select {
    border-radius: 4px;
    width: 160px;
    padding: 0 0 0 10px;
}


.calendar table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    margin: auto;
}

.calendar th {
    vertical-align: middle;
    text-align: center;
    border-radius: 8px;
    background: #F1F2F4;
    height: 36px;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    text-align: center;
    letter-spacing: 0.03em;
}

.calendar .day,
.calendar td {
    width: 111px;
    height: 69px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.006em;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    transition: .05s;
}

.calendar .day {
    /* padding: 4px 8px; */
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    vertical-align: middle;
    text-align: center;
    border: 2px solid transparent;
    position: relative;
}

.calendar .day:hover {
    background-color: #eaeaea;
    cursor: pointer;
}

.calendar .day.selected {
    border: 2px solid var(--apColorAccent);
    ;
}

.calendar .day.today {

    background-color: var(--apColorAccent);
    color: white;
}

.calendar .day.today:hover {
    background-color: var(--apColorAccent);
    ;
}

.calendar .day.today.selected {
    border: 2px solid lightgray;
}

.eventsItems{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.event {
    /* position: absolute; */
    bottom: 5px;
    background-color: #F4575D;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin: 7px 2px 0;
}
.zahid {
    /* position: absolute; */
    bottom: 5px;
    background-color: #BB74D6;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin: 7px 2px 0;

}
.translac {
    /* position: absolute; */
    bottom: 5px;
    background-color: #C5E505;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin: 7px 2px 0;


}

@media (max-width: 775px) {
    .calendar {
        margin: 0 -4px;
    }

    .calendar header {
        margin-top: 24px;
    }

    .calendar header select {
        width: 90px;
    }

    .calendar .day,
    .calendar td {
        height: 40px;
        font-size: 16px;
    }

    .calendarBody {
        display: flex;
        flex-direction: column-reverse;
    }

    .calendar th {
        height: 23px;
        font-size: 12px;
    }
    /* .event{
        left: 47%;
        height: 5px;
        width: 5px;
        bottom: 2px;

    } */
}